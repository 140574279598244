/* Global css */
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:100,200,300,400,500,600,700,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Yellowtail&display=swap");

@font-face {
  font-family: "Apercu-Bold";
  font-display: swap;
  src: local("Apercu-Bold"),
    url("../src/assets/fonts/Apercu-Bold.eot") format("opentype"),
    url("../src/assets/fonts/Apercu-Bold.otf") format("opentype"),
    url("../src/assets/fonts/Apercu-Bold.woff") format("woff"),
    url("../src/assets/fonts/Apercu-Bold.ttf") format("truetype"),
    url("../src/assets/fonts/Apercu-Bold.svg#Apercu-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Apercu-Regular";
  font-display: swap;
  src: local("Apercu-Regular"),
    url("../src/assets/fonts/Apercu-Regular.eot?#iefix") format("embedded-opentype"),
    url("../src/assets/fonts/Apercu-Regular.otf") format("opentype"),
    url("../src/assets/fonts/Apercu-Regular.woff") format("woff"),
    url("../src/assets/fonts/Apercu-Regular.ttf") format("truetype"),
    url("../src/assets/fonts/Apercu-Regular.svg#Apercu-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@mixin apercubold {
  font-family: "Apercu-Bold" !important;
}

@mixin apercuregular {
  font-family: "Apercu-Regular" !important;
}

@mixin text_decoration {
  text-decoration: none;
}

@mixin align_item_center {
  align-items: center;
}

@mixin font14 {
  font-size: 14px;
}

@mixin normal_line_height {
  line-height: normal;
}

@mixin relative_position {
  position: relative;
}

@mixin absolute_position {
  position: absolute;
}

@mixin absolute_position_value($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin font_fira_size($size, $color_text, $line_height, $weight) {
  $fontFamily: "Fira Sans", sans-serif;
  font-size: $size;
  color: $color_text;
  line-height: $line_height;
  font-weight: $weight;
}

@mixin width($property) {
  width: $property;
}

@mixin height($property) {
  height: $property;
}

@mixin fontsize($property) {
  font-size: $property;
}

@mixin radius($property) {
  border-radius: $property;
}

@mixin pointer {
  cursor: pointer;
}

@mixin flex_property($display, $flow, $alignment, $justify) {
  display: $display;
  flex-flow: $flow;
  align-items: $alignment;
  justify-content: $justify;
}

@mixin width_height_max_width_max_height($width, $height, $max_width, $max_height) {
  width: $width;
  height: $height;
  max-width: $max_width;
  max-height: $max_height;
}

@mixin default_input_style {
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
}

@mixin pad_mar_0 {
  padding: 0;
  margin: 0;
}

@mixin fixedposition {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* variables */
$txt_white: #fff;
$input_text_color: #8ba6c1;
$search_border: #71d2f1;
$greyis: #f5f8fa;
$greyish_text: #506d85;
$signup_heading: #303f4c;
$signup_link: #00b9f5;
$pricing-color: #00b9f5;
$card_border: #ebebeb;
$contactY: #f7b500;
$errorcolor: #f94330;
/* comman font color */
$fontColor1: #00407e;
$fontColor2: #fff;
$fontColor3: #00aced;
$fontColor4: #00407e;
$fontColor5: #000;
$fontColor6: #ed8808;
$fontColor7: #8ba6c1;
$colorRed: red;

/* comman font size */
$fontSize10: 10px;
$fontSize12: 12px;
$fontSize14: 14px;
$fontSize16: 16px;
$fontSize18: 18px;
$fontSize20: 20px;
$fontSize21: 21px;
$fontSize22: 22px;
$fontSize24: 24px;
$fontSize28: 28px;
$fontSize32: 32px;
$fontSize54: 54px;
$fontSize60: 60px;

/* comman style */
$fontFamily: "Fira Sans" !important;
$navColor: $fontColor1;
$navBottomBorderColor: $fontColor6;
$btnBackgroundColor: $fontColor6;
$btnColor: $fontColor2;
$btnColor: $fontColor2;
$bannerFontColor: $fontColor2;
$bannerFontSize: $fontSize54;
$bannerTextFontColor: $fontColor2;
$bannerTextFontSize: $fontSize24;

$primary: #003e80;
$secondary: #7fc534;
$tertiary: #01427c;
$whitetext: #fff;
$formborder: #00407e;
$headingcolor: #00407e;
$footeranchor: #003e80;
$footerfollowcolor: #00407e;
$otherbordercolor: #a5a5a5;
$pharmacypop: #00407e;
$formgrey: #e5dfdf;
$formtypecolor: #b6aca3;
$primarytext: #01427c;


/* mixins */
@mixin textAlignLeft {
  text-align: left;
}

@mixin textAlignRight {
  text-align: right;
}

@mixin textAlignCenter {
  text-align: center;
}

@mixin btn {
  font-size: $fontSize16;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor2;
  border-radius: 4px;
  background-color: $fontColor6;
  position: relative;
}

@mixin commanWidth {
  width: 1258px;
  margin: 0 auto;
}

@mixin all {
  margin: 0px;
  padding: 0px;
}

@mixin bannerHeading {
  font-size: $bannerFontSize;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1.13px;
  color: $bannerFontColor;
}

@mixin bannertext {
  font-size: $bannerTextFontSize;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0px;
  color: $bannerTextFontColor;
}

@mixin paragraph {
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor4;
}

@mixin heading {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $fontColor1;
}

@mixin shadow {
  border-radius: 4px;
  box-shadow: 0 2px 24px 0 rgba(29, 37, 45, 0.05);
  border: solid 1px #dde5ed;
  background-color: $fontColor2;
}

@mixin footerHeading {
  margin-bottom: 12px;
  font-size: $fontSize16;
  text-transform: unset;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.29;
  letter-spacing: normal;
  color: $fontColor1;
}

@mixin footerLink {
  font-size: $fontSize14;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: $fontColor1;
  text-decoration: none;
}

/* general */
.comman-width {
  @include commanWidth;
}

body {
  font-family: $fontFamily;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

* {
  @include all;
}

.btn {
  @include btn;
}

.colorRed {
  color: $colorRed;
  font-size: $fontSize14;
  margin-top: 10px;
}

/* btn effect */
@mixin transition($in) {
  transition: $in;
  -webkit-transition: $in;
  -moz-transition: $in;
  -o-transition: $in;
  -ms-transition: $in;
}

@mixin transform($in) {
  transform: $in;
  -webkit-transform: $in;
  -moz-transform: $in;
  -o-transform: $in;
  -ms-transform: $in;
}

@mixin animation($in) {
  animation: $in;
  -webkit-animation: $in;
  -moz-animation: $in;
  -o-animation: $in;
  -ms-animation: $in;
}

.btn {
  &:hover {
    color: $fontColor2 !important;
  }
}

.btn-hover-effect {
  overflow: hidden;

  .insider {
    background-color: #fcb723;
    width: 100%;
    height: 3px;
    position: absolute;
    left: -170px;
    @include transform(rotateZ(45deg));
  }

  &:hover {
    color: $fontColor2;
    opacity: 0.9;

    .insider {
      @include transition(all 1.2s ease);
      left: 170px;
    }
  }
}

/* welcome section */
.welcome-section {
  float: left;
  width: 100%;
  background-size: cover;
  min-height: 570px;
  background-position: 0;
  background-image: #00407e url("assets/images/hero_iphone.png") no-repeat;
  background-image: url("assets/images/hero_iphone.png"),
    -moz-linear-gradient(left, #00407e 0%, #012b53 100%);
  background-image: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #00407e),
    color-stop(100%, #012b53)
  );
  background-image: url("assets/images/hero_iphone.png"),
    -webkit-linear-gradient(left, #00407e 0%, #012b53 100%);
  background-image: url("assets/images/hero_iphone.png"),
    -o-linear-gradient(left, #00407e 0%, #012b53 100%);
  background-image: url("assets/images/hero_iphone.png"),
    -ms-linear-gradient(left, #00407e 0%, #012b53 100%);
  background-image: url("assets/images/hero_iphone.png"),
    linear-gradient(to right, #00407e 0%, #012b53 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00407e', endColorstr='#012b53', GradientType=1);
  position: relative;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right;

  &:before {
    content: "";
    position: absolute;
    // background: url("assets/images/hero_iphone.webp") no-repeat;
    width: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: auto 100%;
    background-position: right top;
    bottom: 0;
  }
}
.welcome-section h2 {
  margin-top: 90px;
  margin-bottom: 20px;
  font-size: 46px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 54px;
  letter-spacing: -0.71px;
  color: $whitetext;
  @include apercubold;
}

.welcome-center {
  margin: 0;
  display: flex;
  flex-flow: column;
  max-width: 553px;
  margin-left: 90px;

  .formsec {
    display: flex;
    margin-top: 30px;
    flex-flow: row wrap;
    justify-content: space-between;

    > .form-group {
      width: calc(50% - 7px);
    }

    input {
      width: 100%;
      border: 2px solid $whitetext;
      border-radius: 22px;
      height: 46px;
      display: flex;
      align-items: center;
      color: $whitetext;
      background: transparent;
      outline: none;
      margin-bottom: 15px;
      padding-left: 15px;
      @include apercuregular;
      font-size: 20px;

      &[type="email"] {
        width: 100%;
      }

      &::placeholder {
        color: $whitetext !important;
      }
    }
    @media (max-width: 1024px) {
      .btn_desc a {
        margin-right: 0 !important;
      }
    }
    .btn_desc {
      display: flex;
      align-items: center;

      a {
        @include apercubold;
        color: $whitetext;
        border-radius: 20px;
        background: $secondary;
        min-height: 45px;
        min-width: 215px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        max-height: 44px;
        margin-right: 20px;
      }

      p {
        padding: 0;
        margin: 0;
        @include apercuregular;
        font-size: 13px;
        color: $whitetext;
        letter-spacing: -0.01px;
        line-height: 16px;
        font-weight: 400;
        margin: 0;
        font-weight: 400;
        a {
          display: none;
          display: inline;
          color: $whitetext;
          letter-spacing: -0.01px;
          width: auto;
          height: auto;
          background: transparent;
          font-size: 12.6px;
          margin: 0;
          font-weight: 400;
        }
      }
    }

    .error_msg {
      position: relative;

      input {
        padding-left: 30px !important;
        transition: all 0.2s;
        border-color: $secondary !important;
      }

      &:before {
        content: "!";
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $secondary;
        color: $whitetext;
        @include apercubold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        position: absolute;
        top: 14px;
        left: 10px;
      }

      span {
        top: 20px;
        @include apercuregular;
        font-size: 16px;
        font-weight: normal;
        color: $secondary;
        margin-top: 0;
        display: flex !important;
        align-items: center;
        letter-spacing: -0.5px;
        top: 0;
        padding-left: 13px;
        position: relative;
        margin-top: 5px;

        &:before {
          content: "!";
          min-width: 16px;
          min-height: 16px;
          border-radius: 50%;
          background: $secondary;
          color: $whitetext;
          @include apercubold;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          margin-right: 7px;
          border: none;
        }
      }
    }
  }
}

// search_medicine section
.search_medicine {
  background: #f2f2f2;

  .comman-width {
    padding-top: 40px;
    max-width: 1048px;
    padding-bottom: 0px;

    app-search {
      .search-fields {
        margin-bottom: 20px;
      }

      .form-section {
        background: transparent;
        border: 2px solid $formborder;
        border-radius: 44px;

        .btn[type="submit"] {
          display: none;
        }

        .form-control {
          width: 100%;
          background: transparent;
          margin-left: 0;
          padding-left: 25px;
          color: $formborder;
          font-size: 20px;
          @include apercubold;
          letter-spacing: -0.05px;

          &::placeholder,
          &:-ms-input-placeholder {
            color: $formborder;
          }
        }

        .lowest {
          background: $primary;
          border-radius: 20.5px;
          min-height: 45px;
          max-height: 45px;
          @include apercubold;
          font-size: 16px;
          color: $whitetext;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 50%;
          transform: translate(0, -50%);
          min-width: 190px;
          max-width: 190px;
          letter-spacing: 0.14px;
        }
      }

      .popular_sesrch {
        .title {
          background: transparent;
          padding: 0;
          margin: 0;
          margin-bottom: 10px;
          @include apercubold;
          font-size: 16px;
          color: $formborder;
          font-weight: normal;
          line-height: 19px;
        }

        ul {
          display: flex;
          flex-flow: row wrap;

          li {
            background: transparent;
            border: 1.2px solid $formborder;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 45px;
            padding-left: 15px;
            padding-right: 15px;
            letter-spacing: 0.07px;
            cursor: pointer;
            color: $formborder;
            margin-right: 10px;
            line-height: normal;
            border-radius: 20.5px;
            margin-bottom: 10px;
            @include apercuregular;
            font-size: 16px;
          }
        }
      }

      .recent_sesrch,
      .serachlist {
        .title {
          background: $formborder;
          color: $whitetext;
          @include apercubold;
          font-size: 16px;
        }

        ul {
          li {
            color: $formborder;
            background: transparent;
            border-bottom: 1.2px solid $formborder;
            @include apercuregular;
            font-size: 16px;
            font-weight: normal;
            border-top: none;

            &:last-child {
              border: none;
            }

            &:hover {
              background: $formborder;
              color: $whitetext;
            }
          }
        }
      }
    }
  }
}

/* accept section */
.accept-section .card {
  position: relative;
  display: block;
  margin: 61px auto 31px auto;
  line-height: 56px;
  width: 224px;
  height: 56px;
  border-radius: 4px;
  background-color: $btnBackgroundColor;
  font-size: $fontSize16;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor2;
  text-decoration: none;
  border: none;
}

.accept-section ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  // text-align: center;
}

.accept-section li {
  max-height: 100px;
  display: inline-block;
}

.accept-section li:first-child {
  padding-left: 31px;
}

.accept-section li:last-child {
  padding-right: 62px;
}

.accept-section li img {
  max-width: 101px;
  height: auto;
}

.accept-section h2 {
  margin-top: 60px;
  margin-bottom: 48px;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #18342f;
}

/* work section */
.work-section {
  padding: 30px 0 30px 0;
  width: 870px;
  text-align: center;
  margin: 0 auto;
}

.work-section h2 {
  @include heading;
}

.work-section p {
  @include paragraph;
}

/* carosuel section */
.carosuel-section .shadow {
  margin-left: 10px;
  margin-right: 10px;
  min-height: 158px;
  margin-bottom: 20px;
  padding-top: 35px; //19Dec
  @include shadow;
}

.carosuel-section h2 {
  margin-bottom: 40px;
  @include textAlignCenter;
  @include heading;
}

.carosuel-section img {
  width: 49px;
  height: 49px;
  border-radius: 49px;
  margin: 25px auto 15px auto;
  display: block;
}

.carosuel-section h3 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #18342f;
}

.carosuel-section p {
  padding: 0 35px;
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: $fontSize10;
}

.carosuel-section .nav-btn.prev-slide.slick-arrow {
  z-index: 9;
  cursor: pointer;
  position: absolute;
  top: 50%; //19Dec
  left: -110px;
  width: 30px;
  height: 60px;
  background: url("assets/images/prev_icon.svg") no-repeat;
  transform: translate(0, -50%);
  background-position: center left;
}

.carosuel-section .nav-btn.next-slide.slick-arrow {
  z-index: 9;
  cursor: pointer;
  position: absolute;
  top: 50%; //19Dec
  right: -110px;
  width: 30px;
  height: 60px;
  background: url("assets/images/next_icon.svg") no-repeat;
  transform: translate(0, -50%);
  background-position: center right;
}

.slick-disabled {
  display: none !important;
}

/* download section */
.download-section {
  padding: 70px 0 0 0;
  margin: 50px 0 0 0;
  width: 100%;
  overflow: hidden;
  min-height: 500px;
  background: url("assets/images/download-app-bg.webp") left top no-repeat;
  background-size: 100% 100%;
}

.download-section span {
  display: block;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  color: #ffffff;
}

.download-section h2 {
  margin-bottom: 50px;
  margin-top: 15px;
  font-size: $fontSize60;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -1.5px;
  color: $fontColor2;
}

.download-section .get {
  margin-top: 30px;
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: $fontColor2;
}

.download-section form {
  width: 469px;
  height: 56px;
  border-radius: 6px;
  background-color: #ffffff;
}

.download-section .form-control {
  padding: 28px 0 27px 15px;
  box-shadow: inherit;
  width: 291px;
  float: left;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #506d85;
}

.download-section .form-control.differ {
  padding: 27px 0 24px 15px;
}

.download-section .form-control:focus {
  box-shadow: none;
}

.download-section .btn {
  margin: 5px 0 0 0;
  width: 172px;
  height: 46px;
  position: relative;
  text-align: center;
  border-radius: 4px;
  background-color: $btnBackgroundColor;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $btnColor;
}

// .download-section .gap {
//   margin-right: 10px;
// }

/* header */
.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar {
  margin-bottom: 0px !important;
  border: none !important;
  padding: 0 !important;
}

.navbar ul {
  // padding-top: 23px;
  overflow: visible;
  list-style: none;
  flex-direction: row;
  align-content: center !important;
}

.navbar li {
  transition: all 0.7s ease;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.navbar li.last {
  padding-right: 0px;
}

.navbar li a {
  font-size: 20px !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  color: #003e80;
  padding: 0 !important;
  cursor: pointer;
  @include apercubold();
  position: relative;
  display: flex;
  align-items: center;
}

.navbar li span {
  position: absolute;
  display: none;
  bottom: -25px;
  width: 100%;
  height: 4px;
  background: $navBottomBorderColor;
  border-radius: 4px;
}

.navbar li.download .active {
  padding-bottom: 0px !important;
}

.navbar li.download .active span {
  bottom: -31px !important;
}

.navbar li a.active span,
.navbar li a.home-active span {
  display: block;
}

.navbar a {
  text-decoration: none;
}

.login {
  // padding-top: 23px;
  padding-left: 28px;
  display: flex;
}

.signIn,
.signIn:hover {
  padding-left: 20px;
  font-size: 16px;
  color: $tertiary;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  @include apercubold;
}

.signUp,
.signUp:hover {
  font-size: 16px;
  color: #fff;
  background: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;
  white-space: nowrap;
  @include apercubold;
  border-radius: 20px;
  min-width: 120px;
}

.margin-leftAuto {
  margin-left: auto !important;
}

.navbar li.download {
  padding-right: 0px !important;
}

.navbar .signUp {
  padding-right: 0px !important;
}

.navbar .login {
  overflow: hidden;
  padding-left: 25px;
}

/* search section */
.search {
  padding-top: 60px;
  padding-bottom: 90px;

  p {
    padding: 0;
    margin: 0;
    @include apercubold;
    color: $tertiary;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.07px;
    font-size: 16px;
  }

  ul {
    list-style: none;
    display: flex;
    margin-top: 12px;
    justify-content: space-between;

    li {
      display: flex;
      flex-flow: column;
      @include apercuregular;
      font-size: 18px;
      color: $tertiary;
      letter-spacing: -0.26px;
      line-height: 24px;
      min-width: 150px;
      max-width: 150px;

      img {
        margin-bottom: 12px;
      }
    }
  }
}

.image {
  min-height: 80px;
}

// .search-fields {
//   // position: relative;
//   // max-width: 908px;
//   // margin: 0 auto;
//   // z-index: 9999;
// }

.search-fields input {
  border: none;
  float: left;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8ba6c1;
}

.search-fields .form-section {
  background: #fff;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  position: relative;
  min-height: 88px;
  border-radius: 10px;
  border-radius: 6px;
  box-shadow: 0 0 64px 0 rgba(29, 37, 45, 0.1);
}

.search-fields .form-section .btn {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 32px;
  left: 29px;
  background: url("assets/images/search.svg") left top no-repeat;
}

.search-fields .form-control {
  min-height: 88px;
  margin-left: 66px;
  padding: 0px;
  width: 65%;
  box-shadow: none;
}

.search-fields .form-control:focus {
  box-shadow: none;
}

.search-fields .serachlist {
  text-align: left;
  margin-bottom: 10px;
  width: 100%;
  height: 350px;
  overflow: auto;
}

.search-fields .serachlist ul {
  list-style: none;
  width: 100%;
  overflow: hidden;
}

.search-fields .serachlist li {
  color: #18342f;
  padding-left: 16px;
  line-height: 36px;
  cursor: pointer;
  border-top: 1px solid #f0f0f0;
}

.search-fields .serachlist li:hover {
  background: $fontColor6;
}

/* footer section */
.footer-section {
  padding: 10px 0 10px 0;
  // padding-bottom: 143px !important;
  width: 100%;
  overflow: hidden;
  // border-top: 1px solid #dde5ed;
  position: relative;
  // top: 50px;
  // padding-top: 104px !important;

  .comman-width {
    max-width: 1040px;

    .footerlogo {
      margin-bottom: 40px;

      a {
        &::after {
          visibility: hidden;
        }
      }
    }

    .footer-adjust {
      ul {
        li {
          margin-bottom: 20px;

          a {
            @include apercubold;
            font-size: 16px;
            color: $footeranchor;
            letter-spacing: -0.51px;

            &:hover {
              &::after {
                background: $footeranchor;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
      }

      .first {
        min-width: 108px;
        max-width: 160px;
        margin-right: 58px;

        ul {
          li {
            &:last-child {
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }
        }
      }

      .second {
        padding-top: 0;

        ul {
          li {
            &:last-child {
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }
        }
      }

      .third {
        min-width: 198px;
        max-width: 198px;
        margin-left: auto;
        margin-right: 100px;

        h2 {
          line-height: normal;
          @include apercubold;
          font-size: 18px;
          color: $footerfollowcolor;
          letter-spacing: -0.24px;
          margin-bottom: 26px;
          font-weight: normal;
        }

        ul.social {
          li {
            margin-bottom: 21.6px;

            a {
              display: flex;
              align-items: center;
              @include apercuregular;
              font-size: 18px;
              color: $footeranchor;
              font-weight: normal;

              &::after {
                display: none;
              }
            }

            &:last-child {
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }
        }
      }

      .fourth {
        h2 {
          line-height: normal;
          @include apercubold;
          font-size: 18px;
          color: $footerfollowcolor;
          letter-spacing: -0.24px;
          margin-bottom: 20px;
          font-weight: normal;
        }

        .socialbtns {
          a {
            width: 150px;
            height: 45px;
            border-radius: 20.5px;
            border: solid 1.2px $formborder;
            display: flex;
            align-items: center;
            padding-left: 15px;
            margin-bottom: 8px;
            @include apercuregular;
            font-size: 16px;
            font-weight: normal;
            color: $formborder;

            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .copyright {
    max-width: 1198px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 110px;
    border-top: 2.2px dashed $secondary;
    position: relative;
    overflow: visible;
    padding-top: 32.5px;

    &::before {
      content: "";
      width: 40px;
      height: 23px;
      background: #fff url("assets/images/footer_scissors.svg") no-repeat;
      position: absolute;
      display: none;
      top: -14px;
      left: 0;
    }

    .comman-width {
      max-width: 100%;
      padding-left: 78px;

      li {
        @include apercuregular;
        font-weight: normal;
        color: $secondary;
        letter-spacing: -0.46px;
        font-size: 16px;
      }
    }
  }
}

.footer-section ul {
  list-style: none;
}

.footer-section h2 {
  margin-bottom: 12px;
  @include footerHeading;
}

.footer-section h2 span {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #506d85;
  display: block;
  text-transform: none;
}

.footer-section li a {
  @include footerLink;
}

.footer-section li {
  padding-bottom: 8px;
}

.footer-section .second {
  padding-top: 51px;
}

.footer-section .social img {
  margin-right: 13px;
}

.footer-section .social li {
  margin-bottom: 6px;
}

.footer-section .form-control {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 2px 24px 0 rgba(29, 37, 45, 0.05);
  border: solid 1px #dde5ed;
  color: #8ba6c1;
  background-color: #ffffff;
}

.footer-section .btn {
  margin-top: 15px;
  color: $btnColor;
  width: 159px;
  height: 48px;
  border-radius: 4px;
  background-color: $btnBackgroundColor;
  position: relative;
}

.footer-section .logo {
  display: none;
  margin-bottom: 21px;
  margin-top: 21px;
}

.footer-section .alert-success,
.footer-section .alert-danger {
  display: none;
  width: 308px;
  margin-top: 8px;
}

.cool-link:hover {
  color: #ed8808;
}

.cool-link {
  display: inline-block;
  color: #ed8808;
  text-decoration: none;
}

.cool-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: $secondary;
  transition: width 0.6s;
}

.cool-link:hover::after {
  width: 100%;
}

.copyright {
  padding: 20px 0;
  border-top: 1px solid #cde5f6;
  overflow: hidden;
  margin-top: 30px;
}

.copyright li:first-child {
  padding-left: 0px;
  border: none;
}

.copyright li {
  font-size: $fontSize14;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #506d85;
  float: left;
  border-left: none;
  padding: 0 0 10px 0;
}

.copyright li a {
  font-size: $fontSize14;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00407e;
}

.slide:focus,
.slide:active {
  outline: none;
}

.slideToggle {
  display: none;
  background: none;
  border: none;
}

.search-fields .btn.lowest {
  width: 224px;
  height: 56px;
  line-height: 42px;
  border-radius: 4px;
  background: $btnBackgroundColor none;
  right: 16px;
  left: auto;
  color: $btnColor;
  top: 15px;
  position: absolute;
  font-family: $fontFamily;
  cursor: pointer;
}

/* banner section */
.banner-section img {
  width: 100%;
  height: auto;
}

.banner-section {
  width: 100%;
  height: auto;
  background-image: linear-gradient(282deg, #399ffa, #0ab2c7);
}

.banner-section .comman-width {
  position: relative;
}

.banner-section .over {
  position: absolute;
  top: -248px;
  left: 0px;
}

.banner-section h2 {
  @include bannerHeading;
}

.banner-section p {
  @include bannertext;
}

/* honest */
.honest-section {
  padding: 60px 0 0 0;
}

.honest-section .small-heading {
  font-size: $fontSize20;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $fontColor3;
}

.honest-section h2 {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: -1px;
  color: $fontColor5;
  margin-top: 8px;
}

.honest-section p {
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: $fontColor4;
}

.honest-section .honest-text {
  font-family: "Yellowtail";
  font-size: $fontSize24;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $fontColor5;
}

.honest-section .honest-line {
  width: 125.8px;
  border: solid 2px #000;
  border-color: #000 transparent transparent transparent;
  border-radius: 69% 0px 0px 3px;
  display: block;
}

/* support section */
.support-section {
  margin: 40px 0 0 0;
  background-color: #f5f8fa;
  padding-bottom: 40px;
}

.support-section img {
  width: 100%;
  height: auto;
}

.support-section .support {
  background-image: linear-gradient(290deg, #399ffa, #0ab2c7);
  width: 1060px;
  margin: -300px auto 0 auto;
  position: relative;
  padding: 40px 0;
}

.support-section h2 {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor2;
}

.support-section p {
  font-size: $fontSize24;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor2;
  padding: 0 80px;
}

.contact-section .content {
  border: solid 1px #dde5ed;
  background-color: #ffffff;
  margin-top: -55px;
  position: relative;
  padding: 40px 45px 0 45px;
  overflow: hidden;
}

.contact-section .left {
  width: 292px;
  float: left;
}

.contact-section .right {
  padding-left: 27px;
  width: 70%;
  float: left;
  padding-top: 5px;
  position: relative;
  border-left: 2px solid #dde5ed;
  min-height: 440px;
}

.contact-section .form-group {
  margin-bottom: 30px;
}

.contact-section h2 {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $fontColor1;
  margin-bottom: 40px;
  margin-top: 0px;
}

.contact-section label {
  font-size: $fontSize14;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $fontColor7;
  position: absolute;
  top: 30px;
  transition: all 0.2s;
}

button {
  outline: none !important;
}

a {
  text-decoration: none !important;
}

.contact-section .form-control {
  font-size: $fontSize18;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $fontColor1;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  border-bottom: 1px solid #dde5ed;
  width: 261px;
  padding: 0px;
  padding-top: 22px;
  box-sizing: content-box;
}

.contact-section .form-control:focus + label {
  top: 0;
  transition: all 0.2s;
}

.contact-section .form-control:not(:placeholder-shown) + label {
  top: 0;
  transition: all 0.2s;
}

.contact-section .form-control::-webkit-input-placeholder,
.contact-section #message::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $fontColor1;
}

.contact-section .form-control::-moz-placeholder,
.contact-section #message::-moz-placeholder {
  /* Firefox 19+ */
  color: $fontColor1;
}

.contact-section .form-control:-ms-input-placeholder,
.contact-section #message::-ms-input-placeholder {
  /* IE 10+ */
  color: $fontColor1;
}

.contact-section .form-control:-moz-placeholder,
.contact-section #message::-moz-placeholder {
  /* Firefox 18- */
  color: $fontColor1;
}

.download-section .form-control::-webkit-input-placeholder,
.download-section .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $fontColor4;
}

.download-section .form-control::-moz-placeholder,
.download-section .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: $fontColor4;
}

.download-section .form-control:-ms-input-placeholder,
.download-section .form-control::-ms-input-placeholder {
  /* IE 10+ */
  color: $fontColor4;
}

.download-section .form-control:-moz-placeholder,
.download-section .form-control::-moz-placeholder {
  /* Firefox 18- */
  color: $fontColor4;
}

.contact-section .check {
  width: auto;
  float: left;
}

.contact-section .checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00407e;
  max-width: calc(100% - 250px);
  @include apercubold;
}

.contact-section .checkbox-container {
  padding: 0 0 0 30px;
  color: #00407e;
}

.contact-section .form-group {
  position: relative;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #365ea8;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #365ea8;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #365ea8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.contact-section textarea {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00407e;
  border: none;
  resize: none;
  display: block;
  margin: 5px 0 0 0;
  min-height: 300px;
  width: 100% !important;
}

.contact-section textarea:focus {
  outline: none;
}

.contact-section .button {
  width: 229px;
  height: 56px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 15px;
  background: $fontColor6;
}

.contact-section .button:focus {
  outline: none;
}

.accordian-section {
  background-color: #f5f8fa;
  width: 100%;
  overflow: hidden;
  margin-top: 60px;
  padding: 30px 0 0 0;
}

.accordian-section h2 {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor1;
}

.accordian-section p {
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor4;
  margin-bottom: 50px;
}

.panel {
  border-radius: 4px !important;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.03) !important;
  border: solid 1px #dde5ed !important;
  background-color: #ffffff !important;
  padding: 28px 20px 28px 28px !important;
  margin-bottom: 20px !important;
}

.panel-body {
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #506d85;
  border: none !important;
  padding: 15px 50px 0 0 !important;
}

.panel-title {
  font-size: $fontSize20 !important;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222 !important;
}

.panel-default > .panel-heading {
  background-color: #fff !important;
  border: none !important;
}

.panel-group .panel-heading {
  padding: 0px !important;
}

.panel-title span {
  cursor: pointer;
}

.panel-title span[aria-expanded="true"] {
  color: $fontColor6;
  cursor: pointer;
}

.panel-title span[aria-expanded="true"] span {
  width: 44px;
  height: 44px;
  float: right;
  background: url("assets/images/minus.png") center 50% no-repeat !important;
  border: 1px solid $fontColor6;
  margin: -10px 0 0 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.panel-title span[aria-expanded="false"] span {
  width: 44px;
  height: 44px;
  float: right;
  background: url("assets/images/plus.png") center center no-repeat;
  border: 1px solid $fontColor6;
  margin: -10px 0 0 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.panel-title a:hover,
.panel-title a:focus {
  text-decoration: none;
}

.btn.accordion {
  width: 229px;
  height: 56px;
  border-radius: 4px;
  background-color: $btnBackgroundColor;
  font-size: $fontSize16;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 46px;
  margin: 40px auto 80px auto;
  float: none;
  display: block;
  color: $fontColor2;
}

.contact-section .form-group.mobile {
  display: none;
}

.contact-section.support-new2 .content {
  margin-top: 0px;
}

.contact-section.support-new .content {
  margin-bottom: 40px;
}

.contact-section.support-new .content {
  padding-bottom: 40px;
}

.contact-section.support-new h4 {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #18342f;
  margin-top: 26px;
}

.contact-section.support-new p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #506d85;
}

.contact-section.support-new a {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ed8808;
}

/* download section */
.download-section.edit {
  background: url("assets/images/bg.png") no-repeat;
  padding-top: 0px;
  width: 100%;
  margin-top: 0px;
  background-size: cover;
}

#contactsucess {
  margin-top: -24px;
  margin-bottom: 15px;
  font-family: $fontFamily;
  color: #ed8808;
  font-weight: 400;
  display: none;
  text-align: center;
  padding-top: 20px;
}

#getsucess {
  margin-top: -24px;
  margin-bottom: 15px;
  font-family: $fontFamily;
  color: #ed8808;
  font-weight: 400;
  display: none;
  widows: 100%;
  text-align: center;
  padding-top: 10px;
}

form.msgerror {
  position: relative;
  border: none !important;
  &:before {
    // content: "!";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $secondary;
    color: #fff;
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    position: absolute;
    top: 41%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    left: 10px;
  }
  span {
    border: none;
    color: $secondary !important;
    font-size: 16px !important;
  }
}

form.msgerror span,
form.Success_sent span {
  color: #44b672 !important;
  text-shadow: 1px 1px #333;
  text-align: left;
  align-items: center;
  display: flex;
  margin-right: auto;
  width: 100%;
  font-size: 16px !important;
  font-family: $fontFamily;
  font-weight: 700;
  padding-left: 33px;
}

form.msgerror {
  // border-bottom: 1px solid #44b672 !important;
  text-shadow: 1px 1px #333;
  box-sizing: border-box;
}

form.Success_sent span {
  color: #fff !important;
}

form.Success_sent.d_bottom span {
  color: #ed8808 !important;
}

.download-section.edit h2 {
  margin-top: 150px;
  margin-bottom: 20px;
  font-size: 55px;
}

.download-section.edit span {
  margin-bottom: 20px;
}

.download-section.edit .get {
  color: #fff;
  margin-bottom: 10px;
}

.lowest-section.edit {
  padding: 50px 0 50px 0;
}

.discount-section {
  width: 100%;
  background-color: #f5f8fa;
  overflow: hidden;
  padding: 30px 0 290px 0;
}

.discount-section h2 {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor1;
}

.discount-section h3 {
  font-size: $fontSize20;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $fontColor1;
}

.discount-section p {
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: $fontColor4;
}

.discount-section .gap {
  padding-top: 140px;
}

.behalf-section {
  width: 1206px;
  background-image: linear-gradient(289deg, #399ffa, #0ab2c7);
  margin: -290px auto 0 auto;
  overflow: hidden;
  padding-bottom: 10px;
}

.behalf-section h2 {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $fontColor2;
  margin: 40px 0 0 0;
  text-align: center;
  padding-bottom: 20px;
}

.behalf-section p {
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor2;
  padding: 0 80px 20px 80px;
}

.behalf-section span {
  font-size: $fontSize20;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor2;
  line-height: 26px;
  margin-top: 20px;
  display: block;
  padding: 0 25px;
}

.behalf-section ul {
  list-style: none;
  text-align: center;
}

.behalf-section img {
  margin: 0 auto;
}

.line {
  width: 263px;
  display: block;
  border-bottom: 1px solid #dde5ed;
  position: absolute;
  top: 22px;
  left: 110px;
}

.oval {
  width: 12px;
  height: 12px;
  border: solid 1px #8ba6c1;
  display: block;
  border-radius: 12px;
  position: absolute;
  top: 40px;
  right: 0px;
}

.discount-section .gap .repeat {
  position: relative;
  padding-bottom: 40px;
}

/* review section */
.review-section h2 span {
  display: flex;
  min-height: 87px;
  font-size: 32px;
  justify-content: center;
  align-items: center;
}

.review-section h2 span {
  position: relative;

  &::before {
    position: absolute;
    background: url("assets/images/invalid-name.png") right top no-repeat;
    left: 0;
    width: 107px;
    height: 87px;
    content: "";
    top: -20px;
  }

  &::after {
    position: absolute;
    background: url("assets/images/copy.png") right top no-repeat;
    right: 0;
    width: 107px;
    height: 87px;
    content: "";
    top: 30px;
  }
}

.review-section h2 {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor1;
  padding: 22px 0 0 0;
  margin: 64px 138px 70px 138px;
}

.review-section li {
  margin-bottom: 30px;
}

.review-section .bg {
  padding: 40px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 24px 0 var(--dark-5);
  border: solid 1px #dde5ed;
  background-color: #ffffff;
}

.review-section ul {
  list-style: none;
}

.download-section.edit.change {
  background: none;
  background-color: #f5f8fa;
  text-align: center;
}

.download-section.edit.change h2 {
  margin: 0px;
  padding: 66px 0 12px 0;
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor1;
}

.download-section.edit.change span {
  font-size: $fontSize24;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  color: $fontColor1;
}

.download-section.edit.change span.get {
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: $fontColor7;
}

.download-section.edit.change form {
  margin: 0 auto;
  width: 469px;
  height: 56px;
  border-radius: 6px;
  border: solid 1px #dde5ed;
  background-color: #ffffff;
  box-shadow: none;
}

.download-section.edit.change .btn {
  width: 165px;
  height: 44px;
}

.download-section.edit.change {
  min-height: inherit;
  padding-bottom: 70px;
}

.review-section p {
  font-size: $fontSize21;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $fontColor1;
}

.review-section .slide span {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #506d85;
  margin-top: 50px;
  display: block;
}

.slick-dots {
  display: none !important;
}

.review-section .bg {
  margin-right: 30px !important;
  margin-bottom: 30px !important;
}

.header-section {
  min-height: 75px;
}

.card-section h3 {
  font-size: $fontSize20;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $fontColor3;
  margin: 50px 0 15px 0;
}

.card-section h2 {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: -1px;
  color: $fontColor5;
  margin: 0 0 20px 0;
}

.card-section p {
  font-size: $fontSize21;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.52;
  letter-spacing: normal;
  color: $fontColor4;
  padding-bottom: 20px;
}

.card-section ul {
  list-style: none;
}

.card-section li {
  font-size: $fontSize21;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $fontColor1;
  background: url("assets/images/star.png") left 11px no-repeat;
  padding-left: 40px;
}

.save-section img {
  margin-bottom: -124px;
  margin-left: -22px;
}

.left-gap {
  padding-left: 50px;
}

.welcome-section.edit .search li {
  width: 300px;
}

.save-section {
  width: 100%;
  background-image: linear-gradient(292deg, #399ffa, #0ab2c7);
  padding: 40px 0;
  margin-top: 50px;
}

.save-section h2 {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $fontColor2;
  margin-top: 80px;
}

.save-section p {
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: $fontColor2;
}

.save-section ul {
  list-style: none;
  padding: 18px 0;
}

.save-section li {
  font-size: $fontSize16;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: $fontColor2;
  background: url("assets/images/star2.png") left 6px no-repeat;
  padding-left: 40px;
}

.welcome-section.edit {
  background: #fff;
  padding: 130px 0 0 0;
}

.welcome-section.edit .search {
  padding-bottom: 0px;
}

.welcome-section.edit .search img {
  margin-bottom: 20px;
}

.signUp-section .bg {
  border-radius: 16px;
  background-color: #18342f;
  text-align: center;
  padding: 30px 0 0 0;
  overflow: hidden;
}

.signUp-section h2 {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor2;
}

.signUp-section form {
  width: 960px;
  margin: 40px auto 65px auto;
  border-radius: 6px;
  box-shadow: 0 0 64px 0 rgba(29, 37, 45, 0.1);
  border: solid 1px #dde5ed;
  background-color: #ffffff;
  overflow: hidden;
  padding: 1px;
}

.signUp-section .field-one {
  width: 329px;
  float: left;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  height: 90px;
  border-right: 1px solid #bed6f0;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8ba6c1;
  padding-left: 25px;
}

.signUp-section .field-second {
  width: 365px;
  float: left;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  height: 90px;
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $fontColor7;
  padding-left: 25px;
}

.signUp-section .btn {
  width: 194px;
  height: 56px;
  margin: 18px 15px 0 0;
  float: right;
  background: $fontColor6;
}

.prescription-section {
  position: relative;
  padding-bottom: 150px;
}

.prescription-section h2 {
  font-size: $fontSize32;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $fontColor1;
  margin: 60px 0 70px 0;
}

.prescription-section h3 {
  font-size: $fontSize20;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $fontColor5;
  padding-top: 18px;
}

.prescription-section p {
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: $fontColor4;
}

.petcard {
  width: 955px;
  border: solid 20px #ffffff;
  border-left: none;
  background-color: #18342f;
  position: absolute;
  bottom: -91px;
  right: 322px;
}

.prescription-section .comman-width {
  position: relative;
}

.petcard {
  padding: 30px 0 73px 30px;
  margin-top: 32px;
}

.petcard h4 {
  font-size: $fontSize22;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $fontColor2;
}

.petcard p {
  font-size: $fontSize16;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: $fontColor2;
  padding-bottom: 25px;
  padding-right: 20px;
}

.petcard ul {
  list-style: none;
  width: 600px;
}

.petcard li {
  width: 50%;
  float: left;
  font-size: $fontSize16;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.94;
  letter-spacing: normal;
  color: $fontColor2;
  background: url("assets/images/star.png") left 7px no-repeat;
  padding-left: 30px;
  padding-bottom: 10px;
}

.welcome-section .comman-width {
  position: relative;
}

.modal-dialog {
  width: 344px !important;
  border-radius: 16px;
}

.contact-section.card-modal .content {
  border-radius: 16px;
}

.modal-backdrop.in {
  display: none !important;
}

.modal-dialog {
  margin: 50px 0 0 0 !important;
  float: right;
  border-radius: 4px;
}

.modal {
  top: 220px !important;
  right: 20px !important;
  padding-top: 45px;
}

.btn.btn-default.close {
  position: absolute;
  top: 2px;
  right: 14px;
  width: auto;
  background: none;
  color: #243d56;
  font-size: 20px;
  opacity: 1;
}

.contact-section.card-modal .content {
  padding: 25px 35px;
  box-shadow: 0 0 64px 0 rgba(29, 37, 45, 0.1), 0 2px 23px 0 rgba(0, 0, 0, 0.16);
}

.contact-section.card-modal button {
  width: 159px;
  height: 48px;
  background-color: #18342f;
  color: #fff;
}

.contact-section.card-modal .form-control {
  width: 100%;
}

.getCard:focus {
  outline: none;
}

.getCard {
  width: 344px;
  height: 64px;
  border-radius: 40px;
  box-shadow: 0 0 64px 0 rgba(29, 37, 45, 0.1), 0 2px 23px 0 rgba(0, 0, 0, 0.16);
  background: #fff url("assets/images/arrow.png") 97% center no-repeat;
  position: fixed;
  bottom: 24px;
  right: 24px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 64px;
  letter-spacing: normal;
  color: #18342f;
  display: block;
  z-index: 999;
}

.getCard:hover,
.getCard:focus {
  text-decoration: none;
  color: #23527c;
}

.modal-open {
  overflow: auto;
  padding: 0 !important;
}

.getCard span {
  background: url("assets/images/get-card.webp") 8% center no-repeat;
  display: block;
  height: 100%;
  text-align: center;
}

#myModal {
  z-index: 99999;
  bottom: 24px;
  top: unset !important;

  .modal-dialog {
    position: absolute;
    bottom: 0px;
    right: 24px;
  }
}

.inner-gap {
  padding-right: 0px;
}

.right .alert-success {
  display: none;
  line-height: 22px;
  position: absolute;
  margin-top: 8px;
  margin-bottom: 0px;
  left: 0px;
  bottom: 15px;
  padding: 10px;
}

.search-fields .form-section {
  overflow: visible;
}

.serachlist {
  position: absolute;
  top: 85px;
  left: 0px;
  background: #fff;
  z-index: 9999999;
}

.btn.btn-default.close:focus {
  outline: none;
  box-shadow: none;
}
.easy-section .card {
  transition: all 0.8s ease;
}
/* responsive */
@media only screen and (min-width: 1024px) {
  .review-section .slick-slide {
    width: 50% !important;
  }
}

// @media only screen and (max-width: 1360px) {
//   .header-section {
//     min-height: inherit;
//   }
//   .save-section img {
//     margin-bottom: -80px;
//   }
//   .comman-width {
//     width: 96%;
//     padding: 0 2%;
//   }
//   .support-section .support {
//     width: 100%;
//   }
//   .navbar li a {
//     font-size: 13px;
//   }
//   .navbar li {
//     padding: 0 13px;
//   }
//   .banner-section h2 {
//     font-size: 35px;
//   }
//   .banner-section p {
//     font-size: 20px;
//   }
//   .banner-section .over {
//     top: -180px;
//   }
//   .banner-section .over {
//     left: auto;
//   }
//   .carosuel-section .nav-btn.next-slide.slick-arrow {
//     right: -35px;
//   }
//   .carosuel-section .nav-btn.prev-slide.slick-arrow {
//     left: -35px;
//   }
//   .work-section {
//     width: 70%;
//   }
//   .contact-section .right {
//     width: 60%;
//   }
//   .contact-section .content {
//     margin-top: -20px;
//   }
//   .search {
//     padding-bottom: 30px;
//   }
//   .download-section.edit h2 {
//     font-size: 36px;
//     margin-top: 110px;
//   }
//   .lowest-section.edit {
//     padding: 30px 0;
//   }
//   .discount-section {
//     padding: 20px 0 10px 0;
//   }
//   .behalf-section {
//     margin-top: 0px;
//   }
//   .line {
//     width: 158px;
//   }
//   .oval {
//     right: auto;
//     left: 275px;
//   }
//   .discount-section .gap {
//     padding-top: 65px;
//   }
//   .behalf-section {
//     width: 100%;
//   }
//   .save-section h2 {
//     margin-top: 45px;
//   }
//   .save-section {
//     padding: 22px 0;
//   }
//   .welcome-section.edit {
//     padding-top: 70px;
//   }
//   .search {
//     padding-top: 10px;
//   }
//   .signUp-section form {
//     width: 90%;
//   }
//   .signUp-section .field-one,
//   .signUp-section .field-second {
//     width: 300px;
//   }
//   .prescription-section h2 {
//     margin: 30px 0 10px 0;
//   }
//   .prescription-section h3 {
//     font-size: 16px;
//   }
//   .prescription-section p {
//     font-size: 14px;
//   }
//   .prescription-section {
//     padding-bottom: 150px;
//   }
//   .getCard {
//     top: 430px;
//   }
//   .petcard {
//     width: 70%;
//   }
// }
@media only screen and (max-width: 1024px) {
  .getCard {
    top: 500px;
  }

  .prescriptionimg-responsive {
    width: 100%;
  }

  .comman-width {
    width: 96%;
    padding: 0 2%;
  }

  .welcome-section h2 {
    margin-top: 30px;
  }

  .search-fields {
    min-height: inherit;
    padding-bottom: 30px;
  }

  .lowest-section.edit .search-fields {
    padding-bottom: 0px;
  }

  .download-section {
    margin-top: 10px;
  }

  .download-section h2 {
    font-size: 40px;
  }

  .download-section .form-control {
    width: 220px;
  }

  .download-section form {
    width: 398px;
  }

  .footer-section h2 {
    line-height: 20px;
  }

  .carosuel-section .nav-btn.next-slide.slick-arrow {
    right: -35px;
  }

  .carosuel-section .nav-btn.prev-slide.slick-arrow {
    left: -35px;
  }

  .banner-section .over {
    top: -157px;
    left: 4%;
  }

  .honest-section {
    padding-top: 40px;
  }

  .support-section .support {
    width: 100%;
  }

  .navbar li {
    padding: 0 8px;
  }

  .navbar li a {
    font-size: 12px;
  }

  .navbar li.download a {
    font-size: 12px !important;
  }

  .header-section .logo {
    width: 15%;
    margin: 13px auto;
  }

  .navbar .signUp {
    width: 70px;
  }

  .carosuel-section .nav-btn.next-slide.slick-arrow {
    right: -30px;
  }

  .download-section.edit.change .form-control {
    width: 291px;
  }

  .download-section.edit h2 {
    font-size: 26px;
  }

  .download-section .app {
    max-width: 40%;
  }

  .download-section.edit h2 {
    margin-top: 80px;
  }

  .download-section.edit {
    min-height: inherit;
    padding-bottom: 30px;
  }

  .line {
    width: 78px;
  }

  .oval {
    right: auto;
    left: 192px;
  }

  .img-responsive.pharmacy {
    margin-top: 120px;
  }

  .discount-section .gap {
    padding-top: 25px;
  }

  .behalf-section span {
    font-size: 15px;
    line-height: 20px;
  }

  .download-section.edit.change .form-control {
    width: 291px;
  }

  .download-section span {
    font-size: 20px;
  }

  .header-section .logo {
    margin: 5px auto;
  }

  .header-section {
    min-height: 75px;
  }

  .btn.accordion {
    margin: 0px auto 25px auto;
  }

  .navbar ul,
  .navbar .login {
    padding-top: 10px;
  }

  .card-section h3 {
    margin: 25px 0 15px 0;
  }

  .save-section img {
    margin-bottom: 0px;
    margin-top: 100px;
  }

  .search-fields .form-control {
    width: 53%;
  }

  .welcome-section.edit {
    padding-top: 30px;
  }

  .signUp-section h2 {
    font-size: 25px;
  }

  .signUp-section .field-one,
  .signUp-section .field-second {
    width: 200px;
  }

  .signUp-section .bg {
    padding: 10px 0 0 0;
  }

  .signUp-section form {
    margin: 30px auto 40px auto;
  }

  .petcard {
    position: relative;
    bottom: auto;
    right: auto;
    width: 100%;
    overflow: hidden;
    border-left: 0px;
    border-right: 0px;
  }

  .prescription-section {
    padding-bottom: 0px;
  }

  .save-section img {
    margin-top: 20px;
  }

  .save-section h2 {
    margin-top: 10px;
  }

  .review-section p {
    font-size: 16px;
  }

  .welcome-section.edit .search li {
    width: 220px;
    font-size: 18px;
  }

  .petcard {
    padding: 15px 0 20px 30px;
  }

  .prescription-section p {
    padding-bottom: 10px;
  }

  .petcard li {
    padding-bottom: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .search_pharmacy_popup .search_pharmacy_select {
    top: 360px !important;
  }

  .header-section .logo {
    float: none;
    display: block;
  }

  .header-section .logo img {
    margin-top: 0px;
    margin-bottom: 0px;
    display: block;
  }

  .welcome-section h2 {
    font-size: 36px;
    letter-spacing: -3px;
  }

  .accept-section h2 {
    margin-top: 30px;
  }

  .work-section {
    padding: 5px 4% 5px 4%;
    width: auto;
  }

  .slick-slider {
    margin: 0 30px;
  }

  .download-section h2 {
    font-size: 34px;
  }

  .banner-section .over {
    top: -160px;
  }

  .banner-section h2 {
    font-size: 34px;
  }

  .banner-section p {
    font-size: 20px;
  }

  .support-section .support {
    margin-top: -140px;
  }

  .navbar {
    width: 100%;
  }

  .contact-section .right {
    width: 48%;
  }

  .review-section p {
    font-size: 15px;
  }

  .review-section .slide span {
    font-size: 13px;
    margin-top: 35px;
  }

  .review-section .bg {
    padding: 20px;
    height: auto;
  }

  .download-section.edit.change form {
    width: 100%;
  }

  .download-section.edit.change .btn {
    float: right;
    margin-right: 7px;
  }

  .download-section.edit.change h2 {
    padding-top: 25px;
  }

  .download-section.edit.change {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .search-fields .form-section .btn {
    display: block;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    background-position-x: 12px;
    background-position-y: 12px;
  }

  .HonestDiscountFooter {
    text-indent: -9999px;
  }

  .slideToggle {
    display: block;
    position: absolute;
    top: 30px;
    left: 30px;
  }

  .navbar-nav {
    display: none;
  }

  .navbar {
    position: static !important;
    min-height: inherit !important;
    padding-bottom: 15px;
  }

  .navbar .login {
    position: absolute;
    top: 24px;
    right: 22px;
    padding-top: 0px;
  }

  .welcome-section h2 {
    font-size: 28px;
    // // letter-spacing: -1px;
    // line-height: 32px;
    font-family: $fontFamily;
    text-align: left;
    letter-spacing: normal;
  }

  .search li {
    width: 160px;
    font-size: 15px;
  }

  .search ul {
    margin-bottom: 0px;
  }

  .download-section {
    background-size: unset;
    object-fit: cover;
    background-position: center;
  }

  .footer-adjust {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-flow: column;
  }

  .footer-adjust .first {
    order: 2;
    width: 45%;
  }

  .footer-adjust .second {
    order: 3;
    width: 45%;
    padding-top: 34px;
  }

  .copyright li {
    font-size: 13px;
  }

  .footer-adjust .third {
    order: 1;
    width: 100%;
  }

  .footer-adjust .fourth {
    order: 4;
    width: 100%;
  }

  .download-section h2 {
    margin-bottom: 20px;
  }

  .download-section .get {
    color: #fff;
  }

  .download-section .btn {
    width: 36px;
    height: 36px;
    text-indent: -9999px;
    background: url("assets/images/arrow_download.png") left top no-repeat;
  }

  .download-section form {
    width: 261px;
    height: 47px;
  }

  .download-section .form-control {
    padding-top: 20px;
    padding-bottom: 20px;
    height: 47px;
    border: none;
    box-shadow: none;
  }

  .download-section .form-control.differ {
    padding: 22px 0 20px 15px;
  }

  .download-section {
    padding-top: 30px;
  }

  .footer-section .social li {
    float: left;
    width: 50%;
  }

  .footer-section {
    padding: 0px;
  }

  .footer-adjust .fourth h2 {
    margin-top: 5px;
  }

  .copyright {
    margin-top: 10px;
    padding: 10px 0 15px 0;
  }

  .search-fields form .btn {
    display: none;
  }

  .search-fields .form-control {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    box-shadow: 0 0 64px 0 rgba(29, 37, 45, 0.1);
    // border: solid 1px #dde5ed;
    background-color: #ffffff;
    padding-left: 45px;
    padding-right: 15px;
    margin: 0 0 7px 0;
    min-height: initial !important;
  }

  .btn-hover-effect {
    .insider {
      left: -350px;
    }

    &:hover {
      .insider {
        left: 350px;
      }
    }
  }

  .search-fields .form-section {
    background: none;
  }

  .search-fields .btn.lowest {
    display: block;
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    // height: 48px;
    margin: 8px 0 0 0;
    font-family: $fontFamily;
  }

  .search-fields form {
    background: none;
    border-radius: 0px;
  }

  .navbar-nav {
    background: #ed8808;
    margin: 5px 0 0 0 !important;
  }

  .navbar li {
    width: 100%;
    float: left;
    border-bottom: 1px solid #fff;
    padding: 8px 12px;
    box-shadow: none;
  }

  .navbar li a {
    padding: 0px !important;
  }

  .navbar ul {
    padding: 0px 0 0 0;
  }

  .navbar {
    width: 100%;
  }

  .navbar li a.active span {
    display: none;
  }

  .navbar li.download a {
    padding-left: 0px !important;
  }

  .navbar li a.active {
    color: #fff;
  }

  .navbar.less {
    padding-bottom: 0px;
  }

  .navbar.less ul {
    margin-bottom: 0px;
  }

  .header-section .comman-width {
    padding: 0px;
    width: 100%;
  }

  .footer-section .logo {
    display: block;
  }

  .footer-section .logo img {
    margin: 0px;
  }

  .footer-section h2 span {
    display: none;
  }

  .banner-section {
    height: 200px;
  }

  .banner-section img {
    height: 100%;
  }

  .banner-section .over {
    top: -175px;
  }

  .banner-section p {
    font-size: 16px;
    padding-right: 20px;
  }

  .card-section h3 {
    font-size: 16px;
  }

  .card-section p {
    font-size: 14px;
  }

  .card-section li {
    font-size: 15px;
  }

  .honest-section {
    padding-top: 20px;
  }

  .support-section {
    margin-top: 20px;
  }

  .support-section .support {
    margin-top: -100px;
    padding: 20px 0;
  }

  .support-section p {
    padding: 0 40px;
    line-height: 26px;
  }

  .footer-section .logo {
    float: none;
  }

  .footer-section .logo img {
    display: block;
  }

  .contact-section .content {
    margin-top: -30px;
  }

  .contact-section .content {
    padding: 20px 15px 20px 15px;
  }

  .contact-section .left {
    border: none;
    width: 100%;
  }

  .contact-section .right {
    width: 100%;
  }

  .contact-section h2 {
    margin-bottom: 10px;
  }

  .contact-section .form-control {
    font-size: 16px;
    width: 100%;
  }

  .contact-section .form-group {
    margin-bottom: 10px;
  }

  .contact-section {
    display: flex;
    background: #f5f8fa;
  }

  .contact-section .form-group.destop {
    display: none;
  }

  .contact-section .form-group.mobile {
    display: block;
    height: 68px;
  }

  .contact-section .right {
    min-height: inherit;
  }

  .contact-section .btn {
    margin-bottom: 0px;
    width: 100%;
  }

  .contact-section .right {
    margin-left: 0px;
    width: 100%;
    border: none;
    min-height: inherit;
    padding-left: 0px;
  }

  .accordian-section {
    margin-top: 0px;
    padding-top: 10px;
  }

  .accordian-section p {
    margin-bottom: 20px;
  }

  .panel-title a span,
  .panel-title a[aria-expanded="true"] span {
    width: 22px;
    height: 22px;
    background-size: 50%;
  }

  .panel {
    margin-bottom: 0px !important;
  }

  .btn.accordion {
    margin: 0px 0 15px 0;
    width: 100%;
  }

  #accordion {
    margin-bottom: 12px;
  }

  .panel-title {
    font-size: 18px !important;
  }

  .accordian-section h2 {
    font-size: 22px;
  }

  .panel-title a span,
  .panel-title a[aria-expanded="true"] span {
    margin-top: 0px;
  }

  .contact-section.support-new .content {
    margin-top: -40px;
    margin-bottom: 10px;
    padding-bottom: 25px;
  }

  .contact-section.support-new a {
    font-size: 20px;
  }

  .contact-section.support-new .content img {
    margin: 0 auto;
    display: block;
  }

  .contact-section.support-new .content {
    text-align: center;
  }

  .contact-section.support-new .content {
    margin-top: -25px;
  }

  .banner-section p br {
    display: none;
  }

  .search {
    padding: 0 0 150px 0;
  }

  .accept-section li img {
    max-width: 140px;
  }

  .download-section.edit h2 {
    margin-top: 40px;
  }

  .download-section.edit {
    background-size: cover;
    background-position: top;
    padding-bottom: 0px;
  }

  .download-section.edit.change.available .form-control {
    width: 88%;
  }

  .download-section.edit.change.available .btn {
    width: 36px;
    height: 36px;
  }

  .download-section.edit.change.available form {
    height: 50px;
  }

  .download-section.edit.change.available form,
  .download-section.edit .form-control {
    box-shadow: 0 12px 13px -12px rgba(0, 0, 0, 0.34);
  }

  .review-section h2 {
    font-size: 18px;
    margin: 40px 10px 10px 10px;
  }

  .discount-section {
    text-align: center;
  }

  .discount-section img {
    margin: 0 auto;
    display: block;
  }

  .discount-section .line,
  .discount-section .oval {
    display: none;
  }

  .discount-section .img-responsive.pharmacy {
    display: none;
  }

  .discount-section .text-right {
    text-align: center;
    padding-top: 0px;
  }

  .lowest-section.edit {
    padding: 30px 0;
  }

  .discount-section h2 {
    font-size: 20px;
    margin: 0px;
  }

  .download-section.edit h2 {
    margin-top: 20px;
  }

  .slick-dots {
    display: block !important;
  }

  .slick-dots li button:before {
    font-size: 11px !important;
    color: #18342f !important;
  }

  .slick-dots {
    bottom: -25px !important;
  }

  .review-section .slick-dots {
    bottom: -20px !important;
  }

  .review-section {
    padding-bottom: 20px;
  }

  .slick-dots li {
    width: 8px !important;
    height: 8px !important;
  }

  .behalf-section span {
    padding: 0px;
  }

  .behalf-section .slick-dots li.slick-active button:before {
    font-size: 10px !important;
  }

  .slick-dots li.slick-active button:before {
    color: #fff !important;
    opacity: 1 !important;
  }

  .slick-dots li button:before {
    opacity: 1 !important;
  }

  .behalf-section p {
    padding: 0 10px 5px 10px;
  }

  .review-section .slick-dots li button:before {
    color: #7e93a9 !important;
  }

  .review-section .slick-dots li.slick-active button:before {
    color: #cb8c38 !important;
  }

  .review-section .bg {
    margin-right: 0px !important;
    height: auto;
  }

  .download-section.edit form {
    width: 100%;
  }

  .download-section.edit .form-control {
    width: 93%;
  }

  .download-section.edit .btn {
    margin-right: 0px;
  }

  .download-section.edit img.app {
    margin-bottom: 0px;
  }

  .download-section img.iphone {
    max-width: 100%;
    margin-bottom: -235px;
  }

  .review-section .slick-slider {
    margin: 0px !important;
  }

  .review-section .bg {
    width: 99%;
  }

  .header-section {
    min-height: inherit;
  }

  .card-section h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .card-section p {
    padding-bottom: 5px;
  }

  .save-section {
    margin-top: 20px;
    padding-bottom: 10px;
  }

  .save-section img {
    display: none;
  }

  .save-section h2 {
    margin-top: 0px;
  }

  .signUp-section form {
    background: none;
    border: none;
  }

  .signUp-section .field-one,
  .signUp-section .field-second {
    width: 100%;
    border: none;
    border-radius: 4px;
    height: 48px;
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 15px;
  }

  .signUp-section .btn {
    width: 100%;
    margin: 0px;
    height: 48px;
    font-size: 14px;
  }

  .petcard li {
    width: 100%;
  }

  .card-section p {
    font-size: 16px;
  }

  .save-section h2 {
    font-size: 25px;
  }

  .save-section li {
    padding-left: 25px;
  }

  .save-section ul {
    padding: 0px;
  }

  .welcome-section.edit .search img {
    width: 50%;
  }

  .signUp-section h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0px;
    padding: 0 18px;
  }

  .signUp-section form {
    margin: 20px auto;
  }

  .prescription-section h2 {
    font-size: 24px;
    margin-bottom: 0px;
    text-align: left;
  }

  .prescription-section h3 {
    font-size: 14px;
  }

  .petcard {
    border: 0px;
    margin: 15px 0;
    padding: 10px 0 20px 20px;
  }

  .card-section li {
    padding-left: 30px;
  }

  .slideToggle {
    top: 18px;
  }

  .navbar .login {
    top: 13px;
  }

  .getCard {
    top: 425px;
    right: 20px;
    z-index: 999;
  }

  #myModal {
    z-index: 99999;
  }

  #myModal .contact-section {
    background: none;
  }

  .welcome-center {
    padding-bottom: 80px;
  }

  .slick-slider {
    margin: 0 20px;
  }

  .contact-section.card-modal .content {
    width: 100%;
  }

  .discount-section .gap .repeat {
    padding-bottom: 0px;
  }

  .discount-section h3 {
    margin-top: 10px;
  }

  .behalf-section h2 {
    margin-top: 25px;
  }

  .review-section .slide span {
    margin-top: 20px;
  }

  .honest-section .row {
    display: flex;
    flex-direction: column;
  }

  .honest-section .first-one {
    order: 2;
  }

  .honest-section .second-one {
    order: 1;
  }

  .panel-title {
    font-size: 15px !important;
  }

  // .panel-body {
  //   font-size: 13px;
  // }
  .left-gap {
    padding-left: 0px;
  }

  .welcome-section.edit .search li {
    width: 180px;
  }

  .welcome-center {
    padding-bottom: 10px;
  }

  .welcome-section.edit .search li {
    width: 33%;
    font-size: 11px;
    float: left;
  }

  .navbar li a.active span,
  .navbar li a.home-active span {
    display: none;
  }

  .slideToggle:focus {
    outline: none;
  }

  .contact-section .right {
    border: none;
  }

  .work-section h2 {
    margin-top: 45px;
  }

  .search-fields .serachlist {
    // z-index: 99;
    top: 45px;
  }

  .panel {
    padding: 15px 10px 15px 15px !important;
  }

  .discount-section h3 {
    font-size: 16px;
  }

  .discount-section p {
    font-size: 14px;
  }

  .behalf-section h2 {
    font-size: 24px;
  }

  .behalf-section p {
    font-size: 14px;
  }

  .save-section h2 {
    font-size: 21px;
  }

  .save-section p {
    font-size: 14px;
    line-height: 21px;
  }

  .save-section li {
    font-size: 15px;
  }
}

@media only screen and (max-width: 420px) {
  .card-section li {
    background: url("assets/images/star.png") left 7px no-repeat;
  }

  .accept-section li img {
    max-width: 61px;
  }

  .slideToggle {
    top: 18px;
    left: 20px;
  }

  .navbar .login {
    top: 14px;
  }

  .search li {
    width: 110px;
    font-size: 11px;
  }

  .accept-section h2 {
    font-size: 13px;
  }

  .work-section {
    padding: 0 4% 0 4%;
  }

  .carosuel-section h2 {
    margin-bottom: 30px;
  }

  .download-section span {
    font-size: 16px;
    letter-spacing: 0px;
  }

  .download-section img {
    max-width: 45%;
  }

  .download-section .get {
    margin-top: 20px;
  }

  .copyright li,
  .copyright li a {
    font-size: 11px;
  }

  .accept-section .card {
    width: 94%;
    margin: 0 auto;
  }

  .search-fields .form-control {
    font-size: 12px;
  }

  .navbar-nav {
    margin: 10px 0 0 0 !important;
  }

  .banner-section .over {
    top: -190px;
  }

  .honest-section .small-heading {
    font-size: 16px;
  }

  .honest-section h2 {
    font-size: 20px;
  }

  .support-section {
    padding-bottom: 20px;
  }

  .support-section .support {
    margin-top: -40px;
    padding: 5px 0;
  }

  .support-section p {
    padding: 0 20px;
    line-height: 24px;
  }

  .download-section img {
    margin: 0 auto;
  }

  .download-section.edit.change h2 {
    padding-top: 25px;
    font-size: 22px;
  }

  .download-section.edit.change form {
    width: 100%;
  }

  .download-section.edit.change.available .form-control {
    width: 86%;
  }

  .download-section.edit.change.available {
    padding-bottom: 20px;
  }

  // .review-section h2 span {
  //   font-size: 16px;
  // }
  .review-section p {
    font-size: 14px;
  }

  .review-section span {
    font-size: 13px;
    margin-top: 30px;
  }

  .review-section .bg {
    height: 175px;
    padding: 15px;
  }

  .download-section.edit .form-control {
    width: 88%;
  }

  .download-section img.iphone {
    margin-bottom: -130px;
  }

  .welcome-section.edit .search li {
    width: 105px;
    float: left;
  }

  .welcome-section.edit .search img {
    margin-bottom: 0px;
  }

  .image {
    min-height: auto;
    margin-bottom: 10px;
  }

  .btn-hover-effect {
    .insider {
      left: -240px;
    }

    &:hover {
      .insider {
        left: 240px;
      }
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

[role="button"] {
  cursor: pointer;
}

.panel {
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
  padding: 15px;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
  color: inherit;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #dddddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}

.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}

.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}

.list-group + .panel-footer {
  border-top-width: 0;
}

.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0;
}

.panel > .table caption,
.panel > .table-responsive > .table caption,
.panel > .panel-collapse > .table caption {
  padding-right: 15px;
  padding-left: 15px;
}

.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel > .table:first-child > thead:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 3px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 3px;
}

.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
}

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #dddddd;
}

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0;
}

.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0;
}

.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

.panel > .table-responsive {
  margin-bottom: 0;
  border: 0;
}

.panel-group {
  margin-bottom: 20px;
}

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}

.panel-group .panel + .panel {
  margin-top: 5px;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #dddddd;
}

.panel-group .panel-footer {
  border-top: 0;
}

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #dddddd;
}

.panel-default {
  border-color: #dddddd;
}

.panel-default > .panel-heading {
  color: #333333;
  background-color: #f5f5f5;
  border-color: #dddddd;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #dddddd;
}

.panel-collapse ul li {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #506d85;
}

.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333333;
}

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #dddddd;
}

.panel-primary {
  border-color: #337ab7;
}

.panel-primary > .panel-heading {
  color: #ffffff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #337ab7;
}

.panel-primary > .panel-heading .badge {
  color: #337ab7;
  background-color: #ffffff;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #337ab7;
}

.panel-success {
  border-color: #d6e9c6;
}

.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6;
}

.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info {
  border-color: #bce8f1;
}

.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}

.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}

.panel-warning {
  border-color: #faebcc;
}

.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc;
}

.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc;
}

.panel-danger {
  border-color: #ebccd1;
}

.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1;
}

.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442;
}

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1;
}

.clearfix:before,
.clearfix:after,
.panel-body:before,
.panel-body:after {
  display: table;
  content: " ";
}

.clearfix:after,
.panel-body:after {
  clear: both;
}

.autopic_location {
  text-align: left;
  position: absolute;
  bottom: 140px;
  right: 38px;
}

.search_pharmacy_popup .search_pharmacy_select .filter_box .lists_box {
  text-align: left;
}

.popular_sesrch .title,
.recent_sesrch .title {
  background: #f7f9fa;
  padding-left: 15px;
  padding-right: 15px;
  font-family: $fontFamily;
  font-size: 12px;
  color: #506d85;
  line-height: 30px;
  font-weight: 500;
}

.getContainer {
  padding: 25px 50px 25px 35px !important;
}

.getCardHeading h2 {
  font-family: $fontFamily;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #18342f;
  margin-bottom: 30px;
}

.getCardHeading .close {
  right: 30px !important;
  top: 12px !important;
}

img.img-responsive.iphone {
  width: 108%;
}

.prescriptionimg-responsive {
  margin-top: 10px;
}

.acceptedsec {
  padding-top: 100px;
  padding-bottom: 61px;
  max-width: 1129px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    pointer-events: none;
  }

  .howitwork {
    max-width: 461px;

    h3,
    h2 {
      padding: 0;
      margin: 0;
    }

    h3 {
      font-size: 16px !important;
      font-weight: normal;
      @include apercuregular;
      color: $secondary;
      line-height: 19px;
      margin-bottom: 4px;
      letter-spacing: -0.5px;
    }

    h2 {
      @include apercubold;
      font-size: 30px;
      color: $headingcolor;
      margin-bottom: 13px;
      text-align: left;
    }

    p {
      @include apercuregular;
      font-size: 18px;
      color: $primarytext;
      letter-spacing: -0.51px;
      line-height: 30px;
      margin: 0;

      span {
        @include apercubold;
      }
    }

    a {
      width: 148px;
      height: 45px;
      border-radius: 20.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      margin-top: 30px;
      @include apercubold;
      font-size: 16px;
      color: $whitetext;
      background: #7fc534;
      opacity: 1;
    }
  }
}

.testimonial {
  padding-top: 124px;
  padding-bottom: 104px;

  .comman-width {
    max-width: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 48%,
      rgba(229, 223, 223, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(255, 255, 255, 1)),
      color-stop(48%, rgba(255, 255, 255, 1)),
      color-stop(100%, rgba(229, 223, 223, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 48%,
      rgba(229, 223, 223, 1) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 48%,
      rgba(229, 223, 223, 1) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 48%,
      rgba(229, 223, 223, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 48%,
      rgba(229, 223, 223, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e5dfdf', GradientType=0);

    .testimonial_inner {
      max-width: 1198px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      top: 50px;

      h3,
      h2 {
        padding: 0;
        margin: 0;
        padding-left: 78px;
      }

      h3 {
        font-size: 16px;
        font-weight: normal;
        @include apercuregular;
        color: $secondary;
        line-height: 19px;
        margin-bottom: 4px;
        letter-spacing: -0.5px;
        text-align: left;
      }

      h2 {
        @include apercubold;
        font-size: 30px;
        color: $headingcolor;
        margin-bottom: 13px;
        text-align: left;
        margin-bottom: 35px;
      }
    }
  }

  .slick-initialized .slick-slide {
    background: $whitetext;
    padding: 19px;
    width: 379px;
    margin-right: 30px;

    .shadow {
      border: solid 1.5px #7fc534;
      border-radius: 0 100px 0 0;
      min-height: 361px;
      padding: 51px 64px 0 44px;
      position: relative;

      p {
        padding: 0;
        margin: 0;
        @include apercubold;
        font-size: 20px;
        letter-spacing: -0.57px;
        line-height: 31px;
        color: #01427c;
        text-align: left;

        &:before {
          content: "“";
        }

        &:after {
          content: "”";
        }
      }

      h3 {
        padding: 0;
        margin: 0;
        position: absolute;
        bottom: 30px;
        @include apercuregular;
        letter-spacing: -0.46px;
        color: $primary;
        line-height: 11px;

        &:before {
          content: "- ";
        }
      }
    }
  }
}

.firstloadpricing {
  margin-top: 87px;
  max-width: 1049px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 54px;

  p.title {
    padding: 0;
    margin: 0;
    @include apercuregular;
    font-size: 16px;
    color: $secondary;
    letter-spacing: -0.5px;
    line-height: 19px;
  }

  .price_searcharea {
    margin-top: 17px;
    display: flex;

    .zipbox {
      min-width: 344px;
      max-width: 344px;
      margin-right: 14px;

      input[type="tel"] {
        width: 100%;
        min-height: 67px;
        border-radius: 33.5px;
        border: 2px solid $formborder;
        background: transparent;
        outline: none;
        color: $headingcolor;
        font-size: 25px;
        @include apercubold;
        letter-spacing: -0.05px;
        padding-left: 22px;
      }

      input[type="checkbox"] {
        display: none;

        & + label {
          display: flex;
          margin-top: 18px;
          @include apercuregular;
          font-size: 20px;
          letter-spacing: -0.44px;
          color: $pharmacypop;
          align-items: center;

          span {
            width: 24px;
            height: 24px;
            background: url(assets/images/uncheck.svg) no-repeat;
            background-size: 100%;
            margin-right: 10px;
          }
        }

        &:checked {
          & + label {
            span {
              width: 24px;
              height: 24px;
              background: url(assets/images/check.svg) no-repeat;
              background-size: 100%;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .medicinenamesearch {
      min-width: calc(100% - 358px);
      max-width: calc(100% - 358px);

      app-search {
        .search-fields {
          margin-bottom: 20px;
        }

        .form-section {
          background: transparent;
          border: 2px solid $formborder;
          border-radius: 33.5px;
          min-height: 67px;

          .btn[type="submit"] {
            display: none;
          }

          .form-control {
            width: 100%;
            background: transparent;
            margin-left: 0;
            padding-left: 25px;
            color: $headingcolor;
            font-size: 25px;
            @include apercubold;
            letter-spacing: -0.05px;
            min-height: 67px;

            &::placeholder {
              color: $headingcolor;
            }
          }

          .lowest {
            background: $primary;
            border-radius: 20.5px;
            min-height: 45px;
            max-height: 45px;
            @include apercubold;
            font-size: 16px;
            color: $whitetext;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            transform: translate(0, -50%);
            min-width: 190px;
            max-width: 190px;
            letter-spacing: 0.14px;
          }
        }

        .popular_sesrch {
          .title {
            background: transparent;
            padding: 0;
            margin: 0;
            margin-bottom: 10px;
            @include apercubold;
            font-size: 20px;
            color: $pharmacypop;
            font-weight: normal;
            line-height: 19px;
          }

          ul {
            display: flex;
            flex-flow: row wrap;

            li {
              background: transparent;
              border: 1.2px solid $otherbordercolor;
              display: flex;
              align-items: center;
              justify-content: center;
              min-height: 45px;
              padding-left: 15px;
              padding-right: 15px;
              letter-spacing: 0.07px;
              cursor: pointer;
              color: $otherbordercolor;
              margin-right: 10px;
              line-height: normal;
              border-radius: 20.5px;
              margin-bottom: 10px;
              @include apercuregular;
              font-size: 16px;
              max-height: 45px;
            }
          }
        }

        .recent_sesrch,
        .serachlist {
          .title {
            background: $formborder;
            color: $whitetext;
            @include apercubold;
            font-size: 16px;
          }

          ul {
            li {
              color: $formborder;
              background: transparent;
              border-bottom: 1.2px solid $formborder;
              @include apercuregular;
              font-size: 16px;
              font-weight: normal;
              border-top: none;

              &:last-child {
                border: none;
              }

              &:hover {
                background: $formborder;
                color: $whitetext;
              }
            }
          }
        }
      }
    }
  }
}

.pharmacyPage {
  margin-left: auto;
  margin-right: auto;
  background: transparent !important;
  height: auto !important;

  .container {
    max-width: 1049px;

    .pharmacy_box {
      .select_filterList_parent {
        min-width: 307px;

        h2 {
          @include apercubold;
          font-size: 20px;
          color: $pharmacypop;
          line-height: normal;
          margin-bottom: 0;
        }

        .filter_box {
          margin-bottom: 17.9px;

          > label {
            @include apercuregular;
            font-size: 14px;
            letter-spacing: -0.53px;
            color: $formtypecolor;
            margin-bottom: 0;
            text-transform: capitalize;
          }

          > div.type_box {
            line-height: 25px;
            font-weight: normal;
            padding-left: 0;
            font-size: 20px;
            @include apercuregular;
            color: $formborder;
            border-bottom: solid 1px $formgrey;
            padding: 0 0 2.5px 0;
          }

          .type_box {
            &::before {
              background: url("assets/images/downarrow.svg") no-repeat;
              bottom: 7px;
              right: 9px;
            }

            &.Noddl {
              // &::before {
              //   content: "";
              //   background: url(assets/images/form_pinlocation.svg) no-repeat;
              //   position: absolute;
              //   width: 18px;
              //   height: 26px;
              //   display: block !important;
              // }
            }
          }
        }

        .update_btn {
          margin-top: 10px;
          min-width: 100%;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #7fc534;
          color: $whitetext;
          @include apercubold;
          font-size: 16px;
          letter-spacing: 0.14px;
          border-radius: 20.5px;
        }
      }

      .select_filterList_parent {
        .type_list {
          ul {
            li {
              @include apercuregular;
              color: $formborder;
              font-size: 16px;
              line-height: 35px;
              letter-spacing: -0.75px;

              &.active {
                color: $secondary;
              }
            }
          }
        }
      }

      .pharmacy_list {
        max-height: 360px;
        margin-top: 0;
        padding-right: 0;
        overflow-y: auto;

        &.smallcard {
          max-width: 307px;
        }

        h2 {
          @include apercubold;
          font-size: 20px;
          color: $pharmacypop;
          letter-spacing: -0.44px;
          line-height: normal;
          position: absolute;
          top: -37px;
          margin-bottom: 0;
        }
        .card:first-child {
          .name_Sec {
            margin-top: 0px;
          }
        }
        .card {
          background: transparent;
          border: none;
          box-shadow: none;
          padding: 0;
          line-height: normal;
          position: relative;
          min-height: 46px;
          border-bottom: solid 1px $formgrey;
          border-radius: 0;
          margin-bottom: 29px;

          &:after {
            content: "";
            width: 18px;
            height: 18px;
            display: block;
            position: absolute;
            background: url(assets/images/downarrow.svg) no-repeat;
            background-size: 100%;
            right: 0;
            bottom: 8px;
          }
          .name_Sec {
            margin-top: -3px;
            @media screen and (min-width: 0) and (max-width: 767px) {
              margin-top: 1px;
            }

            p {
              @include apercuregular;
              font-size: 20px;
              color: $formborder;
              letter-spacing: -0.75px;
              line-height: normal;
              font-weight: normal;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            span {
              @include apercuregular;
              font-size: 20px;
              color: $formborder;
              position: absolute;
              bottom: 0;
              font-weight: normal;
              right: 33px;
            }
          }

          .distance {
            @include apercuregular;
            font-size: 14px;
            letter-spacing: -0.53px;
            color: $formtypecolor;
          }

          &:before {
            display: none;
          }
        }

        .loadmore {
          position: absolute;
          z-index: 99;
          bottom: 53px;
          width: 307px;
          height: 45px;
          background: $secondary;
          border-radius: 20.5px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include apercubold;
          font-size: 16px;
          color: $whitetext;
          letter-spacing: 0.14px;
          cursor: pointer;

          &.fullscreen {
            margin-left: auto;
            right: 0;
            bottom: -49px;
          }
        }

        .discount_card {
          position: absolute;
          background: transparent;
          left: 50px;
          top: -3px;
          max-width: 664px;

          &.mapopened {
            max-width: 308px;
          }

          .discount_card_parent {
            height: auto;
            max-height: max-content;
            border: none;
            width: 100%;
            max-width: 100%;
            background: #fff;
            transform: none;
            top: 0;
            border-radius: 0;

            .pharmacybox {
              margin: 0;
              padding: 0;
              border: none;

              &:before {
                display: none;
              }

              .name_quantity {
                padding: 0;

                p {
                  @include apercuregular;
                  font-size: 20px;
                  color: $formborder;
                  letter-spacing: -0.75px;
                  line-height: normal;
                  font-weight: normal;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                span {
                  @include apercuregular;
                  font-size: 14px;
                  letter-spacing: -0.53px;
                  color: $formtypecolor;
                }
              }

              .price {
                @include apercuregular;
                font-size: 20px;
                color: $formborder;
                position: absolute;
                bottom: 0;
                font-weight: normal;
                right: 0;
              }
            }

            .address,
            .phonenumber {
              margin-top: 21px;
              display: flex;
              align-items: center;
              text-decoration: underline;
              cursor: pointer;
              @include apercuregular;
              font-size: 16px;
              letter-spacing: -0.5px;
              color: $formborder;

              &::before {
                content: "";
                width: 19px;
                height: 28px;
                background: url(assets/images/form_pinlocation.svg) no-repeat;
                background-size: 100%;
                margin-right: 13px;
              }
            }
          }

          .phonenumber {
            a {
              @include apercuregular;
              font-size: 16px;
              letter-spacing: -0.5px;
              color: $formborder;
            }

            &::before {
              content: "";
              width: 15px !important;
              height: 28px !important;
              background: url(assets/images/card_phone.svg) no-repeat !important;
              background-size: 100% !important;
              margin-right: 17px !important;
            }
          }

          .pharmacyDetail {
            padding: 0;
            padding-top: 46px;

            p {
              padding: 0;
              margin: 0;
              line-height: normal;
              @include apercubold;
              font-size: 16px;
              color: $formborder;
              margin-bottom: 5px;
            }

            .email_field {
              margin: 0;
              box-shadow: none;
              border: solid 1.2px #827c78;
              width: 100%;
              height: 45px;
              border-radius: 20.5px;
              margin-bottom: 32px;
              padding-left: 12.7px;

              &.error_msg {
                position: relative;
                border-color: $footerfollowcolor !important;

                &:before {
                  content: "!";
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  background: $formborder;
                  color: $whitetext;
                  @include apercubold;
                  font-size: 12px;
                  line-height: 16px;
                  text-align: center;
                  position: absolute;
                  top: 50%;
                  transform: translate(0, -50%);
                  left: 10px;
                }

                input {
                  border-color: $footerfollowcolor !important;
                  padding-left: 35px;
                }

                & + span {
                  top: 20px;
                  @include apercuregular;
                  font-size: 16px;
                  font-weight: normal;
                  color: $footerfollowcolor !important;
                  margin-top: 0;
                  display: flex;
                  align-items: center;
                  letter-spacing: -0.5px;
                  top: calc(100% + 6px);
                  padding-left: 27px;
                  position: relative;
                  top: -25px;
                  margin-left: 12px;

                  &:before {
                    content: "!";
                    min-width: 16px;
                    min-height: 16px;
                    border-radius: 50%;
                    background: $formborder;
                    color: $whitetext;
                    @include apercubold;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    margin-right: 7px;
                    border: none;
                  }
                }
              }

              input {
                letter-spacing: 0.14px;
                @include apercuregular;
                font-size: 16px;
                color: #827c78;
                display: flex;
                align-items: center;

                width: 100%;
                background: transparent;
                font-weight: normal;
              }

              input::placeholder {
                color: #827c78;
                opacity: 1;
              }
            }

            .save,
            .saved {
              width: 100%;
              text-align: center;
              line-height: normal;
              @include apercubold;
              font-size: 16px;
              color: $secondary;
              margin-bottom: 34px;
              cursor: pointer;
              margin-bottom: 34px;
            }

            .saved {
              pointer-events: none;
            }

            .sendbtn {
              height: 45px;
              border-radius: 20.5px;
              width: 100%;
              background: $primary;
              display: flex;
              align-items: center;
              justify-content: center;
              @include apercubold;
              font-size: 16px;
              color: $whitetext;
              letter-spacing: 0.14px;
              margin-bottom: 40.5px;
              cursor: pointer;
              position: relative;
            }

            .close_search_pharmacy_popup {
              border-top: 1.2px solid $formgrey;
              position: relative;
              background: transparent;
              min-width: calc(100% - 50px);
              margin-left: 50px;
              max-height: 100%;
              height: auto;
              display: flex;
              padding-top: 8.5px;
              @include apercuregular;
              font-size: 14px;
              color: $formtypecolor;
              justify-content: flex-end;

              &:after {
                content: "";
                width: 18.4px;
                height: 18.4px;
                background: url(assets/images/downarrow.svg) no-repeat;
                background-size: 100%;
                margin-left: 10.3px;
                background-size: 100%;
                margin-left: 10.3px;
                transform: rotate(180deg);
                position: relative;
                top: 6px;
              }
            }
          }
        }
      }
    }
  }
}

.loaderscreen {
  position: fixed;
  background: rgba(1,37,72,.97);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  z-index: 999999;

  &.showloader {
    display: block;

    > div {
      width: 639px;
      height: 639px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      margin: 0 auto;

      &:after {
        // content: "Searching for the best price, one moment please.";
        color: $secondary;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        letter-spacing: -0.6px;
        font-size: 20px;
        @include apercuregular;
      }

      &.loaderpill {
        max-width: 195px;
        max-height: 75px;
        text-align: center;
        color: $tertiary;

        > div {
          width: 90px;
          height: 90px;
          animation: pillloader 0.9s linear infinite;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: auto !important;
        }

        &:after {
          display: none;
        }
      }

      > div {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        margin: 0 auto;
        border-radius: 50%;
        opacity: 0.2;

        &.first {
          width: 100%;
          height: 100%;
          border: 1px dashed #003e80;
          animation: pweek 1.8s ease-out infinite;
          animation-delay: 1s;
        }

        &.second {
          width: 532.5px;
          height: 532.5px;
          border: 1px dashed #7dc723;
          animation: pweek 1.8s ease-out infinite;
          animation-delay: 0.9s;
        }

        &.third {
          width: 444px;
          height: 444px;
          border: 1px dashed #7dc723;
          animation: pweek 1.8s ease-out infinite;
          animation-delay: 0.8s;
        }

        &.fourth {
          width: 370px;
          height: 370px;
          border: 1px dashed #7dc723;
          animation: pweek 1.8s ease-out infinite;
          animation-delay: 0.7s;
        }

        &.fifth {
          width: 309px;
          height: 309px;
          border: 1px dashed #00abe7;
          animation: pweek 1.8s ease-out infinite;
          animation-delay: 0.6s;
        }

        &.sixth {
          width: 258px;
          height: 258px;
          border: 1px dashed #7dc723;
          animation: pweek 1.8s ease-out infinite;
          animation-delay: 0.5s;
          img {
            cursor: pointer;
          }
        }

        &.seventh {
          width: 214px;
          height: 214px;
          border: 1px dashed #7dc723;
          animation: pweek 1.8s ease-out infinite;
          animation-delay: 0.4s;
        }

        &.eirth {
          width: 175px;
          height: 175px;
          border: 1px dashed #003e80;
          animation: pweek 1.8s ease-out infinite;
          animation-delay: 0.3s;
        }

        &.ninth {
          width: 148px;
          height: 148px;
          border: 1px dashed #00abe7;
          animation: pweek 1.8s ease-out infinite;
          animation-delay: 0.2s;
        }
      }
    }
  }
}

@keyframes pillloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes pweek {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0.1;
  }

  10% {
    opacity: 0.2;
  }

  15% {
    opacity: 0.3;
  }

  20% {
    opacity: 0.4;
  }

  25% {
    opacity: 0.5;
  }

  30% {
    opacity: 0.6;
  }

  35% {
    opacity: 0.7;
  }

  40% {
    opacity: 0.8;
  }

  45% {
    opacity: 0.9;
  }

  50% {
    opacity: 1;
  }

  55% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.8;
  }

  65% {
    opacity: 0.7;
  }

  70% {
    opacity: 0.6;
  }

  75% {
    opacity: 0.5;
  }

  80% {
    opacity: 0.4;
  }

  85% {
    opacity: 0.3;
  }

  90% {
    opacity: 0.2;
  }

  95% {
    opacity: 0.1;
  }

  100% {
    opacity: 0;
  }
}

.pharmacylist_parent {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  flex-flow: column;

  .map_list_box {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    position: relative;

    .maps_box {
      margin-top: 0 !important;
      margin-left: 23px;
    }

    agm-map {
      height: 462px;
    }
  }

  .right_btn_sec {
    display: flex;
    justify-content: flex-end;
    max-width: 662px;
    margin-left: 50px;
    position: relative;
    top: -10px;

    .map_btn {
      display: flex;
      align-items: center;
      margin-right: 33px;

      input {
        display: none;

        & + label {
          @include apercuregular;
          font-size: 20px;
          letter-spacing: -0.44px;
          color: $pharmacypop;
          display: flex;
          align-items: center;

          i {
            width: 26px;
            height: 26px;
            background: url(assets/images/uncheck.svg);
            margin-right: 10px;
            background-size: 100%;
          }
        }

        &:checked {
          & + label {
            i {
              background: url(assets/images/check.svg);
              background-size: 100%;
            }
          }
        }
      }
    }

    .sortby {
      min-width: 139px;
      max-width: 139px;
      display: flex;
      flex-flow: column;
      margin-top: -10px;
      font-size: 14px;
      @include apercuregular;
      letter-spacing: -0.53px;
      line-height: normal;
      color: $formtypecolor;

      .sortby_ddl ul {
        display: flex;
        width: 100%;
        justify-content: space-between;

        li {
          @include apercubold;
          font-size: 16px;
          letter-spacing: 0.14px;
          cursor: pointer;
          color: $pharmacypop;

          &.active {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .firstloadimg {
    max-width: 662px;
    margin-left: 50px;
    padding-top: 20px;

    img {
      max-width: 100%;
      pointer-events: none;
    }
  }
}

.signupbox {
  background: rgba(42, 82, 75, 0.95) !important;
  z-index: 9999999 !important;

  .Signup_login {
    max-width: 654px !important;
    max-height: 580px !important;
    border-radius: 0 100px 0 0 !important;
    height: 580px !important;
    box-shadow: none !important;
    padding-right: 0 !important;

    .close_search_pharmacy_popup {
      z-index: 99;
      background-repeat: no-repeat;
      width: 21px;
      height: 21px;
      top: 0;
      right: 0;
      background-size: 100%;
    }

    .background_area {
      flex-basis: 389px !important;
      min-width: 389px !important;
      max-width: 389px !important;
      background: url(assets/images/signup_screen.webp) no-repeat !important;
    }

    .form_area {
      flex-basis: calc(100% - 150px) !important;
      max-width: calc(100% - 150px) !important;
      min-width: calc(100% - 150px) !important;
      padding-left: 0 !important;
      padding-top: 0 !important;
      position: relative !important;

      .signup_signin_parent_box {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 100%;
        max-width: 505px;
        margin: 0 auto;

        img {
          // margin-bottom: 2em;
          margin-bottom: 0;
          margin-left: -5px !important;
        }

        h2 {
          font-size: 50px !important;
          @include apercubold;
          font-weight: normal !important;
          padding: 0;
          margin: 0;
          color: $headingcolor !important;
          letter-spacing: -1.43px;
        }

        nav {
          margin-top: 10px;

          a {
            @include apercubold;
            font-size: 16px !important;
            color: $formborder !important;
            letter-spacing: 0.14px;
            margin-right: 35px;

            &.active {
              text-decoration: underline !important;
            }

            &:before {
              display: none;
            }
          }
        }

        .signup_parent,
        .signin_parent {
          margin-top: -19px;

          .Login_Signup_Error {
            @include apercuregular;
            font-size: 16px;
            font-weight: normal;
            color: $footerfollowcolor;
            margin-top: 37px;
            display: flex;
            align-items: center;
            letter-spacing: -0.5px;

            &:before {
              content: "!";
              min-width: 16px;
              min-height: 16px;
              border-radius: 50%;
              background: $formborder;
              color: $whitetext;
              @include apercubold;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
              margin-right: 7px;
            }
          }
        }

        .form_sec {
          .field {
            margin-bottom: 18px;

            &.error_msg {
              position: relative;

              &:before {
                content: "!";
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: $formborder;
                color: $whitetext;
                @include apercubold;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 10px;
              }

              input {
                border-color: $footerfollowcolor !important;
                padding-left: 35px;
              }

              & + span {
                display: none;
              }
            }
          }

          input {
            width: 100%;
            min-height: 45px;
            border-radius: 20.5px;
            box-shadow: none;
            border: 1px solid #00407e;
            @include apercubold;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0.14px;
            padding-left: 18px;
            color: #00407e;

            &::placeholder {
              @include apercuregular;
              color: #00407e;
              opacity: 1 !important;
            }
          }

          .changepassword_main {
            position: relative;
            transform: none;
            box-shadow: none;
            width: 100%;
            height: auto;
            max-height: 100%;
            max-width: 100%;
            padding: 0;

            > div {
              width: 100%;
              color: #00407e;

              label {
                display: none;
              }

              input {
                &::placeholder {
                  opacity: 1;
                }
              }

              .reset_button {
                margin-left: auto;
                display: flex;
                min-width: 194px;
                height: 45px;
                align-items: center;
                justify-content: center;
                background: #7fc534;
                border-radius: 20.5px;
                color: $whitetext;
                margin-top: 27px;
                @include apercubold;
                font-size: 16px;
                letter-spacing: 0.14px;
                cursor: pointer;
              }
              .less_top { 
                margin-top: 0;
              }
              .reset_text {
                background: none;
                color: #ff8b6c;
                height: auto;
                padding-bottom: 10px;
                justify-content: left;
                margin-top: 15px;
                @include apercuregular;
              }
            }
          }
        }

        a {
          &.forgot_btn {
            max-width: 100%;
            width: 100%;
            justify-content: flex-end;
            text-align: right;
            margin-top: -13px;
            color: $formborder;
            letter-spacing: -0.5px;
            @include apercubold;
            font-size: 16px;
            margin-bottom: 18px;
            font-weight: normal;

            span {
              text-decoration: underline;
              margin-left: 5px;
            }
          }
        }

        .signup_btn_sec {
          .already {
            text-align: left;
            align-items: flex-start;
            margin-left: 0;
            @include apercuregular;
            font-size: 16px;
            letter-spacing: -0.5px;
            color: $formborder;
            // max-width: 255px;
            font-weight: 700;

            a {
              color: $tertiary;
              text-decoration: underline !important;
            }
          }

          .btns {
            margin-left: auto;
            min-width: 194px;
            max-width: 194px;
            min-height: 45px;
            max-height: 45px;
            background: $primary;
            color: $whitetext;
            border-radius: 20.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include apercubold;
            font-size: 16px;
            letter-spacing: 0.14px;
          }
        }

        .tncbox {
          margin-top: -4px;
          @include apercuregular;
          font-size: 16px;
          letter-spacing: -0.5px;
          color: $formborder;
          margin-bottom: 50px;

          a {
            text-decoration: underline !important;
            @include apercubold;
            color: inherit;
          }
        }
      }
    }
  }
}

.pharmacyPage.cardPage {
  #pharmacy_container {
    margin-top: 97px;
    max-width: 1042px !important;
    padding: 0;

    > h2 {
      @include apercubold;
      font-size: 50px;
      color: $headingcolor;
      letter-spacing: -1.43px;
      margin-bottom: 30px;
    }

    .sorting_box {
      margin-bottom: 59px;

      p {
        @include apercuregular;
        color: $formtypecolor;
        letter-spacing: -0.53px;
        font-size: 14px;
        padding: 0;
        margin: 0 0 4px 0;
      }

      .sort_link {
        display: flex;

        > div {
          cursor: pointer;
          @include apercubold;
          font-size: 16px;
          color: $pharmacypop;
          margin-right: 20px;

          &.active {
            text-decoration: underline;
          }
        }
      }
    }

    .pharmacy_list {
      max-width: 100%;
      max-height: 100%;

      .card {
        display: flex;
        flex-flow: row wrap;
        border: none;
        padding-bottom: 20px;
        border-bottom: solid 3px $footerfollowcolor;
        cursor: default;
        margin-bottom: 54px;

        &:last-child {
          border: none;
        }

        &:after {
          display: none;
        }

        .card_name_price {
          width: 100%;
          margin-bottom: 15px;
          display: flex;

          .drugname {
            @include apercubold;
            font-size: 30px;
            color: $headingcolor;
            letter-spacing: -0.86px;
          }

          .drugprice {
            @include apercubold;
            font-size: 30px;
            color: $secondary;
            margin-left: auto;
          }
        }
        .cardbox_p {
          padding-left: 5px;
        }
        .card_box {
          width: 407px;
          margin-right: 36px;
          &:nth-child(2) {
            margin-top: 10px;
            > div {
              padding: 15px !important;
              table {
                width: 100%;
                td {
                  color: #19342f;
                  font-family: "Fira Sans", sans-serif !important;
                  font-weight: 400;
                  font-size: 9px;
                  vertical-align: top;
                  p {
                    color: #19342f;
                    font-family: "Fira Sans", sans-serif !important;
                    font-weight: 400;
                    font-size: 10px;
                  }
                }
              }
            }
          }

          .card_box_header {
            height: 42px;
            background: $formborder;
            border-radius: 20.5px 20.5px 0 0;
            @include apercuregular;
            font-size: 13.2px;
            line-height: 42px;
            color: $whitetext;
            display: flex;
            align-items: center;
            padding-left: 18.7px;
            letter-spacing: -0.49px;

            &:before {
              background: url(assets/images/discount_icon.svg) no-repeat;
              content: "";
              width: 19.8px;
              height: 19.8px;
              margin-right: 5.5px;
              background-size: 100%;
            }

            img {
              margin-left: auto;
              margin-right: 20.7px;
              max-height: 20px;
            }
          }

          .card_box_body {
            padding-top: 19.5px;
            padding-left: 44.6px;
            padding-right: 44.6px;

            .logo_phone {
              display: flex;
              align-items: flex-end;
              margin-bottom: 15px;

              img {
                max-width: 90px;
                margin-left: -2px;
                margin-bottom: -7px;
              }

              .member_support {
                margin-left: auto;
                display: flex;
                flex-flow: column;
                @include apercuregular;
                font-size: 13.2px;
                color: $secondary;
              }
            }

            .bin_pcn_member_id {
              display: flex;
              flex-flow: row wrap;

              > div {
                width: 50%;

                span {
                  font-size: 13.2px;
                  @include apercuregular;
                  color: #b6aca2;
                  letter-spacing: -0.49px;
                }

                > div {
                  padding-left: 5.6px;
                  letter-spacing: 0.26px;
                  color: $formborder;
                  height: 25px;
                  align-items: center;
                  border-left: 0.82px solid rgba(182, 172, 162, 0.5);
                  display: flex;
                  position: relative;
                  left: 2px;
                  margin-bottom: 6px;
                }
              }
            }

            p {
              letter-spacing: -0.37px;
              font-size: 9.9px;
              margin-top: 13px;
              color: #827c78;
              @include apercubold;
            }
          }
        }

        .card_detail {
          width: 593px;
          display: flex;
          flex-flow: row wrap;
          height: auto;
          align-self: baseline;
          justify-content: space-between;

          .type_form {
            width: 209px;
            margin-right: 17px;
            display: flex;
            flex-flow: column;
            max-height: 189px;

            > div {
              line-height: 30px;
              @include apercuregular;
              font-size: 18px;
              letter-spacing: -0.51px;

              span {
                color: #b6aca2;
                margin-right: 5px;

                &:last-child {
                  color: $primary;
                }
              }
            }
          }

          .address_number_box {
            width: calc(100% - 226px);
            padding-left: 32px;
            border-left: 0.82px solid rgba(182, 172, 162, 0.5);

            .pharmacy_name {
              font-size: 20px;
              letter-spacing: -0.75px;
              padding: 0;
              margin: 0;
              color: $formborder;
            }

            .distance {
              letter-spacing: -0.51px;
              @include apercuregular;
              font-size: 18px;
              color: #b6aca2;
            }

            .address {
              display: flex;
              color: $formborder;
              letter-spacing: -0.56px;
              margin-top: 15px;
              margin-bottom: 25px;
              align-items: center;

              &:before {
                content: "";
                width: 19px;
                height: 28px;
                background: url(assets/images/form_pinlocation.svg) no-repeat;
                background-size: 100%;
                margin-right: 13px;
              }

              span {
                text-decoration: underline;
                cursor: pointer;
              }
            }

            .phonenumber {
              padding: 0;
              margin: 0;
              display: flex;
              color: $formborder;
              letter-spacing: -0.56px;
              align-items: center;

              &:before {
                content: "";
                width: 15px;
                height: 28px;
                background: url(assets/images/card_phone.svg) no-repeat;
                background-size: 100%;
                margin-right: 17px;
              }

              a {
                text-decoration: underline !important;
                cursor: pointer;
              }
            }
          }

          .share_card {
            width: 100%;
            margin-top: 44px;
            max-width: 45%;

            > div.sharecard {
              width: 100%;
              height: 45px;
              border-radius: 20.5px;
              background: $secondary;
              display: flex;
              cursor: pointer;
              align-items: center;
              justify-content: center;
              @include apercubold;
              font-size: 16px;
              color: $whitetext;
              letter-spacing: 0.14px;
            }
          }
        }

        .remove_card {
          margin-top: auto;
          cursor: pointer;
          @include apercubold;
          font-size: 15px;
          color: $footerfollowcolor;
          letter-spacing: normal;
          text-decoration: underline;
          margin-top: 15px;
        }
      }
    }
  }
}

.contact_info {
  max-width: 1039px;
  margin: 50px auto 0 auto;
  padding: 25px 0;
  border-top: 2px solid $formborder;
  border-bottom: 2px solid $formborder;
  display: flex;

  > div {
    margin-right: 90px;
    max-width: 150px;

    p {
      padding: 0;
      margin: 0;
      @include apercuregular;
      font-size: 16px;
      color: $formborder;

      &:first-child {
        @include apercubold;
      }
    }
  }

  .needa {
    max-width: 250px;
    margin-right: 0;
    p:first-child() {
      font-size: 18px !important;
    }
    p:last-child() {
      font-size: 20px !important;
    }
  }
}

.mobileheader_download,
.mobile_mobile_image {
  display: none;
}

.onmobile {
  display: none;
}

.onweb {
  display: flex;
}

.welcome-section.download-edit {
  min-height: 710px;
  background-image: rgba(0, 64, 126, 1) no-repeat;
  background-image: -moz-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
  background-image: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 64, 126, 1)),
    color-stop(100%, rgba(1, 43, 83, 1))
  );
  background-image: -webkit-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
  background-image: -ms-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
  background-image: linear-gradient(to right, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#19342f', endColorstr='#2a524b', GradientType=1);
}

.welcome-section.download-edit h2 {
  margin-top: 205px;
}

.welcome-section.download-edit:before {
  background: url(assets/images/hero_iphone.png) no-repeat;
  background-size: contain !important;
  background-position: center top;
  z-index: 999;
}

.welcome-section.download-edit:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  height: 60px;
  left: 0;
  right: 0;
  background: #fff;
}

section.contact-section.green-theme.aboutbox_header {
  position: relative;
  padding-bottom: 90px;

  app-getcard {
    .getCard.green-theme {
      position: absolute;
      right: 70px;
      transition: all 0.1s;
      bottom: -50px;

      &.fixed_pos_card {
        position: fixed;
        right: 24px;
        transition: all 0.1s;
        bottom: 24px;
      }
    }
  }
}

section.welcome-section.download-edit.aboutbox_header {
  position: relative;

  app-getcard {
    .getCard.green-theme {
      position: absolute;
      right: 70px;
      transition: all 0.1s;
      bottom: -50px;

      &.fixed_pos_card {
        position: fixed;
        right: 24px;
        transition: all 0.1s;
        bottom: 24px;
      }
    }
  }
}

#myModal.green-theme-modal h2 {
  padding-top: 0;
  margin-bottom: 30px;
  margin-top: 0 !important;
  margin-left: 0;
}

#myModal.green-theme-modal .getCardHeading img {
  width: auto;
}

.quantity_box_manual {
  background: rgba(25, 52, 47, 0.95) !important;

  .changepassword_main {
    width: 100% !important;
    height: 250px !important;
    max-width: 300px !important;
    max-height: 250px !important;
    background: #fff !important;
    border-radius: 0 100px 0 0 !important;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2) !important;
    padding: 32px !important;
    padding-top: 30px !important;

    h2 {
      @include apercubold;
      margin: 0;
      text-align: left;
      font-size: 20px;
      color: $pharmacypop;
      line-height: normal;
    }

    .input_box {
      display: flex !important;
      flex-flow: column;

      label {
        @include apercuregular;
        font-size: 14px !important;
        letter-spacing: -0.53px !important;
        color: $formtypecolor !important;
        margin-bottom: 0 !important;
        position: relative !important;
        top: auto !important;
        bottom: auto !important;
        padding: 0 !important;
        left: auto !important;
        margin: 0 !important;
        font-weight: normal !important;
      }

      > div {
        margin-bottom: 0 !important;

        input {
          border: none !important;
          outline: none !important;
          width: 100% !important;
          background: transparent !important;
          font-size: 20px !important;
          @include apercuregular;
          color: $formborder !important;
          line-height: 25px !important;
          font-weight: normal !important;
          border-bottom: solid 1px $formgrey !important;
          padding: 0 !important;
          box-shadow: none !important;
          margin-bottom: 18px !important;
          border-radius: 0 !important;

          &::placeholder {
            color: $formborder !important;
            opacity: 1 !important;
          }
        }
      }

      .reset_button {
        width: 100% !important;
        height: 45px !important;
        max-width: 100% !important;
        max-height: 45px !important;
        @include apercubold;
        font-size: 16px !important;
        color: #fff !important;
        border-radius: 20.5px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $secondary !important;
      }
    }
  }
}

app-pharmacy {
  .pharmacyPage {
    .pharmacy_box {
      .map_list_box {
        .card {
          display: none;
        }
      }
    }
  }
}

.search_pharmacy_popup .search_pharmacy_select .filter_box .lists_box > div:first-child > div input {
  transition: all 0.2s;
}

.lists_box.PharmacyNear.error_msg {
  > div {
    border-color: $footerfollowcolor !important;

    label {
      color: $formtypecolor !important;
    }

    .filtertext {
      position: relative;

      &:before {
        content: "!";
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $formborder;
        color: $whitetext;
        @include apercubold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        left: 0;
      }

      input {
        padding-left: 25px;
        transition: all 0.2s;
      }
    }

    span {
      top: 20px;
      @include apercuregular;
      font-size: 16px;
      font-weight: normal;
      color: $footerfollowcolor;
      margin-top: 0;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      top: calc(100% + 6px);
      padding-left: 27px;
      position: absolute;

      &:before {
        content: "!";
        min-width: 16px;
        min-height: 16px;
        border-radius: 50%;
        background: $formborder;
        color: $whitetext;
        @include apercubold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin-right: 7px;
        border: none;
      }
    }

    &:after {
      display: none;
    }
  }
}

.PharmacyNear {
  & + span {
    display: none;
  }
}

.PharmacyNear.error_msg {
  > div {
    border-color: $footerfollowcolor !important;
    position: relative;

    label {
      color: $formtypecolor !important;
    }

    &::after {
      content: "!";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $formborder;
      color: $whitetext;
      @include apercubold;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      left: 0;
    }

    input {
      padding-left: 25px;
      transition: all 0.2s;
    }
  }

  & + span {
    top: 20px;
    @include apercuregular;
    font-size: 16px;
    font-weight: normal;
    color: $footerfollowcolor !important;
    margin-top: 0;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    top: -10px;
    padding-left: 27px;
    position: relative;

    &:before {
      content: "!";
      min-width: 16px;
      min-height: 16px;
      border-radius: 50%;
      background: $formborder;
      color: $whitetext;
      @include apercubold;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      margin-right: 7px;
      border: none;
    }
  }
}

#contactForm {
  .error_msg {
    position: relative;

    input {
      padding-left: 30px;
      transition: all 0.2s;
      border-color: $footerfollowcolor !important;
    }

    &:before {
      content: "!";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $formborder;
      color: $whitetext;
      @include apercubold;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      position: absolute;
      top: 25px;
      left: 10px;
    }

    > div {
      top: 20px;
      @include apercuregular;
      font-size: 16px;
      font-weight: normal;
      color: $footerfollowcolor;
      margin-top: 0;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      top: calc(100% + 6px);
      padding-left: 13px;
      position: relative;
      margin-top: 5px;

      &:before {
        content: "!";
        min-width: 16px;
        min-height: 16px;
        border-radius: 50%;
        background: $formborder;
        color: $whitetext;
        @include apercubold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin-right: 7px;
        border: none;
      }
    }
  }

  .btnValidator {
    @include apercuregular;
    font-size: 16px;
    font-weight: normal;
    color: $footerfollowcolor;
    margin-top: 0;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    top: calc(100% + 6px);
    padding-left: 0;
    position: relative;
    margin-top: 5px;

    &:before {
      content: "!";
      min-width: 16px;
      min-height: 16px;
      border-radius: 50%;
      background: $formborder;
      color: $whitetext;
      @include apercubold;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      margin-right: 7px;
      border: none;
    }
  }
}

section.banner-section.about-banner-section.aboutSecOne.green-theme.aboutbox_header {
  position: relative;

  .over {
    max-width: 988px;
    position: relative;
  }

  app-getcard {
    .getCard.green-theme {
      position: absolute;
      right: 70px;
      transition: all 0.1s;
      bottom: -50px;

      &.fixed_pos_card {
        position: fixed;
        right: 24px;
        transition: all 0.1s;
        bottom: 24px;
      }
    }
  }
}

app-getcard {
  .error_msg {
    position: relative;

    input {
      padding-left: 30px !important;
      transition: all 0.2s;
      border-color: $secondary !important;
    }

    &:before {
      content: "!";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $secondary;
      color: $whitetext;
      @include apercubold;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      position: absolute;
      top: 14px;
      left: 10px;
    }

    span {
      top: 20px;
      @include apercuregular;
      font-size: 16px;
      font-weight: normal;
      color: $secondary;
      margin-top: 0;
      display: flex !important;
      align-items: center;
      letter-spacing: -0.5px;
      top: calc(100% + 6px);
      padding-left: 13px;
      position: relative;
      margin-top: 5px;

      &:before {
        content: "!";
        min-width: 16px;
        min-height: 16px;
        border-radius: 50%;
        background: $secondary;
        color: $whitetext;
        @include apercubold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin-right: 7px;
        border: none;
      }
    }
  }
}

.successhome_msg {
  width: 100%;
  display: flex;
  align-items: center;
  color: #19342f;
  margin-top: 25px;
  font-size: 16px;
  @include apercubold;

  img {
    margin-right: 10px;
    max-width: 30px;
  }
}

.app-link {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

#myModal.green-theme-modal .modal-dialog > section {
  position: relative;
}

.pharmacyPage .container .pharmacy_box .pharmacy_list .discount_card .pharmacyDetail .sendbtn.success {
  background: $formborder !important;
  pointer-events: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

p.captchalink {
  padding-left: 20px;
  @include apercuregular;
  font-size: 12px;
  color: $formborder;
}

.change_password .changepassword_main {
  &.custom_quantity {
    position: relative;
    overflow: visible;

    .close_search_pharmacy_popup {
      width: 26px;
      height: 25px;
      max-width: 25px;
      max-height: 25px;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;
      background-size: 100%;
    }
  }
}

.counter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include apercuregular;
  font-size: 12px;
  color: $formborder;
}

@media screen and (min-width: 0) and (max-width: 1024px) {
  .mobileheader_download {
    display: flex;
    width: 100%;
    height: 35px;
    background: #00b00e;
    /* Old browsers */
    background: -moz-linear-gradient(top, #00b00e 0%, #275049 100%, #275049 100%, #275049 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #00b00e 0%, #275049 100%, #275049 100%, #275049 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #00b00e 0%, #275049 100%, #275049 100%, #275049 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00b00e', endColorstr='#275049', GradientType=0);
    /* IE6-9 */
    align-items: center;
    justify-content: center;
    color: $whitetext;
    letter-spacing: -0.21px;
    @include apercuregular;
    font-size: 11px;

    img {
      &:first-child {
        margin-right: 7.5px;
      }
    }
  }

  header {
    &.header-section {
      position: relative;
      min-height: 84px;
      max-height: 84px;

      .menu_bar_btn {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: auto;
        right: 36px;
        display: flex;
        flex-flow: column;

        span {
          @include apercubold;
          font-size: 10px;
          color: $secondary;
          margin-top: 3px;
          letter-spacing: -0.5px;
        }
      }

      .logo {
        min-width: 93px;
        max-width: 93px;
        padding: 0;
        margin: 0 0 0 50px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 30px;
        margin: 0;
      }
    }
  }

  .welcome-section {
    background-image: rgba(0, 64, 126, 1) no-repeat;
    background-image: -moz-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
    background-image: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(0, 64, 126, 1)),
      color-stop(100%, rgba(1, 43, 83, 1))
    );
    background-image: -webkit-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
    background-image: -ms-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
    background-image: linear-gradient(to right, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);

    &:before {
      display: none;
    }

    .comman-width {
      width: 100%;
      padding: 0;

      .welcome-center {
        max-width: 100%;
        margin-left: 0;

        h2 {
          font-size: 30px;
          padding: 34px 0 14px 0 !important;
          line-height: 33px;
          letter-spacing: -0.86px;
          font-weight: normal;
          @include apercubold;
          padding-left: 32px !important;
          padding-right: 41px !important;
        }

        .mobile_mobile_image {
          display: flex;
          background: url(assets/images/bg_glow.svg) no-repeat;
          justify-content: center;
          margin-bottom: 31px;
          background-position: center 0;

          img {
            max-width: 100%;
          }
        }

        .formsec {
          margin-top: -50px;
          display: flex;
          flex-flow: column;
          padding-left: 30px;
          padding-right: 30px;

          input {
            width: 100%;
            font-size: 16px;
            letter-spacing: 0.14px;
            margin-bottom: 19px;
          }

          .btn_desc {
            flex-flow: column;

            a {
              width: 100%;
            }

            p {
              width: 100%;
              margin-top: 23px;
              letter-spacing: -0.02px;
              font-size: 13.6px;
              align-items: center;
              justify-content: center;
              display: flex;
              max-width: 209px;
              margin-left: auto;
              margin-right: auto;
              text-align: center;
              line-height: 17px;
              font-weight: normal;
            }
          }
        }

        .search {
          display: flex;
          flex-flow: column;
          margin-top: 30px;
          text-align: center;
          padding-bottom: 0;

          ul {
            display: flex;
            flex-flow: column;
            margin-top: 30px;
            text-align: center;
            max-width: 257px;
            min-width: 257px;
            margin-left: auto;
            margin-right: auto;

            li {
              text-align: center;
              min-width: 100%;
              max-width: 100%;
              margin-bottom: 31px;
              margin-top: 31px;

              img {
                width: auto;
              }

              span {
                @include apercubold;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: -0.57px;
              }
            }
          }
        }
      }
    }
  }

  .search_medicine {
    .comman-width {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 40px;
      padding-bottom: 29px;

      app-search {
        .search-fields {
          margin-bottom: 0;

          .form-section {
            border: none;
            background: transparent;
            border-radius: 0;
            box-shadow: none;
            display: flex;
            flex-flow: column;

            input[type="text"] {
              border: 2px solid $formborder;
              height: 45px;
              border-radius: 45px;
              background: transparent;

              &::placeholder {
                font-size: 12px;
              }
            }

            .lowest {
              min-width: 100%;
              max-width: 100%;
              position: relative;
              transform: none;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }

  .accept-section {
    .acceptedsec {
      padding-top: 55px;
      flex-flow: column;
      padding-bottom: 0;

      .onmobile {
        display: flex;
      }

      > h3,
      > h2,
      > p {
        padding: 0;
        margin: 0;
        @include apercubold;
        font-weight: normal;
      }

      > h3 {
        font-size: 20px;
        color: $primary;
        letter-spacing: -0.57px;
        margin-bottom: 7px;
      }

      > h2 {
        font-size: 50px;
        letter-spacing: -4.43px;
        color: $headingcolor;
      }

      > p {
        font-size: 20px;
        color: $primary;
        letter-spacing: -0.57px;
        margin-bottom: 26px;
      }

      .onweb {
        display: none;
      }

      > img {
        margin-bottom: 56px;
        max-width: 100%;
        padding: 3em;
      }

      .howitwork {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  .testimonial {
    padding-top: 79px;

    .comman-width {
      .testimonial_inner {
        top: 0;

        h2,
        h3 {
          padding: 0 30px !important;
        }

        .row {
          top: 45px;
          position: relative;
        }
      }

      .row .col-lg-12 .nav-btn.prev-slide.slick-arrow {
        top: auto !important;
        bottom: -95px;
        width: 34px;
        height: 34px;
        background-size: 100% !important;
        left: 15px !important;
        z-index: 99999;
      }

      .row .col-lg-12 .nav-btn.next-slide.slick-arrow {
        right: 15px !important;
        top: auto !important;
        bottom: -95px;
        width: 34px;
        height: 34px;
        background-size: 100% !important;
        z-index: 99999;
      }
    }

    .slick-initialized .slick-slide {
      width: 250px;
      margin-right: 0;
      padding: 0;
      margin-left: 5px;
      margin-right: 5px;

      .shadow {
        padding: 32px 25px 0 12px;

        h3 {
          padding: 0 !important;
          left: 12px;
        }
      }
    }

    .slick-dots {
      bottom: -70px !important;
      z-index: 999;

      li {
        cursor: pointer;
        margin-right: 10px;

        button {
          &:before {
            color: #b6aca2 !important;
            font-size: 14px !important;
          }
        }

        &.slick-active {
          button {
            &:before {
              color: $footerfollowcolor !important;
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  .footer-section {
    // padding-top: 179px !important;
    // padding-bottom: 90px !important;

    .comman-width {
      width: 100%;
      padding: 0 30px;

      .footerlogo {
        display: none;
      }

      .footer-adjust {
        .fourth {
          order: 1;
          border-top: 2px solid $secondary;
          padding-top: 33.5px;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          min-width: 260px;
          max-width: 260px;
          margin-left: auto;
          margin-right: auto;

          .socialbtns {
            width: 100%;
            display: flex;
            flex-flow: column;

            a {
              width: 100%;
              justify-content: center;
              align-items: center;
              padding: 0;
            }
          }
        }

        .third {
          margin-top: 24px;
          order: 2;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 33.5px;
          border-bottom: 2px solid $secondary;
          min-width: 260px;
          max-width: 260px;
          margin-left: auto;
          margin-right: auto;
          text-align: center;

          .social {
            display: flex;
            flex-flow: column;
            padding-left: 60px;
          }
        }

        .first {
          order: 3;
          padding-top: 30px;
          min-width: 260px;
          max-width: 260px;
          margin-left: auto;
          margin-right: auto;

          &:before {
            content: "";
            width: 160px;
            height: 60px;
            background: url(assets/images/footerlogo.png) no-repeat;
            display: flex;
            // margin-bottom: 5px;
            background-size: 100%;
            margin-left: auto;
            margin-right: auto;
          }

          ul {
            li {
              text-align: center;
              margin-bottom: 12px !important;
              padding-bottom: 8px !important;
            }
          }
        }

        .second {
          order: 4;
          min-width: 260px;
          max-width: 260px;
          margin-left: auto;
          margin-right: auto;

          ul {
            li {
              text-align: center;
              margin-bottom: 12px !important;
            }
          }
        }
      }
    }

    .copyright {
      margin-top: 40px;
      max-width: 260px;
      padding-top: 20px !important;

      .comman-width {
        padding-left: 0 !important;
        padding-right: 0;
      }

      ul {
        li {
          margin-bottom: 0 !important;
          padding-left: 38px !important;
        }
      }
    }
  }

  .getCard.green-theme {
    width: 80% !important;
    height: 40px !important;
    background-size: 20px !important;
    bottom: 10px !important;
    position: fixed !important;
    right: 10px !important;

    span {
      line-height: 38px !important;
      font-size: 13px !important;
      background-size: 20px !important;
      padding-left: 34px !important;
      background-position: 10px center !important;
    }
  }

  #myModal.green-theme-modal .modal-dialog {
    right: 0 !important;
  }

  app-getcard #myModal.green-theme-modal .btn.btn-default.close {
    top: -15px !important;
    right: 15px !important;
  }

  #myModal.green-theme-modal .form-control {
    width: 100% !important;
    max-width: 100% !important;
  }

  app-getcard #myModal.green-theme-modal h2 {
    margin-bottom: 20px !important;
  }

  app-pharmacy {
    .firstloadpricing {
      padding-left: 30px;
      padding-right: 30px;

      .price_searcharea {
        flex-flow: column;

        > div {
          width: 100%;
        }

        .zipbox {
          min-width: 100%;
          max-width: 100%;
          margin-right: 0;
          display: flex;
          align-items: center;

          input[type="tel"] {
            padding-left: 10px;
            max-height: 45px;
            min-height: 45px;
            max-width: 95px;
          }

          input[type="checkbox"] + label {
            margin: 0;
            margin-left: 13px;
            font-size: 16px;
            letter-spacing: -0.84px;
          }
        }

        .medicinenamesearch {
          min-width: 100%;
          max-width: 100%;

          app-search {
            .search-fields {
              margin-top: 20px;

              .form-section {
                box-shadow: none;
                border: none;

                .form-control {
                  box-shadow: none;
                  border: 2px solid $formborder;
                  border-radius: 33.5px;

                  &::placeholder {
                    font-size: 12px;
                  }
                }

                .lowest {
                  position: relative;
                  transform: none;
                  min-width: 100%;
                }
              }
            }

            .popular_sesrch {
              ul {
                flex-flow: row nowrap;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
              }
            }
          }
        }
      }
    }

    .pharmacyPage {
      .container {
        .pharmacy_box {
          .pharmacy_list {
            overflow-y: auto;

            &:hover::-webkit-scrollbar {
              display: none;
            }

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .select_filterList_parent {
            display: flex;
            padding-left: 30px;
            padding-right: 30px;
            flex-basis: 100%;
            min-width: 100%;
            max-width: 100%;
          }

          .pharmacylist_parent {
            .right_btn_sec {
              margin-left: 0;
              padding-left: 30px;
              padding-right: 30px;
              top: 0;
              margin-top: 40px;

              .map_btn {
                margin-right: auto;

                input + label {
                  font-size: 0;

                  &::after {
                    content: "Map View";
                    font-size: 16px;
                    font-family: inherit;
                  }
                }
              }
            }

            .map_list_box {
              margin-top: 50px;
              padding-left: 30px;
              padding-right: 30px;
              flex-flow: column;

              .maps_box {
                margin-bottom: 30px;
              }

              .pharmacy_list {
                max-width: 100% !important;

                .loadmore.fullscreen {
                  right: 30px;
                  left: 30px;
                  width: auto;
                }

                .loadmore {
                  bottom: -40px;
                }

                .showdiscountcard_view {
                  position: relative;

                  .discount_card {
                    left: 0em;
                    max-width: 100%;

                    .discount_card_parent {
                      position: absolute !important;

                      .address {
                        margin-top: 10px;
                      }

                      .pharmacyDetail {
                        padding-top: 15px;
                      }

                      .email_field {
                        margin-bottom: 15px;
                      }

                      .save {
                        margin-bottom: 10px;
                      }

                      .sendbtn {
                        margin-bottom: 10px;
                      }

                      .close_search_pharmacy_popup {
                        min-width: 100%;
                        margin-left: 0;
                      }
                    }
                  }
                }

                .card {
                  margin-left: 0;
                  margin-right: 0;
                  max-width: 100%;
                }
              }

              .firstloadimg {
                margin-left: 0;
                padding-top: 0;
                margin-top: -30px;
              }
            }
          }
        }
      }
    }
    .pharmacyPage .container .pharmacy_box .pharmacy_list span.saving-shown {
      bottom: -70px;
      width: 100%;
    }
  }

  app-about-us {
    .about-banner-section {
      .comman-width {
        width: 100%;
        padding: 0 30px;

        .over {
          margin-left: 0;

          p {
            padding-right: 0;
            line-height: 40px;
            letter-spacing: -2.8px;
            font-size: 39px;
            margin-top: 20px;
          }
        }
      }
    }

    .honest-section {
      padding-top: 50px !important;
      display: flex;
      flex-flow: column;

      .image {
        order: 2;
      }

      .comman-width {
        width: 100%;
        padding: 0 30px;

        .content {
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }

  app-download {
    .welcome-section {
      .comman-width {
        .welcome-center {
          h2 {
            padding-left: 30px !important;
            padding-right: 30px !important;
          }

          span {
            padding-left: 30px;
            padding-right: 30px;
          }

          .app-link {
            padding-left: 30px;
            padding-right: 30px;
            justify-content: flex-start;
          }

          form {
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;

            .form-control {
              width: 100%;
              height: 45px;
              padding-left: 15px;
              line-height: normal;
            }

            .btn {
              position: relative;
              left: 0;
              $right: 0;
              transform: none;
              width: 100%;
            }
          }
        }
      }
    }

    .how-works {
      .comman-width {
        width: 100%;

        .welcome-center {
          .search {
            h2 {
              padding-top: 0 !important;
              margin-bottom: 0;
              padding-bottom: 0 !important;
            }

            ul {
              li {
                span {
                  text-align: center;
                }

                p {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }

  app-contact-us,
  app-agentsupport {
    .comman-width {
      width: 100%;
      padding: 0;

      .content {
        width: 100% !important;

        h2 {
          margin-left: 0;
          padding-left: 0;
        }

        form {
          display: flex;
          flex-flow: column;
          max-width: 100%;
          padding: 0 30px;

          > div {
            width: 100% !important;

            &.form-control {
              height: 45px;
            }
          }
        }
      }
    }

    section {
      &.accordian-section.green-theme {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;

        .panel-title span[aria-expanded="true"] span {
          right: 12px !important;
        }
      }
    }

    .contact_info {
      max-width: calc(100% - 60px);
      flex-flow: column;

      > div {
        margin-bottom: 20px;

        &.needa,
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .welcome-center .formsec > .form-group {
    width: 100%;
  }

  .signupbox {
    .Signup_login {
      .close_search_pharmacy_popup {
        right: 6px !important;
      }

      .background_area {
        display: none !important;
      }

      .form_area {
        flex-basis: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
        min-height: 100% !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 30px !important;
        border-radius: 0 0 100px 0 !important;

        .signup_signin_parent_box {
          position: relative;
          top: 50%;
          transform: translateY(-50%);

          > h2 {
            font-size: 35px !important;
          }

          .signup_parent {
            .form_sec {
              .changepassword_main {
                top: 0;

                > h2 {
                  font-size: 35px !important;
                }

                div {
                  .reset_button {
                    min-width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }

      .signup_btn_sec {
        flex-flow: column !important;

        .already {
          min-width: 100% !important;
        }

        .btns {
          min-width: 100% !important;
          margin-top: 20px !important;
        }
      }

      .tncbox {
        margin-bottom: 20px !important;
      }

      .skip_btn {
        bottom: -30px !important;
      }
    }
  }

  .mobMenu {
    padding-right: 0 !important;
    top: 35px !important;

    nav {
      > div {
        &.m-header-deny {
          background-image: linear-gradient(-180deg, #ffffff 0, #f7f7f7 100%);
          align-items: center;
          padding-left: 30px;
          padding-right: 30px;
          flex-flow: row;
          justify-content: space-between;
          align-items: center;
          min-height: 84px;

          img {
            max-width: 155px;
            margin-left: -33px;
          }

          .m-close {
            position: absolute;
            height: 40px;
            background-position: center;
            top: 50%;
            transform: translate(0, -29px);
            right: 30px;
            background-size: 140%;
            width: 40px;

            &:before {
              content: "CLOSE";
              position: absolute;
              bottom: -14px;
              @include apercubold;
              font-size: 12px;
              color: $secondary;
              margin-top: 3px;
              letter-spacing: -0.5px;
              left: 2px;
            }
          }
        }
      }

      ul {
        display: flex;
        flex-flow: column;
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid $otherbordercolor;
          margin-bottom: 25px;

          a {
            padding: 0 !important;
            @include apercubold;
            font-size: 22px;
            font-weight: normal;
            color: $tertiary !important;
            margin: 0;
            margin-bottom: 15px;
            display: flex !important;
            align-items: center;

            &:before {
              display: none;
            }

            &.downapp-i {
              &:before {
                display: flex;
                content: "";
                height: 27px;
                width: 15px;
                background: url(assets/images/download_header.svg) no-repeat;
                background-size: 100%;
                margin-right: 10px;
                position: relative;
                left: auto;
                top: auto;
              }
            }
          }
        }

        &.appear_menu {
          margin-top: 40px;

          li {
            &:last-child {
              border: none;
            }
          }
        }
      }

      .login-link {
        display: flex;
        flex-flow: column;
        align-items: center;

        a {
          padding-left: 20px;
          font-size: 16px;
          color: #44b672;
          white-space: nowrap;
          display: flex;
          justify-content: center;
          align-items: center;
          @include apercubold;
          order: 2;

          &:last-child {
            order: 2;
            font-size: 16px;
            color: #fff;
            background: $secondary;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 9px 12px;
            white-space: nowrap;
            @include apercubold;
            border-radius: 20px;
            min-width: 120px;
            margin-bottom: 30px;
            order: 1;
          }
        }
      }

      .menu_main {
        top: 0;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        p {
          padding: 0;
          margin: 0;
          @include apercuregular;
          font-size: 16px;
          color: $secondary;
        }

        ul {
          li {
            padding: 0 !important;
            margin: 0 !important;
            font-size: 22px;
            font-weight: normal;
            color: $tertiary !important;
            @include apercuregular;
            margin-bottom: 15px !important;
            padding-bottom: 15px !important;

            i {
              display: none;
            }

            &:last-child {
              border: none;
            }
          }
        }
      }
    }
  }

  .loaderscreen.showloader > div {
    margin-left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .header-section {
    .comman-width {
      max-width: 1180px;
    }
  }

  .testimonial .comman-width .testimonial_inner {
    max-width: 1160px;
  }

  .testimonial .comman-width .testimonial_inner .row {
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .testimonial .slick-initialized .slick-slide {
    width: 365px;
    padding: 15px;
    margin-right: 20px;
  }

  .testimonial .slick-initialized .slick-slide .shadow {
    margin-left: 0;
    margin-right: 0;
  }

  .carosuel-section .nav-btn.next-slide.slick-arrow {
    right: -60px !important;
  }

  .carosuel-section .nav-btn.prev-slide.slick-arrow {
    left: -60px !important;
  }

  .footer-adjust .first {
    order: 1;
  }

  .footer-adjust .second {
    order: 2;
  }

  .footer-section .comman-width .footer-adjust .third {
    order: 3;
  }

  .footer-section .comman-width .footer-adjust .fourth {
    order: 4;
  }

  .getCardSectionOne .getContainer h2 {
    margin-bottom: 35px !important;
  }

  .getCard.green-theme {
    height: 75px !important;
  }

  .getCard.green-theme span {
    line-height: 73px !important;
    background-position: 15px center !important;
  }

  .app-link {
    justify-content: flex-start !important;

    a {
      margin-right: 20px;

      img {
        max-width: 179px;
      }
    }
  }

  .welcome-section h2 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  header .header .headermenu .menu_main {
    top: 64px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .footer-section .copyright {
    max-width: 360px;
  }

  .honest-section.green-theme .image {
    max-width: 100% !important;
  }

  .pharmacyPage .pharmacy_box {
    flex-flow: column !important;
  }

  .pharmacylist_parent {
    min-width: 100% !important;
  }

  app-pharmacy .pharmacyPage .container .pharmacy_box .pharmacylist_parent .right_btn_sec {
    min-width: 100% !important;
  }

  app-pharmacy .pharmacyPage .container .pharmacy_box .pharmacylist_parent .map_list_box .firstloadimg {
    max-width: 100% !important;

    img {
      width: 100%;
    }
  }

  app-pharmacy .pharmacyPage .container .pharmacy_box .pharmacy_list {
    order: 2;
  }

  app-pharmacy .pharmacyPage .container .pharmacy_box .pharmacylist_parent .map_list_box .maps_box {
    order: 1;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .header-section {
    min-height: 150px !important;
    padding: 0 15px !important;
  }

  .header-section .comman-width {
    max-width: 100% !important;
  }

  .logo {
    min-width: 160px !important;
    max-width: 160px !important;
  }

  .navbar li {
    padding: 0 10px !important;
  }

  .download {
    padding-left: 20px !important;
  }

  body {
    overflow-x: hidden;
  }

  section.contact-section.green-theme.aboutbox_header {
    .comman-width {
      max-width: calc(100% - 30px);

      content.content {
        width: 100%;
      }
    }
  }

  .accordian-section.green-theme {
    max-width: 1000px;

    app-accordian {
      #accordion {
        margin-left: 0 !important;
        margin-right: 0 !important;
        .panel {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }

  .contact_info {
    max-width: 1000px;
  }

  .pharmacyPage.cardPage #pharmacy_container {
    max-width: 1000px;
  }

  .pharmacyPage.cardPage #pharmacy_container .pharmacy_list .card .card_detail {
    max-width: 512px !important;
  }

  .pharmacyPage .container .pharmacy_box .pharmacy_list .discount_card {
    max-width: 664px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .welcome-section {
    background-image: linear-gradient(to right, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%) !important;

    &:before {
      content: "";
      position: absolute;
      width: 50%;
      right: 0;
      top: 0;
      bottom: 0;
      background-size: 100%;
      background-position: right top;
      bottom: 0;
      background-image: url(assets/images/hero_iphone.png);
      background-repeat: no-repeat;
    }
  }

  .welcome-center {
    max-width: 500px !important;

    .formsec {
      .btn_desc {
        align-items: flex-start;
      }
    }
  }

  .search_medicine .comman-width {
    max-width: 1000px;
  }

  .acceptedsec {
    max-width: 1000px;
  }

  .testimonial .comman-width .testimonial_inner {
    max-width: 1000px;
  }

  .testimonial .slick-initialized .slick-slide {
    width: 305px;
  }

  .carosuel-section .nav-btn.prev-slide.slick-arrow {
    left: 0 !important;
  }

  .carosuel-section .nav-btn.next-slide.slick-arrow {
    right: 0 !important;
  }

  .footer-section .comman-width {
    max-width: 1000px !important;
  }

  .footer-section .copyright {
    max-width: 1000px;
  }

  body {
    overflow-x: hidden;
  }

  .signupbox .Signup_login {
    max-width: 1000px !important;
  }

  section.banner-section.about-banner-section.aboutSecOne.green-theme.aboutbox_header .over {
    max-width: 900px !important;
  }
}

.pharmacyPage.cardPage #pharmacy_container .pharmacy_list .card .card_detail .share_card {
  > div {
    p {
      padding: 0;
      margin: 0;
      line-height: normal;
      @include apercubold;
      font-size: 16px;
      color: $formborder;
      margin-bottom: 5px;
    }

    .email_field {
      margin: 0;
      box-shadow: none;
      border: solid 1.2px #827c78;
      width: 100%;
      height: 45px;
      border-radius: 20.5px;
      margin-bottom: 32px;
      padding-left: 12.7px;
      display: flex;
      align-items: center;

      > input {
        letter-spacing: 0.14px;
        @include apercuregular;
        font-size: 16px;
        color: #827c78;
        display: flex;
        align-items: center;
        width: 100%;
        background: transparent;
        font-weight: normal;
        border: none;
        box-shadow: none;
        outline: none;
      }

      &.error_msg {
        position: relative;
        border-color: $footerfollowcolor !important;

        &:before {
          content: "!";
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: $formborder;
          color: $whitetext;
          @include apercubold;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: 10px;
        }

        input {
          border-color: $footerfollowcolor !important;
          padding-left: 35px;
        }

        & + span {
          top: 20px;
          @include apercuregular;
          font-size: 16px;
          font-weight: normal;
          color: $footerfollowcolor !important;
          margin-top: 0;
          display: flex;
          align-items: center;
          letter-spacing: -0.5px;
          top: calc(100% + 6px);
          padding-left: 27px;
          position: relative;
          top: -25px;
          margin-left: 12px;

          &:before {
            content: "!";
            min-width: 16px;
            min-height: 16px;
            border-radius: 50%;
            background: $formborder;
            color: $whitetext;
            @include apercubold;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            margin-right: 7px;
            border: none;
          }
        }
      }
    }

    .sendbtn {
      height: 45px;
      border-radius: 20.5px;
      width: 100%;
      background: $secondary;
      display: flex;
      align-items: center;
      justify-content: center;
      @include apercubold;
      font-size: 16px;
      color: $whitetext;
      letter-spacing: 0.14px;
      margin-bottom: 40.5px;
      cursor: pointer;
      position: relative;

      .loadProcess {
        width: 100%;
        height: 45px;
        max-width: 100%;
        max-height: 100%;
        background: transparent;
        border-radius: 4px;
        display: none;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        line-height: normal;
        font-weight: 600;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        padding: 0 !important;
        // transition: font-size 0.1s ease;
        overflow: hidden;

        &.loader {
          font-size: 200px;
          transition: font-size 1s ease;
          pointer-events: none;
          padding-left: 40%;
          padding-top: 2%;
          display: block;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 15px !important;

          &:before {
            content: "";
            border: 4px solid $secondary;
            border-radius: 50%;
            border-top: 4px solid $txt_white;
            width: 30px;
            height: 30px;
            -webkit-animation: spin 0.5s linear infinite;
            /* Safari */
            animation: spin 0.5s linear infinite;
            position: absolute;
          }
        }

        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }

          100% {
            -webkit-transform: rotate(360deg);
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }

      &.success {
        background: $formborder !important;
        pointer-events: none;
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 480px) {
  .loaderscreen.showloader > div:after {
    max-width: 300px;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .pharmacyPage .container .pharmacy_box .pharmacy_list span.saving-shown {
    bottom: -90px !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .loaderscreen.showloader > div:after {
    max-width: 420px;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

// testimonial arrow
@media screen and (min-width: 1025px) and (max-width: 1100px) {
  .carosuel-section .nav-btn.next-slide.slick-arrow {
    right: -20px !important;
    background-size: auto 40px;
  }

  .carosuel-section .nav-btn.prev-slide.slick-arrow {
    left: -27px !important;
    background-size: auto 40px;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1280px) {
  .carosuel-section .nav-btn.next-slide.slick-arrow {
    right: -60px !important;
  }

  .carosuel-section .nav-btn.prev-slide.slick-arrow {
    left: -60px !important;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1366px) {
  .testimonial .slick-initialized .slick-slide {
    width: 360px;
    margin-right: 20px;
  }

  .testimonial .comman-width .testimonial_inner {
    max-width: 1120px;
  }

  .carosuel-section .nav-btn.prev-slide.slick-arrow {
    left: -60px !important;
  }

  .carosuel-section .nav-btn.next-slide.slick-arrow {
    right: -60px !important;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1450px) {
  .carosuel-section .nav-btn.prev-slide.slick-arrow {
    left: -80px !important;
  }

  .carosuel-section .nav-btn.next-slide.slick-arrow {
    right: -80px !important;
  }
}

.testimonial.green-theme .comman-width .testimonial_inner h3,
.testimonial.green-theme .comman-width .testimonial_inner h2 {
  padding-left: 0 !important;
}

@media screen and (min-width: 0) and (max-width: 1024px) {
  .welcome-section .comman-width .welcome-center {
    max-width: 100% !important;
  }

  .footer-section .copyright {
    max-width: calc(100% - 60px);
  }

  .footer-section .comman-width .footer-adjust .fourth,
  .footer-section .comman-width .footer-adjust .third,
  .footer-section .comman-width .footer-adjust .second,
  .footer-section .comman-width .footer-adjust .first {
    min-width: 100%;
    max-width: 100%;
  }

  .footer-section .comman-width .footer-adjust .third .social {
    padding-left: 0;
    max-width: 185px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .welcome-section.how-works {
    background-image: none !important;

    .welcome-center {
      max-width: 800px !important;
    }
  }

  .welcome-section.download-edit:before {
    background-size: 100%;
    background-position: right top;
  }
}

.welcome-section.download-edit:after {
  bottom: -1px !important;
}

.btnshover {
  &:hover {
    background: $primary !important;

    .insider {
      display: none;
    }
  }
}

.contact-section.green-theme .newContactBtn.btnshover {
  &:hover {
    background: $primary !important;
  }
}

app-search .popular_sesrch ul li {
  &:hover {
    background: $formgrey !important;
  }
}

.navbar li a {
  font-size: 16px !important;
}

.welcome-center .formsec > .form-group:nth-child(3) {
  width: 100%;
}

.welcome-center .formsec > .form-group:nth-child(4) {
  width: 100%;
}

.search ul li {
  min-width: 170px;
  max-width: 170px;
}

app-search {
  .popular_sesrch {
    max-width: calc(100% - 41px);
    margin-left: 25px;
    margin-right: 16px;
  }
}

section.search_medicine:before {
  content: "";
  width: 100%;
  height: 15px;
  display: flex;
  background: linear-gradient(90deg, #19342f, #f2f2f2) !important;
}

app-search .popular_sesrch ul {
  justify-content: space-between !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .welcome-section .comman-width .welcome-center {
    flex-flow: column !important;
    display: flex !important;

    h2 {
      max-width: 50% !important;
    }

    .mobile_mobile_image {
      width: 50% !important;
      max-width: 384px;
      background: transparent !important;
      position: absolute !important;
      right: 0 !important;

      img {
        // max-height:686px !important;
      }
    }

    .formsec {
      margin-top: 30px !important;
      max-width: 50% !important;

      .btn_desc {
        p {
          max-width: 71% !important;
        }
      }
    }

    .search {
      padding-left: 30px !important;
      padding-right: 30px !important;
      text-align: left !important;

      ul {
        max-width: 768px !important;
        min-width: 700px !important;
        justify-content: space-between !important;
        flex-flow: row !important;
        margin-left: 0 !important;

        li {
          min-width: 190px !important;
          max-width: 190px !important;
          text-align: left !important;
        }
      }
    }
  }

  app-search .popular_sesrch {
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  section.accept-section {
    padding-left: 30px !important;

    .acceptedsec {
      flex-flow: row;

      .onmobile {
        display: none !important;
      }

      img.onweb {
        display: flex !important;
        max-width: 50% !important;
        height: auto;
      }
    }
  }

  section {
    &.footer-section {
      .footerlogo {
        display: flex !important;
      }

      .footer-adjust {
        flex-flow: row !important;

        > div {
          &.first {
            min-width: 108px !important;
            max-width: 108px !important;
            margin-right: 40px !important;
            margin-left: 0 !important;
            order: 1 !important;

            &:before {
              display: none !important;
            }

            ul {
              li {
                text-align: left !important;
              }
            }
          }

          &.second {
            order: 2 !important;
            padding-top: 29px !important;
            min-width: 108px !important;
            max-width: 108px !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
          }

          &.third {
            order: 3 !important;
            min-width: 198px !important;
            max-width: 198px !important;
            margin-left: auto !important;
            margin-right: auto !important;
            padding-top: 5px !important;
            border: none !important;
            margin-right: 15px !important;
          }

          &.fourth {
            order: 4 !important;
            max-width: 150px !important;
            min-width: 150px !important;
            border: none !important;
            padding-top: 0 !important;
            margin-left: 15px !important;
            margin-right: 0 !important;

            h2 {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 1024px) {
  app-search .popular_sesrch {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .mobileheader_download {
    background: #3cb26d !important;
    background: linear-gradient(to bottom, #3cb26d 0%, #275049 100%) !important;
  }
}

.testimonial .slick-initialized .slick-slide {
  box-shadow: 0 3px 20px rgba(62, 62, 63, 0.15);
  margin-left: 1px;
  margin-bottom: 10px;
}

#myModal.green-theme-modal .contact-section.card-modal button {
  @include apercuregular;
}

.showsuccess > div img {
  width: 35px !important;
  margin: 0 auto 30px 0 !important;
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .testimonial .slick-initialized .slick-slide .shadow {
    padding: 51px 40px 0 40px;
  }
  .loginPage .login-section .logo {
    display: initial;
  }
}

.navbar.green-theme li span {
  bottom: -36px !important;
}

app-search .popular_sesrch ul li {
  flex-grow: 1 !important;
  white-space: nowrap !important;
}

.firstloadpricing .price_searcharea .medicinenamesearch app-search .form-section {
  background: #f4f4f4 !important;
}

.search_medicine .comman-width app-search .form-section {
  background: #f2f2f2 !important;
}

app-search .form-section input[type="text"]::placeholder {
  color: $footerfollowcolor !important;
}

.search-fields .serachlist {
  height: auto !important;
  max-width: calc(100% - 41px) !important;
  margin-left: 25px !important;
  padding: 30px !important;
  max-height: 350px !important;
  top: 86px !important;
  box-shadow: 0px 0px 10px $formborder !important;

  .title {
    background: transparent !important;
    color: $secondary !important;
    padding-left: 0 !important;
    @include apercuregular;
    font-size: 16px !important;

    &.nodrugfound {
      span {
        color: $secondary !important;
        font-size: 16px !important;
        @include apercuregular;
      }

      p {
        @include apercubold;
        font-size: 20px !important;
        color: $footerfollowcolor !important;
      }
    }
  }

  ul {
    li {
      border-bottom: 1px solid $formgrey !important;
      padding-left: 0 !important;
      @include apercubold;
      font-size: 20px !important;
      transition: all 0.1s linear;

      &:hover {
        padding-left: 10px !important;
        transition: all 0.1s linear;
      }

      &:last-child {
        border: none !important;
      }
    }
  }
}

.firstloadpricing .price_searcharea .medicinenamesearch app-search .form-section .serachlist {
  top: 66px !important;
}

.firstloadpricing .price_searcharea .medicinenamesearch app-search .popular_sesrch ul {
  flex-wrap: nowrap;
  overflow-x: hidden;
  height: 65px;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover {
    overflow-x: scroll;
    &::-webkit-scrollbar-thumb {
      background-color: #2a524b;
      border-radius: 15px;
      position: absolute;
      top: 0;
    }
  }
}

.search_pharmacy_popup .search_pharmacy_select .filter_box .lists_box .lists {
  position: fixed !important;
  top: 80px !important;
  right: 30px !important;
  bottom: 0 !important;
  left: 30px !important;
  width: calc(100% - 60px) !important;
  background: #fff !important;
  z-index: 9 !important;
  padding-top: 8px !important;
  height: calc(100% - 80px) !important;
  display: flex;
  flex-flow: column;
}
.search_pharmacy_popup .search_pharmacy_select .filter_box .lists_box .lists > label {
  font-family: "Apercu-Regular" !important;
  font-size: 14px;
  letter-spacing: -0.53px;
  color: #b6aca3;
  margin-bottom: 0;
}
.search_pharmacy_popup .search_pharmacy_select .filter_box .lists_box .lists > div {
  line-height: 25px;
  font-weight: normal;
  padding-left: 0;
  font-size: 20px;
  font-family: "Apercu-Regular" !important;
  color: #19342f;
}
.search_pharmacy_popup .search_pharmacy_select .filter_box .lists_box .lists ul {
  background: #fff !important;
  position: relative !important;
  top: auto !important;
  right: 0 !important;
  bottom: auto !important;
  left: 0 !important;
  -webkit-transform: translate(0, -50%);
  transform: none !important;
  width: 100% !important;
  height: auto !important;
  max-width: 100% !important;
  max-height: 300px !important;
  overflow-y: auto !important;
  padding: 0 !important;
  margin: 0 auto !important;
  border-radius: 0 !important;

  li {
    @include apercuregular;
    font-size: 20px !important;
    color: $formborder !important;
  }
}

.search_pharmacy_popup .search_pharmacy_select .filter_box .lists_box .lists div.closepop {
  display: flex;
  height: 40px;
  position: relative;
  width: 100%;
  background: #fff;
  background: url(assets/images/downarrow_select.svg) no-repeat;
  background-size: 17px;
  z-index: 9;
  right: 35px;
  background-position: right;
  border-bottom: 1px solid $footerfollowcolor !important;
  top: 10px;
  right: 0;
}

.search_pharmacy_popup {
  z-index: 999999 !important;
}

.loaderscreen {
  z-index: 999999 !important;
}

.pharmacyPage .container .pharmacy_box .pharmacy_list {
  min-height: 360px;
  max-height: 360px;

  span {
    @include apercuregular;
    color: $footerfollowcolor;
    font-size: 16px !important;
  }

  span.notxt {
    @include apercubold;
    font-size: 30px !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: $formborder;
  transition: background-color 5000s ease-in-out 0s;
}

.pharmacyPage .pharmacy_box .select_filterList_parent .type_list ul {
  padding-left: 0 !important;
  padding-top: 0 !important;
  margin-left: -10px !important;

  li {
    font-size: 14px !important;
    color: #979797 !important;
    padding-left: 10px !important;

    &:hover {
      color: $whitetext !important;
      background: $footerfollowcolor !important;
    }
  }
}

.pharmacyPage .pharmacy_box .select_filterList_parent .type_list.list_open .collapse_box {
  display: flex;
  width: 100%;
  color: $footerfollowcolor;
  border-top: 1px solid $footerfollowcolor;
  padding-top: 10px;
  padding-bottom: 0;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;

  &:after {
    content: "";
    width: 20px;
    height: 11px;
    background: url(assets/images/down-arrow-active.png) no-repeat;
    margin-left: 10px;
  }
}

.pharmacyPage .container .pharmacy_box .select_filterList_parent .filter_box > div.type_box.open {
  border: none;

  &:before {
    display: none;
  }
}

.change_password .changepassword_main.custom_quantity {
  top: 50%;
  transform: translate(0, -50%);
}

.change_password .changepassword_main .input_box .reset_button.btnshover {
  &:hover {
    background: $primary !important;
  }
}

.pharmacyPage .container .pharmacy_box .select_filterList_parent .filter_box > div.type_box.Noddl.open {
  border-bottom: 1px solid $formgrey;
}

div[style="display: table-cell; vertical-align: middle; white-space: nowrap; text-align: center;"] > div {
  font-family: "Apercu-Bold" !important;
}

img[src="../assets/images/mapicon.svg"],
img[src="../assets/images/mapicon_active.svg"] {
  opacity: 0.92;
}

.pharmacyPage .container .pharmacy_box .pharmacy_list .card .name_Sec span {
  position: relative !important;
  right: auto !important;
}
app-pharmacy
  .pharmacyPage
  .container
  .pharmacy_box
  .pharmacylist_parent
  .map_list_box
  .pharmacy_list
  .showdiscountcard_view {
  min-height: 360px !important;
}
.welcome-section.download-edit:before {
  // background-size: 100% !important;
  // background-position: center top;
  // width: 60% !important;
  // background: url(assets/images/downloadiphone.svg) no-repeat !important;
}
.testimonial .slick-initialized .slick-slide {
  border-radius: 0 100px 0 0 !important;
  box-shadow: 0 3px 28px rgba(62, 62, 63, 0.15) !important;
}
.testimonial.green-theme .comman-width .testimonial_inner h3,
.testimonial.green-theme .comman-width .testimonial_inner h2 {
  padding-left: 20px !important;
}
.pharmacyPage.cardPage #pharmacy_container .pharmacy_list .card .card_box {
  box-shadow: 0 0 15px #ccc;
  border-radius: 20px;
  max-height: 254px;
  min-height: 254px;
}
.pharmacyPage.cardPage #pharmacy_container .pharmacy_list .card .backcard {
  width: 100%;
}
a[title="Verify LegitScript Approval"] {
  position: relative;
  left: 0;
  bottom: 0;
  max-width: 1260px;
  margin: 15px auto;
  display: block;
  width: 100%;
  padding: 0 15px;
  text-align: center;
}
.search ul li > div {
  min-height: 40px;
  max-height: 40px;
}
.loaderscreen.showloader > div.loaderpill > div {
  opacity: 1;
}
.banner-section.green-theme p {
  font-size: 50px !important;
}
#myModal.green-theme-modal .contact-section.card-modal button {
  text-align: center;
  justify-content: center;
  &.loader {
    pointer-events: none;

    display: flex;
    align-items: center;

    &:before {
      content: "";
      border: 4px solid $secondary;
      border-radius: 50%;
      border-top: 4px solid $txt_white;
      width: 30px;
      height: 30px;
      -webkit-animation: spin 0.5s linear infinite;
      /* Safari */
      animation: spin 0.5s linear infinite;
      position: absolute;
      right: 10px;
    }
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
.welcome-center .formsec .btn_desc a {
  &.loader {
    pointer-events: none;
    position: relative;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      border: 4px solid $secondary;
      border-radius: 50%;
      border-top: 4px solid $txt_white;
      width: 25px;
      height: 25px;
      -webkit-animation: spin 0.5s linear infinite;
      /* Safari */
      animation: spin 0.5s linear infinite;
      position: absolute;
      right: 10px;
    }
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
.change_password.quantity_box_manual {
  z-index: 999999 !important;
}
.pharmacyPage .container .pharmacy_box .select_filterList_parent .filter_box.PharmacyNear > label {
  margin-top: -5px !important;
}
.pharmacyPage .container .pharmacy_box .select_filterList_parent .filter_box.PharmacyNear {
  margin-bottom: 23px !important;
}
.welcome-section.download-edit:before {
  background-position: center top !important;
}
.testimonial .slick-initialized .slick-slide {
  box-shadow: -12px 24px 38px rgba(62, 62, 63, 0.15) !important;
}
.signupbox .Signup_login .background_area {
  background-position: -1px -1px !important;
}
.welcome-section.download-edit form {
  z-index: 999 !important;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .testimonial .comman-width .testimonial_inner .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  app-pharmacy .firstloadpricing .price_searcharea .zipbox {
    display: none;
  }
  app-pharmacy .firstloadpricing .price_searcharea .medicinenamesearch app-search .search-fields .form-section {
    background: transparent !important;
  }
  app-pharmacy
    .firstloadpricing
    .price_searcharea
    .medicinenamesearch
    app-search
    .search-fields
    .form-section
    .form-control {
    &::placeholder {
      font-size: 16px !important;
      @include apercubold;
    }
    font-size: 16px !important;
    @include apercubold;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pharmacyPage.cardPage #pharmacy_container .pharmacy_list .card .card_detail {
    width: calc(100% - 310px);
  }
  .pharmacyPage.cardPage #pharmacy_container .pharmacy_list .card .card_box {
    max-width: 290px;
    max-height: 230px;
    margin-right: 20px;
    .card_box_header {
      font-size: 9px !important;
    }
    .card_box_body {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      .logo_phone {
        .img_logo {
          img {
            max-width: 70px;
          }
        }
        .member_support {
          span {
            font-size: 10px !important;
          }
        }
      }
      .bin_pcn_member_id > div span {
        font-size: 14px !important;
      }
    }
  }
  .aboutImgResponsive {
    width: 60% !important;
    margin-left: auto !important;
    display: block !important;
  }

  .firstloadpricing .price_searcharea .medicinenamesearch app-search .form-section {
    background: transparent !important;
  }

  app-pharmacy .firstloadpricing .price_searcharea {
    flex-flow: row !important;

    .zipbox {
      min-width: 250px !important;
      max-width: 250px !important;
      align-items: flex-start !important;
      flex-flow: column !important;

      > input {
        min-width: calc(100% - 30px) !important;
        min-height: 55px !important;
        margin-bottom: 10px !important;
      }
    }

    .medicinenamesearch {
      min-width: calc(100% - 250px) !important;
      max-width: calc(100% - 250px) !important;

      app-search {
        .search-fields {
          margin-bottom: 0 !important;
          margin-top: 0 !important;

          .form-section {
            min-height: 55px;

            input[type="text"] {
              min-height: inherit !important;

              &::placeholder {
                font-size: 14px !important;
              }
            }

            .lowest {
              min-width: 175px !important;
              max-width: 175px !important;
              position: absolute !important;
              top: 50% !important;
              transform: translate(0, -50%) !important;
              right: 16px !important;
              margin: 0 !important;
              left: auto !important;
              min-height: 40px;
              max-height: 40px;
            }
          }
        }
      }
    }
  }
  section.welcome-section.download-edit.aboutbox_header {
    .welcome-center {
      max-width: 50% !important;
      h2 {
        max-width: 100% !important;
        /*margin-top: 0 !important;*/
      }
    }
  }
  .welcome-section.download-edit:before {
    display: flex !important;
    background: url(assets/images/mobile_phone_mobile.webp) !important;
    background-size: 90% !important;
    background-repeat: no-repeat !important;
    max-width: 50%;
    background-position: center top !important;
  }
  app-download .welcome-section .comman-width .welcome-center span,
  app-download .how-works .comman-width .welcome-center .search h2 {
    padding-left: 0 !important;
  }
  app-download .how-works .comman-width .welcome-center .search ul li span,
  app-download .how-works .comman-width .welcome-center .search ul li p {
    text-align: left !important;
  }
  .testimonial.green-theme .comman-width .testimonial_inner h3,
  .testimonial.green-theme .comman-width .testimonial_inner h2 {
    padding-left: 0 !important;
  }
  .testimonial .slick-initialized .slick-slide {
    width: calc(100vw / 3 - 30px) !important;
  }
  .testimonial .slick-initialized .slick-slide .shadow {
    margin-top: 10px !important;
  }
  .testimonial .comman-width .row .col-lg-12 .nav-btn.prev-slide.slick-arrow {
    left: -25px !important;
    bottom: auto;
    top: 50% !important;
  }
  .testimonial .comman-width .row .col-lg-12 .nav-btn.next-slide.slick-arrow {
    right: -25px !important;
    top: 50% !important;
    bottom: auto !important;
  }
  app-download .welcome-section .comman-width .welcome-center span.rated,
  app-download .welcome-section .comman-width .welcome-center span.get {
    padding-left: 30px !important;
  }
  .pharmacyPage #pharmacy_container.container {
    padding-left: 0 !important;
  }
}
/* how it works */
.easy-section {
  min-height: 570px;
  background-image: rgba(0, 64, 126, 1) no-repeat;
  background-image: -moz-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
  background-image: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 64, 126, 1)),
    color-stop(100%, rgba(1, 43, 83, 1))
  );
  background-image: -webkit-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
  background-image: -ms-linear-gradient(left, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
  background-image: linear-gradient(to right, rgba(0, 64, 126, 1) 0%, rgba(1, 43, 83, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00407e', endColorstr='#012b53', GradientType=1);
  padding: 65px 0 65px 0;
}
.easy-section h2 {
  font-size: 50px;
  color: #fff;
  text-align: center;
  font-family: Apercu-Bold;
  padding-bottom: 8px;
}
.easy-section .easy-step {
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 26px;
  display: block;
  padding-bottom: 20px;
}
.easy-section .col-lg-4 span {
  font-size: 16px;
  color: #fff;
  line-height: 21px;
  text-align: center;
  display: block;
  padding-bottom: 10px;
}
.easy-section .col-lg-4 p {
  font-size: 20px;
  color: #fff;
  line-height: 24px;
  text-align: center;
  padding: 0 40px 10px 40px;
}
.easy-section .col-lg-4 img {
  display: block;
  margin: 0 auto 25px auto;
}
.easy-section .easy-content {
  max-width: 1129px;
  margin: 0 auto;
}
.easy-section .card {
  font-family: Apercu-Bold !important;
  width: 194px;
  height: 45px;
  border-radius: 20.5px;
  background-color: #7fc534;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 44px;
  letter-spacing: 0.14px;
  text-align: center;
  color: #fff;
  margin: 45px auto 0 auto;
}
.easy-section .disclaimer {
  font-size: 12px;
  color: #fff;
  text-align: center;
  display: block;
  margin-top: 15px;
}
.acceptedsec .howitwork p.bottom-gap {
  padding-bottom: 10px;
}
.question-section h2 {
  font-size: 30px;
  color: #fff;
  line-height: 32px;
  font-family: Apercu-Bold;
  text-align: center;
  padding-bottom: 50px;
}
.question-section h3 {
  font-size: 20px;
  color: #fff;
  line-height: 26px;
  font-family: Apercu-Regular;
  font-weight: normal;
}
.question-section {
  background: #7fc534;
  padding: 60px 0 80px 0;
}
.question-section .question-content {
  max-width: 1129px;
  margin: 0 auto;
}
.question-section h3 {
  font-size: 20px;
  color: #00407e;
}
.question-section p {
  font-size: 18px;
  color: #fff;
  line-height: 26px;
  padding-bottom: 30px;
}
.question-section .more {
  width: 600px;
  height: 205px;
  background: #fff;
  border-radius: 20px;
  margin: 50px auto 0 auto;
}
.question-section .more span {
  font-size: 30px;
  color: #00407e;
  line-height: 42px;
  text-align: center;
  display: block;
  padding-top: 50px;
}
.question-section .card {
  font-family: Apercu-Bold !important;
  width: 152px;
  height: 45px;
  border-radius: 20.5px;
  background-color: #7fc534;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 44px;
  letter-spacing: 0.14px;
  text-align: center;
  color: #fff;
  margin: 25px auto 0 auto;
}
.testimonial.custom {
  padding-top: 80px;
}
.acceptedsec .howitwork h2.heading-gap {
  margin-top: 15px;
}

@media screen and (min-width: 0) and (max-width: 1024px) {
  .easy-section .easy-content,
  .question-section .question-content {
    max-width: 88%;
  }

  .easy-section .col-lg-4 p {
    padding: 0 0px 10px 0px;
  }

  .mobMenu nav .login-link a {
    padding-left: 0 !important;
  }

  .mobMenu nav > div.m-header-deny .m-close {
    transform: translate(0, -20px) !important;
    width: 26px !important;
    height: 26px !important;
  }

  .mobMenu nav > div.m-header-deny .m-close:before {
    bottom: -11px !important;
    font-size: 9px !important;
    left: 0px !important;
  }

  .btn-hover-effect .insider {
    display: none !important;
  }

  body {
    overflow-x: hidden !important;
  }

  app-pharmacy .firstloadpricing {
    margin-top: 42px !important;
  }

  .pharmacyPage .container .pharmacy_box .select_filterList_parent .filter_box .type_box::before {
    right: 0 !important;
  }

  .pharmacylist_parent .right_btn_sec .sortby {
    margin-top: -11px !important;
  }

  .pharmacylist_parent {
    min-width: 100% !important;
  }

  app-pharmacy .pharmacyPage .container .pharmacy_box .pharmacylist_parent .right_btn_sec {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .pharmacyPage .container .pharmacy_box .pharmacy_list .discount_card .discount_card_parent .pharmacybox .price {
    top: 5px !important;
  }

  .pharmacyPage .container .pharmacy_box .pharmacy_list .loadmore {
    width: calc(100% - 58px) !important;
  }

  app-pharmacy .pharmacyPage .container .pharmacy_box .pharmacylist_parent .map_list_box .maps_box {
    margin-left: 0 !important;
  }

  app-pharmacy .pharmacyPage .container .pharmacy_box .pharmacylist_parent .map_list_box .pharmacy_list {
    margin-left: 0 !important;
  }

  .pharmacyPage #pharmacy_container.container {
    padding-left: 0 !important;
  }

  app-pharmacy
    .pharmacyPage
    .container
    .pharmacy_box
    .pharmacylist_parent
    .map_list_box
    .pharmacy_list
    .showdiscountcard_view
    .discount_card
    .discount_card_parent {
    height: 100%;
  }

  .pharmacyPage .container .pharmacy_box .pharmacy_list {
    min-height: 385px;
  }

  app-pharmacy
    .pharmacyPage
    .container
    .pharmacy_box
    .pharmacylist_parent
    .map_list_box
    .pharmacy_list
    .showdiscountcard_view {
    min-height: 385px !important;
    overflow: auto;
  }

  header.header-section .menu_bar_btn {
    width: 26px;
    height: 29px;
    background: url(assets/images/menu_icon.svg) no-repeat !important;
    right: 30px !important;

    span {
      display: none;
    }

    img {
      display: none;
    }
  }

  .mobMenu nav > div.m-header-deny .m-close {
    width: 30px !important;
    height: 40px !important;
    background: url(assets/images/close_icon.svg) no-repeat !important;
    background-size: 100%;
    right: 30px;

    &:before {
      display: none;
    }
  }
}
/* login page */
.login-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.loginPage .monitor-tag {
  display: none;
}
html,
body {
  height: 100%;
  min-height: 100%;
}
.loginPage app-root,
.loginPage app-login,
.loginPage app-root div:nth-child(2) {
  height: 100%;
}
.login-section .form-control {
  width: 480px;
  height: 64px;
  margin: 0 auto 16px auto;
  padding: 0 21px;
  border-radius: 32px;
  border: solid 1px #827c77;
  background-color: #ffffff;
  font-size: 18px;
  color: #2a524b;
  font-family: "Apercu-Bold";
}
.caller-section {
  margin-top: 80px;
  @media screen and (min-width: 0) and (max-width: 1024px) {
    margin-top: 60px;
  }
  .comman-width {
    margin-left: 300px;
    width: calc(100% - 300px);
    display: flex;
    flex-flow: column;
    @media screen and (min-width: 0) and (max-width: 1024px) {
      margin-left: 0;
      width: 100%;
    }
  }
}
.login-section .form-control:focus,
.caller-section .form-control:focus {
  border: 1px solid #17332e;
}
.login-section .btn {
  margin: 37px auto 0 auto;
  float: none;
  display: block;
  width: 480px;
  height: 56px;
  padding: 14px 0px;
  border-radius: 24px;
  background-color: #43b672 !important;
  font-size: 20px;
  color: #fff;
  font-family: "Apercu-Regular";
}
.login-section .form-control::placeholder,
.caller-section .form-control::placeholder {
  font-size: 18px;
  color: #2a524b;
  font-family: "Apercu-Bold";
}
.login-section .colorRed {
  width: 480px;
  margin: 0 auto 15px auto;
  color: #2a524b !important;
  font-size: 16px;
}
.login-section .logo img {
  display: block;
  margin: 0 auto 48px auto;
  min-width: 172px;
  max-width: 172px;
}
.login-section .logo {
  display: block;
  margin-top: 25px;
}
.caller-section h2 {
  font-size: 48px;
  color: #19342f;
}
.caller-section h2 span {
  display: block;
  font-size: 18px;
  color: #ff8b6c;
  font-weight: normal;
}
.caller-section .inner-width {
  max-width: 780px;
  padding: 25px 0;
}
.caller-section .form-control {
  width: 383px;
  height: 56px;
  margin: 0 32px 19px 0;
  padding: 0px 21px;
  border-radius: 28px;
  border: solid 1px #827c77;
  background-color: #ffffff;
  font-size: 18px;
  color: #2a524b;
  font-family: "Apercu-Bold";
}
.caller-section .formsec {
  display: flex;
  margin-top: 30px;
  flex-flow: row wrap;
  justify-content: space-between;
}
.caller-section .formsec > .form-group {
  width: calc(50% - 7px);
  margin: 0px;
}
.caller-section .btnshover {
  width: 220px;
  height: 56px;
  line-height: 56px;
  margin: 10px 14px 0 0;
  padding: 0 0 17px;
  border-radius: 20.5px;
  background-color: #ff8b6c;
  font-size: 20px;
  color: #fff;
  float: left;
  text-align: center;
}
.caller-section .btnshover img {
  float: left;
  margin: 13px 13px 0 13px;
}
.caller-section .btnshover.success-btn {
  width: 240px;
  background: #44b672;
  text-align: left;
}
.caller-section .btnshover.success-btn:hover {
  background: #44b672 !important;
}
.caller-section .colorRed {
  margin-bottom: 15px;
  display: block;
  margin-top: 0px;
  color: #2a524b !important;
  font-size: 16px;
}
.caller-section .btn_desc {
  width: 100%;
  position: relative;
}
.caller-section .load-container {
  position: absolute;
  top: 38px;
  right: auto;
  left: 209px;
}
.login-section .form-control.errorIcon,
.caller-section .form-control.errorIcon {
  padding-left: 46px !important;
  background: url(assets/images/info-icon.svg) 15px no-repeat;
}
.load-container {
  position: absolute;
  top: 29px;
  right: 10px;
}
.theme-loader {
  pointer-events: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.theme-loader:before {
  content: "";
  border: 4px solid #2a524b;
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 32px;
  height: 32px;
  -webkit-animation: 0.5s linear infinite spin;
  animation: 0.5s linear infinite spin;
  position: absolute;
  right: 10px;
}
.login-section .btn-section {
  width: 480px;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 0) and (max-width: 980px) {
  .easy-section .easy-content,
  .question-section .question-content {
    max-width: 82%;
  }
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .testimonial .slick-dots li.slick-active button:before,.testimonial .slick-dots li button:before {
    font-size: 20px !important;
  }
  .testimonial .slick-dots li {
    margin-right: 15px;
  }
  .caller-section h2 {
    font-size: 30px;
  }
  .login-section .btn-section,
  .login-section .form-control,
  .login-section .btn,
  .caller-section .inner-width,
  .caller-section .formsec > .form-group,
  .caller-section .form-control,
  .btn_desc {
    width: 100%;
  }
  .accept-section .acceptedsec .howitwork {
    order: 2;
  }

  .accept-section .acceptedsec {
    padding-bottom: 40px;
  }

  .accept-section .acceptedsec > img {
    padding: 1em;
  }

  .question-section .more {
    width: 100%;
    margin-top: 0px;
  }

  .easy-section .card {
    width: 100%;
    margin-top: 10px;
  }

  .question-section .more span {
    font-size: 24px;
  }

  .easy-section,
  .question-section {
    padding: 40px 0 40px 0;
  }

  .question-section p {
    padding-bottom: 15px;
  }

  .question-section h2 {
    padding-bottom: 20px;
  }

  .easy-section h2 {
    font-size: 26px;
  }

  app-mycards {
    .pharmacyPage.cardPage {
      #pharmacy_container {
        padding: 0 15px !important;

        > h2 {
          font-size: 40px;
        }

        .sorting_box {
          margin-bottom: 30px;
        }

        .pharmacy_box {
          .pharmacy_list {
            .card {
              margin-left: 0 !important;
              margin-right: 0 !important;
              min-width: 100% !important;
              max-width: 100% !important;

              .card_box {
                width: 100%;
                margin-right: 0;
                display: flex;
                flex-flow: column;

                .card_box_body {
                  padding-left: 20px;
                  padding-right: 20px;
                }
              }

              .card_detail {
                width: 100%;
                display: flex;
                flex-flow: column;

                .address_number_box {
                  width: 100%;
                  padding-left: 0;
                  border-left: none;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer-section .comman-width .footer-adjust .third .social {
    max-width: 123px;
  }
}
.search_medicine.new-design {
  background: #f2f2f2;
  min-height: 540px;
}
.search_medicine.new-design::before {
  background: none !important;
}
.search_medicine.new-design .comman-width app-search .form-section .form-control {
  color: #00407e !important;
}
.search_medicine.new-design .comman-width app-search .form-section .form-control::placeholder {
  color: #00407e !important;
}
.search_medicine.new-design .comman-width app-search .form-section {
  background: none !important;
  border: 2px solid #00407e;
}
.search_medicine.new-design .comman-width app-search .form-section .lowest {
  background: #7fc534;
  color: #fff;
}
.search_medicine.new-design .comman-width app-search .popular_sesrch .title {
  color: #00407e;
}
.search_medicine.new-design .comman-width app-search .popular_sesrch ul li {
  border: 1.2px solid #00407e;
  color: #00407e;
}
.search_medicine.new-design .comman-width .get {
  width: 236px;
  height: 45px;
  margin: 0 12px 0 0;
  padding: 13px 0 12px;
  border-radius: 70.5px;
  border: solid 1px #fff;
  background-color: #7fc534;
  color: #fff;
  display: inline-block;
  margin: 45px 12px 45px 0;
  line-height: normal;
}
.search_medicine.new-design .btn-section {
  text-align: center;
  width: 100%;
  overflow: hidden;
}
.search_medicine.new-design h2 {
  color: #00407e;
  font-size: 38px;
  text-align: center;
  margin-bottom: 40px;
}
.search_medicine.new-design h2 span {
  color: #7fc534;
}
welcome-section.home-new-design {
  min-height: 900px;
  background-image: url("assets/images/hero_iphone.png"), linear-gradient(to right, #00407e 0%, #012b53 100%)!important;
  &:before {
    content: "";
    width: 100%;
    height: 15px;
    display: flex;
    background: url(assets/images/transition_to_search_section.svg) no-repeat;
    background-size: cover;
    //background: linear-gradient(90deg, #19342f, #f2f2f2) !important;
  }
}
.welcome-section.home-new-design h2 {
  color: #fff;
  margin: 0px;
}
.welcome-section.home-new-design .welcome-center {
  justify-content: center;
  min-height: 900px;
}
.home-new-design .welcome-center .formsec input {
  border: 2px solid #fff;
  color: #fff;
}
.welcome-center .formsec .reset_text {
  color: #ff8b6c;
  font-size: 16px;
  font-family: Apercu-Regular !important;
  cursor: pointer;
  display: inline;
}
.home-new-design .welcome-center .formsec input::placeholder {
  color: #fff !important;
}
.home-new-design .welcome-center .formsec .x-section p {
  color: #fff;
  font-family: Apercu-Regular !important;
  font-size: 16px;
  letter-spacing: -0.01px;
  line-height: 20px;
  font-weight: 400;
  padding-top: 12px;
}
.easy-section .col-lg-4 .step1-click,
.welcome-center .step1-click {
  cursor: pointer;
}
.home-new-design .welcome-center .formsec .x-section p a {
  color: #7fc534;
}
.easy-section.new-design {
  background: #f2f2f2;
}
.easy-section.new-design h2 {
  padding-top: 40px;
  color: #00407e;
}
.easy-section.new-design .easy-step,
.easy-section.new-design .col-lg-4 span,
.easy-section.new-design .col-lg-4 p,
.easy-section.new-design .disclaimer {
  color: #00407e;
}
@media screen and (min-width: 0) and (max-width: 1024px) {
  .search_medicine.new-design h2 br {
    display: none;
  }
  .search_medicine.new-design {
    min-height: 630px;
  }
  .welcome-section.home-new-design .welcome-center {
    min-height: inherit;
  }
  .welcome-section.home-new-design .comman-width .welcome-center .mobile_mobile_image {
    top: 30px;
  }
  .welcome-section.home-new-design .comman-width .welcome-center h2 {
    margin-top: 65px;
  }
  .accept-section .acceptedsec > h2 {
    letter-spacing: -2.21px !important;
  }

  .mobileheader_download {
    background: url(assets/images/appdownloadheader.svg) !important;
  }

  .welcome-section .comman-width .welcome-center .mobile_mobile_image img {
    align-self: center;
  }

  .welcome-section .comman-width .welcome-center .formsec .btn_desc p {
    max-width: 100% !important;
    justify-content: flex-start;
    text-align: left;
    display: block;
  }

  app-pharmacy
    .pharmacyPage
    .container
    .pharmacy_box
    .pharmacylist_parent
    .map_list_box
    .pharmacy_list
    .showdiscountcard_view:after {
    /*content: "";
    position: absolute;
    bottom: 4px;
    height: 30px;
    background: #fff;
    width: 100%;*/
  }

  .pharmacyPage .container .pharmacy_box .pharmacy_list .discount_card .pharmacyDetail .email_field.error_msg + span {
    top: -10px !important;
  }

  .footer-section {
    // padding-top: 79px !important;
  }
}
@media screen and (min-width: 0) and (max-width: 980px) {
  .welcome-section.home-new-design .comman-width .welcome-center .mobile_mobile_image {
    top: 65px;
  }
  .search_medicine.new-design {
    min-height: 745px;
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .search-fields .serachlist {
    top: 45px !important;
    padding: 10px 30px !important;
  }
  .search_medicine.new-design {
    min-height: 860px;
  }
  .welcome-section.home-new-design .comman-width .welcome-center h2 {
    margin-top: 10px;
  }
  .welcome-section.home-new-design .comman-width .welcome-center .mobile_mobile_image {
    display: none;
  }
  .welcome-section.home-new-design .comman-width .welcome-center .formsec {
    margin-top: 10px;
  }
  .welcome-section.home-new-design {
    padding-bottom: 10px;
  }
  .firstloadpricing .price_searcharea .medicinenamesearch app-search .form-section .serachlist {
    top: 48px !important;
  }

  .welcome-section .comman-width .welcome-center .search {
    p {
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }

  .slick-track {
    max-height: 405px !important;
  }

  .testimonial .slick-initialized .slick-slide .shadow {
    margin-top: 10px !important;
  }

  .banner-section.green-theme p {
    font-size: 36px !important;
  }

  .honest-section.green-theme .content ul {
    margin-bottom: 35px !important;
  }

  app-contact-us .contact_info {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: none !important;
  }

  .welcome-section .comman-width .welcome-center .search ul {
    margin: 0 auto !important;
  }

  .app-link a img {
    max-width: 147px !important;
  }
}

* {
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .firstloadpricing .price_searcharea .medicinenamesearch app-search .form-section .serachlist {
    top: 55px !important;
  }

  .search-fields .serachlist {
    top: 46px !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 0) {
  .pharmacyPage #pharmacy_container.container {
    padding: 0 25px !important;
  }
  .search_medicine.new-design .comman-width app-search .form-section {
    border: none !important;
  }
}
@media screen and (max-width: 480px) and (min-width: 0) {
  .search_medicine.new-design h2 {
    font-size: 23px;
  }
  .search_medicine.new-design {
    min-height: 810px;
  }
  .search_medicine.new-design .comman-width .get {
    font-size: 13px;
    width: 135px;
  }
  .search_medicine.new-design h2 br {
    display: none;
  }
  .search_medicine.new-design .comman-width .get {
    margin: 20px 12px 5px 0;
  }
  .caller-section .load-container {
    right: 5px;
    left: auto;
  }
  .login-section .logo {
    margin-top: 0px;
  }
  .caller-section .btnshover,
  .caller-section .btnshover.success-btn {
    width: 100%;
    text-align: center;
  }
  .caller-section .btnshover.success-btn img {
    margin-top: 0px;
    float: none;
  }
}

.success_group_validate {
  position: fixed;
  top: 0;
  background-color: #ff8b6c;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 16px;
  color: #19342f;
  width: 100%;
  height: 75px !important;
  // line-height: 75px !important;
  z-index: 10000000;
  padding: 5px 30px;
  display: table;

  p {
    display: table-cell;
    vertical-align: middle;
  }
  app-pharmacy .pharmacyPage .container .pharmacy_box .pharmacylist_parent .map_list_box .pharmacy_list {
    overflow: visible;
  }
}
@media screen and (min-width: 575px) and (max-width: 1920px) {
  .easy-section .easy-content .row .col-lg-4 {
    flex: 0 0 33.3%;
  }
  .header-section {
    min-height: 91px !important;
  }
}
@media screen and (min-width: 0) and (max-width: 1024px) {
  .header-section {
    min-height: 84px !important;
  }
  .welcome-section .comman-width .welcome-center .formsec .btn_desc p {
    display: block;
  }
}
.disclaimerrr {
  padding-top: 10px;
}
.white-bg {
  background: #fff;
  padding: 5px 30px 10px 30px;
  margin: 35px 0 0 0;
  border-radius: 20px;
  max-width: 850px;
  position: relative;
}
.caller-section .white-bg.second .load-container {
  position: absolute;
  top: 30px;
  right: 5px;
  left: auto;
}
.caller-section h4 {
  display: block;
  font-size: 18px;
  color: #4e625f;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 0px;
}
.caller-section .checkbox-container {
  position: relative;
  top: auto;
  margin-left: 0px;
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: normal;
  padding: 0 0 0 37px;
  color: #19342f;
  margin-bottom: 50px;
}
.caller-section .checkmark {
  background-color: #fff;
  width: 24px;
  height: 24px;
  border: 1px solid #ff8b6c;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 4px;
}
.caller-section .checkbox-container .checkmark:after {
  border-color: #ff8b6c;
  top: 4px;
  left: 8px;
}
.caller-section .checkbox-container:hover input ~ .checkmark {
  background-color: #fff;
}
.signature {
  width: 100%;
  overflow: hidden;
  align-items: center;
}
.signature a {
  width: 100px;
  height: 36px;
  line-height: 36px;
  margin: 9px 0 0 0;
  padding: 0 0 17px;
  border-radius: 8px;
  border: 1px solid #ff8b6c;
  font-size: 16px;
  color: #ff8b6c;
  float: right;
  text-align: center;
}
.signature a:hover {
  color: #ff8b6c;
}
.white-bg.second span.name {
  display: block;
  font-size: 20px;
  color: #19342f;
}
.search_pharmacy_popup.signature .address-section {
  font-size: 16px;
  color: #19342f;
}
.search_pharmacy_popup.signature .search_pharmacy_select h2 {
  border-bottom: 1px solid rgba(134, 134, 134, 0.2);
  padding-bottom: 13px;
  font-size: 24px;
  margin-bottom: 17px;
  color: #000;
}
.search_pharmacy_popup.signature .search_pharmacy_select input {
  border: 0.8px solid #827c77;
  width: 100%;
  height: 42px;
  border-radius: 38px;
  margin-top: 12px;
  padding: 11px 19px 11px 19px;
  color: #2a524b;
  font-size: 15px;
  margin-bottom: 12px;
  font-family: "Apercu-Regular";
  transition: all 0.2s ease-in-out;

  &.errorIcon {
    background: url(../src/assets/images/info-icon.svg) no-repeat;
    background-position: 10px 9px;
    padding-left: 40px;
  }
}
.search_pharmacy_popup.signature .search_pharmacy_select input::placeholder {
  color: #2a524b;
}
.caller-section .changepassword_main .btnshover {
  margin-top: 12px;
  border: none;
  width: 160px;
}
.search_pharmacy_popup.signature .search_pharmacy_select {
  min-height: 440px;
  padding-top: 30px;
  height: auto;
}
.caller-section .white-bg.second .inner-width {
  padding: 29px 0 20px 0;
}
.caller-section .changepassword_main .colorRed {
  margin-bottom: 0px;
  margin-top: 0px;
}
.search_pharmacy_popup.signature .btn-section {
  position: relative;
}
.caller-section .changepassword_main .btnshover.animate {
  text-align: left;
  padding-left: 35px;
  pointer-events: none;
}
.caller-section .btn-container {
  position: absolute;
  top: 40px;
  right: auto;
  left: 158px;
  text-align: left;
}
.forgot-access {
  width: 480px;
  margin: 0 auto;
  text-align: right;
}
#recoverForm label {
  display: block;
  width: 480px;
  margin: 0 auto;
  text-align: left;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: normal;
}
.loginPage.access app-root > div:nth-child(3) {
  height: 100%;
}
.loginPage.access app-root > div.top_search:nth-child(3) {
  height: auto;
}
.enrollparent.past h3 {
  font-size: 20px;
  color: #19342f;
  font-weight: normal;
  padding-bottom: 20px;
  margin-bottom: 0px;
  @include apercubold();
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .btn-container {
    position: relative;
    top: 0;
    right: 0;
    text-align: left;
    left: -20px;
    .theme-loader.save {
      position: relative;
      top: 0;
      left: 0;
    }
  }
}
.caller-section .enrollparent .inner-width {
  // padding-top: 0px;
  overflow: hidden;
  position: relative;
}
.enrollparent.past .enroll-content {
  height: 100%;
  overflow: hidden;
}
.enrollparent.past tr {
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  float: left;
}
.enrollparent.past tr:last-child {
  border: none;
}
.enrollparent.past tr td {
  font-size: 14px;
  color: #19342f;
  line-height: 44px;
  word-wrap: break-word;
}
.enrollparent.past .header th {
  font-size: 12px;
  color: #979797;
  font-family: "Apercu-Bold" !important;
  text-transform: uppercase;
  line-height: 34px;
}
.enrollparent.past tr,
.enrollparent.past table {
  display: block;
}
.enrollparent.past thead,
.enrollparent.past tbody {
  display: block;
  width: 100%;
}
.enrollparent.past tr .first {
  width: 15%;
  float: left;
}
.enrollparent.past tr .second {
  width: 15%;
  float: left;
}
.enrollparent.past tr .third {
  width: 15%;
  float: left;
}
.enrollparent.past tr .fourth {
  width: 25%;
  float: left;
}
.enrollparent.past tr .fifth {
  width: 20%;
  float: left;
}
.enrollparent.past tr .sixth {
  width: 10%;
  float: left;
}
.enrollparent.past tr .sixth img {
  cursor: pointer;
}
.enrollparent.past .img-gap {
  margin-right: 15px;
}
.search_pharmacy_popup.signature.past_enroll .search_pharmacy_select {
  min-width: 740px;
}
.search_pharmacy_popup.signature.past_enroll .search_pharmacy_select h2 {
  font-size: 36px;
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
  color: #19342f;
}
.caller-section .past_enroll .formsec {
  margin-top: 20px;
}
.search_pharmacy_popup.signature.past_enroll .search_pharmacy_select input {
  height: 56px;
  border: 1px solid #827c77;
  font-size: 20px;
  color: #2a524b;
  font-weight: 500;
  border-radius: 50px;
}
.search_pharmacy_popup.past_enroll .search_pharmacy_select {
  border-radius: 10px 100px 10px 10px;
}
.caller-section .past_enroll .formsec > .form-group.full {
  width: calc(100% - 172px);
}
.caller-section .past_enroll .formsec > .form-group.full.first-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.caller-section .past_enroll .formsec > .form-group.full .adjust {
  width: calc(50% - 7px);
}
.caller-section .past_enroll .btnshover {
  height: 48px;
  line-height: 48px;
  width: 120px;
  font-size: 16px;
  border-radius: 10px;
}
.caller-section .past_enroll .btnshover.resend {
  width: 160px;
  background: #fff !important;
  border: 1px solid #ff8b6c;
  color: #ff8b6c;
}
.caller-section .past_enroll .btnshover.resend:hover {
  background: #2a524b !important;
}
.past_enroll .formsec .note {
  font-size: 14px;
  color: #2a524b;
  line-height: 18px;
  display: block;
  margin-top: 38px;
}
.past_enroll .formsec .note span {
  color: #ff8b6c;
}
.caller-section .past_enroll .btnshover:hover {
  border: 1px solid #2a524b;
}
.pagination ul {
  display: flex;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}
.pagination .prev {
  text-indent: -9999px;
  background: url("assets/images/prev.svg") left center no-repeat;
  width: 11px;
  height: 17px;
  border: none;
  margin: 3px 1px 0 0;
}
.pagination .next {
  text-indent: -9999px;
  background: url("assets/images/prev.svg") left center no-repeat;
  width: 11px;
  height: 17px;
  border: none;
  margin: 3px 0 0 0px;
  transform: rotate(180deg);
}
.pagination li a {
  color: #19342f;
  font-size: 14px;
  width: 24px;
  height: 24px;
  float: left;
  text-align: center;
  line-height: 24px;
}
.pagination li a span {
  display: block;
}
.pagination li {
  padding: 0 1px;
}
.pagination a {
  color: #19342f;
  font-size: 14px;
}
.pagination .prev[disabled],
.pagination .next[disabled] {
  opacity: 0.3;
}
.pagination .first {
  padding-right: 5px;
}
.pagination .last {
  padding-left: 5px;
}
.pagination li.active a {
  background: #ff8b6c;
  border-radius: 30px;
  color: #fff;
}
.theme-loader.save {
  position: absolute;
  top: -6px;
  left: -38px;
}
.theme-loader.resend {
  position: absolute;
  top: -6px;
  left: 155px;
}
.caller-section .past_enroll .btnshover.resend.animate {
  width: 180px;
  padding-left: 20px;
  pointer-events: none;
}
.caller-section .past_enroll .btnshover.animate {
  text-align: left;
  padding-left: 20px;
  pointer-events: none;
}
.caller-section .past_enroll .btnshover.success-btn {
  width: 195px;
}
.caller-section .btnshover.success-btn img {
  margin-top: 7px;
}
.agent-support {
  width: calc(100% - 50px);
  margin: 0 25px;
  background: #fff6f3;
  border-radius: 8px;
  padding: 14px 14px 0px 14px;
  font-size: 12px;
  overflow: hidden;
  font-weight: 500;
  h4 {
    font-size: 14px;
    color: #19342f;
    line-height: 18px;
    @include apercubold;
  }
  span{
    font-size: 10px;
    color: #506d85;
    line-height: 14px;
    display: block;
    margin-top: 18px;
    text-transform: uppercase;
    padding-bottom: 5px;
  }  
  a {
    color: #ff8b6c;
  }
  .phone {
    color: #19342f;
  }
}

@media screen and (min-width: 320px) and (max-width: 980px) {
  .search_pharmacy_popup.signature .search_pharmacy_select {
    margin-top: 60px;
  }
  .loginPage .login-section .btn-section {
    padding-bottom: 20px;
  }
  .forgot-access {
    width: 100%;
  }
  .caller-section .form-control {
    width: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .welcome-section.download-edit h2 {
    margin-top: 0px;
  }
  .welcome-section.download-edit {
    min-height: 320px;
  }
  .enrollparent.past .enroll-content {
    height: 100%;
    overflow: auto;
  }
  .enrollparent.past .enroll-content table {
    min-width: 700px;
  }
  .enrollparent.past .enroll-content::-webkit-scrollbar {
    height: 4px;
  }
  .enrollparent.past .enroll-content::-webkit-scrollbar-thumb {
    background: #2a524b;
  }
  .caller-section .past_enroll .btnshover,
  .caller-section .past_enroll .btnshover.resend.animate,
  .caller-section .past_enroll .btnshover.resend {
    width: 100%;
  }
  .caller-section .past_enroll .formsec > .form-group.full {
    width: 100%;
  }
  .caller-section .past_enroll .formsec > .form-group.full .adjust {
    width: 100%;
  }
  .enrollparent.past .header th {
    line-height: 22px;
  }
  .enrollparent.past tr td {
    line-height: 22px;
    padding-bottom: 4px;
  }
  /*
  .enrollparent.past tbody tr {
    padding-top: 10px;
  }
  .enrollparent.past tr td.first,.enrollparent.past tr td.third, .enrollparent.past tr td.second {
    width: 30%;
  }
  .enrollparent.past tr th.fourth {
    width: 18%;
  }
  .enrollparent.past tr td.fourth {
    width: 48%;
  }
  .enrollparent.past tr td.fifth {
    width: 36%;
  }
  .enrollparent.past tr td.sixth {
    width: 16%;
  }
  */
  .enrollparent.past,
  .enrollparent.past .inner-width {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }
  .search_pharmacy_popup.signature .search_pharmacy_select {
    margin-top: 0px;
  }
  .search_pharmacy_popup.signature .search_pharmacy_select {
    max-width: 350px;
    max-height: 795px;
  }
  .white-bg {
    margin-top: 10px;
  }
  .search_pharmacy_popup.signature.past_enroll .search_pharmacy_select {
    min-width: 90%;
  }
  .past_enroll .formsec .note {
    margin-top: 30px;
  }
}
.navbar li.agent,
.mobMenu nav ul li.agent {
  background: #ff8b6c;
  border-radius: 20.5px;
  min-height: 45px;
  max-height: 45px;
  font-family: "Apercu-Bold" !important;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.14px;
  margin-left: 30px;
}
.navbar li.agent a,
.mobMenu nav ul li.agent a {
  color: #fff;
}
.mobMenu nav ul li.agent {
  min-width: 160px;
  margin: 0 auto 20px auto;
  padding: 0;
  line-height: 46px;
}
.mobMenu nav ul li.agent a {
  color: #fff !important;
  margin-bottom: 0px !important;
}
.pharmacyPage .container .pharmacy_box .pharmacy_list .discount_card .pharmacyDetail .sendbtn.btnsnd {
  margin-bottom: 20px;
}
.pharmacyPage .container .pharmacy_box .pharmacy_list .discount_card .pharmacyDetail .save {
  margin-bottom: 20px;
}
.pharmacyPage .container .pharmacy_box .pharmacy_list .discount_card .pharmacyDetail .sendbtn.printbtn {
  margin-bottom: 25px;
}

/* #461 css */
.pharmacyPage .container .pharmacy_box .pharmacy_list span.saving-shown {
  position: absolute;
  z-index: 99;
  bottom: 2px;
  width: 307px;
  font-size: 14px !important;
}
.pharmacyPage .container .pharmacy_box .pharmacy_list span.saving-shown.fullscreen {
  bottom: -54px;
}
.pharmacyPage .container .pharmacy_box .pharmacy_list .card .name_Sec span.discount_Sec {
  font-size: 12px !important;
  color: #44b672;
  @include apercubold;
  float: left;
  margin-right: 12px;
}
.pharmacyPage .container .pharmacy_box .pharmacy_list .discount_Sec {
  font-size: 12px !important;
  color: #44b672;
  @include apercubold;
  float: left;
  margin-right: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.pharmacyPage .container .pharmacy_box .pharmacy_list .discount_Sec span {
  font-size: 12px !important;
  color: #827c77;
  @include apercuregular;
  text-decoration: line-through;
  padding-left: 6px;
}
.pharmacyPage .container .pharmacy_box .pharmacy_list .card .name_Sec span.discount_Sec span {
  font-size: 12px !important;
  color: #827c77;
  @include apercuregular;
  display: block;
  text-decoration: line-through;
}
.pharmacyPage .container .pharmacy_box .pharmacy_list .card .name_Sec p {
  max-width: 195px;
}
.pharmacyPage .discount_card .discount_card_parent .pharmacybox .name_quantity {
  min-width: 58% !important;
  max-width: 58% !important;
}
.pharmacyPage .container .pharmacy_box .pharmacy_list .card .name_Sec span.discount_Sec label {
  display: initial;
  margin-bottom: 0px;
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .pharmacyPage .container .pharmacy_box .pharmacy_list .card .name_Sec p {
    max-width: 175px;
  }
}
@media screen and (min-width: 481px) and (max-width: 1024px) {
  .pharmacyPage .container .pharmacy_box .pharmacy_list span.saving-shown.fullscreen {
    bottom: -70px;
  }
  .pharmacyPage .container .pharmacy_box .pharmacy_list .card .name_Sec p {
    max-width: 0px;
  }
  .pharmacyPage .container .pharmacy_box .pharmacy_list .discount_card .discount_card_parent .pharmacybox .price {
    top: auto !important;
    bottom: 0px;
    margin-right: 10px;
  }
  .pharmacyPage .container .pharmacy_box .pharmacy_list .discount_Sec {
    right: 10px;
  }
  .pharmacyPage .container .pharmacy_box .pharmacy_list.ie_fixed_add_pharmacy_list.smallcard .card .name_Sec p {
    max-width: 195px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .pharmacy_list.ie_fixed_add_pharmacy_list.smallcard {
    margin-bottom: 85px;
  }  
  .pharmacyPage .container .pharmacy_box .pharmacy_list.ie_fixed_add_pharmacy_list.smallcard .card .name_Sec p {
    max-width: 195px;
  }
  app-pharmacy .pharmacyPage .container .pharmacy_box .pharmacylist_parent .map_list_box .pharmacy_list .loadmore {
    bottom: 25px;
  }
  app-pharmacy .pharmacyPage .container .pharmacy_box .pharmacy_list span.saving-shown {
    bottom: 0px!important;
  }
  .footer-section .comman-width .footer-adjust .fourth {
    margin-top: 55px!important;
  }
}
@media screen and (max-width: 480px) {

  .pharmacy_list.ie_fixed_add_pharmacy_list.smallcard {
    margin-bottom: 100px;
  }  
  app-pharmacy .pharmacyPage .container .pharmacy_box .pharmacylist_parent .map_list_box .pharmacy_list .loadmore {
    bottom: 45px;
  }
  .pharmacyPage .container .pharmacy_box .pharmacy_list span.saving-shown.fullscreen {
    bottom: -70px;
  }
  .theme-loader.resend {
    top: 52px;
    left: 30px;
  }
  .pharmacyPage .container .pharmacy_box .pharmacy_list .card .name_Sec p {
    max-width: 175px;
  }
  .pharmacyPage .container .pharmacy_box .pharmacy_list .discount_Sec,
  .pharmacyPage .container .pharmacy_box .pharmacy_list .discount_Sec span {
    font-size: 12px !important;
  }
  .pharmacyPage .container .pharmacy_box .pharmacy_list .discount_card .discount_card_parent .pharmacybox .price {
    top: 15px !important;
    right: 8px;
  }
  .pharmacyPage .container .pharmacy_box .pharmacy_list .discount_Sec {
    margin-right: 8px;
  }
  .caller-section .past_enroll .btnshover.success-btn {
    width: 100%;
  }
  .caller-section .past_enroll .btnshover.success-btn img {
    margin-top: 0px;
  }
}
.question-section u {
  text-decoration: underline;
}
.enrollparent.past tr .first[colspan="6"] {
  width: 100%;
  font-size: 20px;
  padding: 20px 0;
}
.edit-image,
.mail-image {
  position: relative;
}
.edit-image span,
.mail-image span {
  position: absolute;
  bottom: 0;
  background: #fff;
  border: 1px solid #dde5ed;
  // box-shadow: 0 2px 14px rgb(139 166 193 / 15%);
  min-width: -webkit-max-content;
  min-width: max-content;
  font-style: normal;
  padding: 5px 10px;
  border-radius: 4px;
  color: #506d85;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  bottom: -38px;
  right: 0px;
  display: none;
  z-index: 9;
}
.edit-image span:after,
.mail-image span:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  position: absolute;
  top: -3px;
  left: calc(50% - 6px);
  z-index: 999;
  display: none;
}
.edit-image span:before,
.mail-image span:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #dde5ed;
  position: absolute;
  top: -6px;
  left: calc(50% - 6px);
  display: none;
}
.mail-image span {
  right: -24px;
}
.edit-image:hover span,
.mail-image:hover span {
  display: block;
}
.edit-image:hover span::before,
.mail-image:hover span::before {
  display: block;
}
.edit-image:hover span::after,
.mail-image:hover span::after {
  display: block;
}
.enrollparent.past tbody tr:last-child .edit-image:hover span,
.enrollparent.past tbody tr:last-child .mail-image:hover span {
  bottom: auto;
  top: -36px;
}
.enrollparent.past tbody tr:last-child .edit-image span:before,
.enrollparent.past tbody tr:last-child .mail-image span:before {
  top: auto;
  bottom: -6px;
  transform: rotate(180deg);
}
.enrollparent.past tbody tr:last-child .edit-image span::after,
.enrollparent.past tbody tr:last-child .mail-image span::after {
  top: auto;
  bottom: -4px;
  transform: rotate(180deg);
}
.monitor-tag img {
  height: auto!important;
  margin-bottom: 10px;
}
.logo img {
  max-width: 150px!important;
  min-width: 150px!important;
  margin-left: 0px;
}
.header-section.brokerpageheader .logo img {
  max-width: 190px!important;
  min-width: 190px!important;
  margin-left: -42px;
}
.footer-section .comman-width .footerlogo img {
  max-width: 116px;
  min-width: 116px;
  margin-left: 0px;
}
.signupbox .Signup_login .form_area .signup_signin_parent_box img {
  margin-left: 0px;
  max-width: 130px;
}
.signupbox .Signup_login .form_area .signup_signin_parent_box .signup_parent .Login_Signup_Error {
  margin-top: 8px;
}
.signupbox.sharepass .Signup_login {
  max-height: 285px!important;
  max-width: 600px!important;
}
.signupbox.sharepass .Signup_login .form_area .signup_signin_parent_box .form_sec .changepassword_main > div .reset_button {
  margin-left: 0px;
  width: 150px;
  min-width: 150px;
}
.pharmacyPage .pharmacy_box .select_filterList_parent .filter_box .type_box input {
  color: #00407e;
}
.firstloadpricing .price_searcharea .medicinenamesearch app-search .form-section .lowest {
  background: #7fc534;
}
.welcome-section::after, .welcome-section.download-edit.aboutbox_header::after{
  content: "";
  width: 100%;
  height: 15px;
  display: flex;
  background: url("assets/images/transition_to_search_section.svg") 0 0 / cover no-repeat;
}
.welcome-section.how-works::after{
  content: "";
  width: 100%;
  height: 0px;
  display: flex;
  background: none;
}
@media screen and (min-width:1201px) and (max-width:1300px) {
  .welcome-center {
    max-width: 470px;
  }
  .welcome-section h2 {
    font-size: 45px;
  }
}