// Importing bootstrap
@import "microsite.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:100,200,300,400,500,600,700,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800&display=swap");
// Reset css

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
}
@mixin apercubold {
  font-family: "Apercu-Bold" !important;
}
@mixin apercuregular {
  font-family: "Apercu-Regular" !important;
}

h4 {
  font-family: "Apercu-Bold" !important;
  font-size: 20px;
}
.link-color {
  color: #506d85 !important;
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.u {
  // text-decoration: underline !important;
}

// colors variable
// $primary: #c90016;
// $primary: $fontColor6;
// $secondary: #ed8808;
// $tertiary: #18342f;
$txt_white: #fff;
$input_text_color: #8ba6c1;
$search_border: #71d2f1;
$greyis: #f5f8fa;
$greyish_text: #506d85;
$signup_heading: #303f4c;
$signup_link: #00b9f5;
$pricing-color: #00b9f5;
$card_border: #ebebeb;
$contactY: #f7b500;
$errorcolor: #f94330;

$primary: #003e80;
// $secondary: #44b672;
// $tertiary: #25534b;
$whitetext: #fff;
$formborder: #00407e;
$pharmacypop: #00407e;
$formgrey: #e5dfdf;
$formtypecolor: #b6aca3;

// Predifined css property
@mixin font_fira {
  $fontFamily: "Fira Sans", sans-serif;
}
@mixin font_fira_size($size, $color_text, $line_height, $weight) {
  $fontFamily: "Fira Sans", sans-serif;
  font-size: $size;
  color: $color_text;
  line-height: $line_height;
  font-weight: $weight;
}
@mixin text_decoration {
  text-decoration: none;
}
@mixin align_item_center {
  align-items: center;
}
@mixin font14 {
  font-size: 14px;
}
@mixin normal_line_height {
  line-height: normal;
}
@mixin relative_position {
  position: relative;
}
@mixin absolute_position {
  position: absolute;
}
@mixin absolute_position_value($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin width($property) {
  width: $property;
}
@mixin height($property) {
  height: $property;
}
@mixin fontsize($property) {
  font-size: $property;
}
@mixin radius($property) {
  border-radius: $property;
}
@mixin pointer {
  cursor: pointer;
}
@mixin flex_property($display, $flow, $alignment, $justify) {
  display: $display;
  flex-flow: $flow;
  align-items: $alignment;
  justify-content: $justify;
}
@mixin width_height_max_width_max_height($width, $height, $max_width, $max_height) {
  width: $width;
  height: $height;
  max-width: $max_width;
  max-height: $max_height;
}
@mixin default_input_style {
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
}
@mixin pad_mar_0 {
  padding: 0;
  margin: 0;
}
@mixin fixedposition {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@mixin viewportheight {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
header {
  height: auto;
  min-height: 72px;
  display: flex;
  @include align_item_center;
  background-color: #ffffff;
  flex-flow: column;
  justify-content: center;
  box-shadow: 0 10px 30px 0 rgba(25, 52, 47, 0.09) !important;
  .header {
    // width: 100%;
    .logo {
      @include font_fira;
      font-size: 36px;
      font-weight: 800;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      font-weight: 800;
      color: $primary;
      display: flex;
      @include pointer;
      img {
        max-height: 50px;
      }
      span {
        color: $secondary;
      }
    }
    .headermenu {
      display: flex;
      @include align_item_center;
      height: 100%;
      justify-content: flex-end;
      @include relative_position;
      @include font_fira;
      padding-left: 25px;
      > div {
        display: flex;
        @include align_item_center;
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
        &.userArea {
          margin-right: 0;
          i {
            margin-right: 0;
          }
        }
      }
      a {
        color: $tertiary;
        @include text_decoration;
        display: flex;
        @include align_item_center;
        @include font14;
      }
      i {
        margin-right: 5px;
      }
      i.dowmload_icon {
        background: url(assets/images/download_header.svg);
        @include width(30px);
        @include height(30px);
      }
      i.mobile_icon {
        background: url(assets/images/call-icon.svg);
        width: 30px;
        height: 30px;
      }
      .sigbup_btn a {
        width: 87px;
        height: 34px;
        border-radius: 4px;
        background: $primary;
        color: $txt_white;
        justify-content: center;
        @include font14;
      }
      .login_btn a {
        color: $primary;
      }
      .headersearch {
        flex-basis: 496px;
        max-width: 496px;
        @include relative_position;
        i {
          @include absolute_position_value(50%, auto, auto, 18px);
          transform: translate(0, -50%);
          background: url(assets/images/search.svg) no-repeat;
          @include width_height_max_width_max_height(22px, 22px, 22px, 22px);
          background-size: 100%;
        }
        input[type="search"] {
          box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
          border: solid 1px $primary;
          background-color: $txt_white;
          @include radius(6px);
          width: 100%;
          outline: none;
          height: 41px;
          padding-left: 50px;
          @include font_fira_size(14px, $input_text_color, normal, normal);
        }
      }
      .userArea {
        padding-top: 4px;
        cursor: pointer;
        @include apercubold;
        font-size: 16px;
        font-weight: normal;
        color: $tertiary;

        span {
          text-decoration: underline;
          text-transform: lowercase;
          &::first-line {
            text-transform: capitalize;
          }
        }
        &::after {
          content: "";
          width: 13px;
          height: 13px;
          display: flex;
          background: url(assets/images/header_down.svg) no-repeat;
          margin-left: 10px;
        }
        i {
          @include width_height_max_width_max_height(25px, 25px, 25px, 25px);
          background: url(assets/images/user_icon.svg) no-repeat;
          @include pointer;
        }
      }
      .menu_main {
        @include width_height_max_width_max_height(100%, 380px, 322px, 380px);

        border: none;
        background-color: $txt_white;
        @include flex_property(flex, column, flex-start, flex-start);
        @include absolute_position_value(calc(100% + -10px), -30px, auto, auto);
        @include radius(6px);
        padding-left: 30px;
        z-index: 99999;
        min-width: 310px;
        top: 89px;
        box-shadow: 0 10px 30px 0 rgba(25, 52, 47, 0.09);
        border-radius: 0;
        padding: 50px;
        right: 0;
        // &:before {
        //   content: "";
        //   @include width_height_max_width_max_height(14px, 14px, 14px, 14px);
        //   background: $txt_white;
        //   border: 1px solid rgba(80, 109, 133, 0.42);
        //   @include absolute_position_value(-8px, 34px, auto, auto);
        //   transform: rotate(45deg);
        //   border-bottom: none;
        //   border-right: none;
        // }
        p {
          @include pad_mar_0;
          text-transform: capitalize;
          margin-top: 0;
          @include apercuregular;
          font-size: 16px;
          color: $secondary;
          letter-spacing: -0.5px;
        }
        ul {
          padding-top: 0;
          width: 100%;
          li {
            list-style: none;
            padding-left: 0;
            color: #25534b;
            @include relative_position;
            @include pointer;
            line-height: 30px;
            @include apercuregular;
            font-size: 18px;
            letter-spacing: -0.51px;
            margin-bottom: 10px;
            border-bottom: 1px solid $formgrey;
            padding-bottom: 10px;
            &:last-child {
              border: none;
            }
            i {
              display: none;
              @include absolute_position_value(50%, auto, auto, 0);
              background-size: 100% !important;
              transform: translate(0, -50%);
            }
            &.closememu {
              display: flex;
              align-items: center;
              @include apercubold;
              color: $secondary;
              img {
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .container {
      max-width: 100%;
      .row {
        flex-flow: row;
      }
    }
  }
}
.homepage {
  background: #5ed4fa url(assets/images/landing-page-bg.webp) no-repeat;
  @include width(100%);
  background-size: cover;
  height: calc(100vh - 72px);
  .container {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    .searchbox_home_parent {
      position: absolute;
      top: 50%;
      @include width(100%);
      max-width: 600px;
      transform: translate(0, -50%);
      h1 {
        @include font_fira;
        color: $txt_white;
        font-size: 36px;
      }
      .search_steps {
        display: flex;
        justify-content: space-between;
        > div {
          position: relative;
          display: flex;
          align-items: center;
          &:after {
            content: "";
            @include absolute_position_value(50%, 25px, auto, auto);
            width: 1px;
            height: 46px;
            transform: translate(0, -50%);
            background: $txt_white;
          }
          &:first-child {
            flex-basis: 35%;
            max-width: 35%;
          }
          &:nth-child(2) {
            flex-basis: 27%;
            max-width: 27%;
          }
          &:last-child {
            flex-basis: 38%;
            max-width: 38%;
            &:after {
              display: none;
            }
          }
          span {
            @include font_fira;
            font-weight: 800;
            color: $txt_white;
            font-size: 18px;
            @include normal_line_height;
            &:first-child {
              font-size: 68px;
              margin-right: 12px;
            }
          }
        }
      }
      .searchbox_home {
        margin-top: 20px;
        @include relative_position;
        .searchbox_box {
          @include relative_position;
          .cancelbtn {
            display: none;
          }
          input[type="text"] {
            box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
            border: solid 1px #71d2f1;
            background-color: #ffffff;
            @include width(100%);
            @include height(50px);
            outline: none;
            border-radius: 6px;
            line-height: 50px;
            @include font_fira;
            font-size: 16px;
            color: $input_text_color;
            font-weight: normal;
            padding-left: 60px;
          }
          i {
            @include absolute_position;
            background: url(assets/images/search.svg) no-repeat;
            display: block;
            @include width_height_max_width_max_height(22px, 22px, 100%, 100%);
            top: 50%;
            transform: translate(0, -50%);
            left: 20px;
          }
        }
        .serachlist {
          box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
          border: solid 1px $search_border;
          background-color: $txt_white;
          @include radius(6px);
          @include absolute_position_value(50px, 0, auto, 0);
          @include width_height_max_width_max_height(100%, 100%, 100%, 315px);
          overflow-y: auto;
          min-height: 315px;
          .recent_sesrch,
          .popular_sesrch {
            .title {
              background: $greyis;
              padding-left: 26px;
              padding-right: 26px;
              @include font_fira_size(12px, $greyish_text, 30px, 500);
            }
            ul {
              li {
                @include font_fira_size(16px, $tertiary, 40px, 600);
                @include pointer;
                padding-left: 26px;
                padding-right: 26px;
                &:hover {
                  background: $primary;
                  color: $txt_white;
                }
              }
            }
          }
        }
      }
      .search_btn {
        @include width(235px);
        @include height(50px);
        border-radius: 4px;
        background: $secondary;
        margin-top: 25px;
        @include pointer;
        @include font_fira;
        @include fontsize(16px);
        color: $txt_white;
        @include flex_property(flex, column, center, center);
      }
    }
  }
  .search_pharmacy_popup {
    @include fixedposition;
    z-index: 9999;
    background: rgba(1,37,72,.97);
    .close_search_pharmacy_popup {
      @include width_height_max_width_max_height(25px, 25px, 25px, 25px);
      background: url(assets/images/pharmacy_close.svg) no-repeat;
      @include pointer;
      @include absolute_position_value(20px, 20px, auto, auto);
    }
    .search_pharmacy_select {
      @include absolute_position_value(50%, 0, auto, 0);
      margin: 0 auto;
      @include width_height_max_width_max_height(100%, 601px, 496px, 601px);
      background: $txt_white;
      @include radius(8px);
      box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
      transform: translate(0, -50%);
      padding: 32px;
      overflow: visible !important;
      h2 {
        @include font_fira_size(24px, $tertiary, normal, 700);
        margin: 0;
      }
      .filter_box {
        margin-top: 35px;
        .lists_box {
          @include relative_position;
          margin-bottom: 25px;
          > div:first-child {
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
            border: solid 1px $input_text_color;
            background-color: $txt_white;
            @include radius(4px);
            height: 52px;
            @include pointer;
            > label {
              @include absolute_position_value(-7px, auto, auto, 15px);
              background: $txt_white;
              padding-left: 5px;
              padding-right: 5px;
              @include font_fira_size(12px, $greyish_text, normal, 500);
            }
            > div {
              @include font_fira_size(14px, $tertiary, 52px, 500);
              padding-left: 20px;
              input {
                @include default_input_style;
                @include font_fira_size(14px, $tertiary, 52px, 500);
              }
            }
            > i {
              @include width_height_max_width_max_height(24px, 24px, 24px, 24px);
              background: url("assets/images/ddl.svg") no-repeat;
              display: block;
              @include absolute_position;
              top: 50%;
              transform: translate(0, -50%);
              right: 15px;
            }
          }
          .lists {
            @include fixedposition;
            background: rgba(29, 37, 45, 0.9);
            z-index: 9;

            ul {
              background: $txt_white;
              @include absolute_position_value(50%, 0, auto, 0);
              transform: translate(0, -50%);
              @include width_height_max_width_max_height(100%, auto, 75%, 250px);
              overflow-y: auto;
              padding: 15px;
              margin: 0 auto;
              @include radius(6px);
              li {
                @include font_fira_size(14px, $tertiary, 35px, 500);
                @include pointer;
                border-bottom: 1px solid $greyis;
                &.active {
                  color: $primary;
                }
                &:last-child {
                  border: none;
                }
              }
            }
          }
        }
        .autopic_location {
          input[type="checkbox"] {
            display: none;
          }
          label {
            @include relative_position;
            padding-left: 26px;
            margin: 0;
            @include font_fira_size(12px, $greyish_text, normal, 500);
            @include pointer;
            span {
              @include absolute_position_value(50%, auto, auto, 0);
              @include width_height_max_width_max_height(16px, 16px, 16px, 16px);
              transform: translate(0, -50%);
              border: 1px solid #5ed4fa;
            }
          }
          input[type="checkbox"]:checked + label {
            span {
              background: url(assets/images/pick-location_search.svg) no-repeat;
              border: none;
              background-size: 100%;
            }
          }
        }
        .find_button {
          background: $primary;
          @include width_height_max_width_max_height(100%, 40px, 227px, 40px);
          @include font_fira_size(16px, $txt_white, 40px, 600);
          @include radius(4px);
          margin-top: 20px;
          text-align: center;
          @include pointer;
        }
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1260px) {
    background-position: -180px;
    .container {
      .searchbox_home_parent {
        top: 40%;
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    background-position: -200px;
    position: relative;
    .container {
      max-width: 100%;
      .searchbox_home_parent {
        top: 50px;
        transform: none;
        max-width: 55%;
        .search_steps {
          flex-flow: column;
          > div {
            max-width: 100% !important;
            flex-basis: auto !important;
            span {
              &:first-child {
                font-size: 40px;
              }
            }
            br {
              display: none;
            }
            &::after {
              display: none;
            }
          }
        }
        .searchbox_home {
          .serachlist {
            border: none;
          }
        }
      }
    }
  }
}

.signupbox {
  @include fixedposition;
  z-index: 9999;
  background: rgba(29, 37, 45, 0.9);
  @media screen and (min-width: 0) and (max-width: 768px) {
    height: -webkit-fill-available;
    .Signup_login {
      .form_area {
        .tncbox {
          margin-top: 15px !important;
        }
      }
    }
  }
  .close_search_pharmacy_popup {
    @include width_height_max_width_max_height(36px, 36px, 36px, 36px);
    background: url(assets/images/pharmacy_close.svg);
    @include pointer;
    @include absolute_position_value(20px, 20px, auto, auto);
  }
  .Signup_login {
    @include absolute_position_value(50%, 0, auto, 0);
    margin: 0 auto;
    @include width_height_max_width_max_height(100%, 520px, 727px, 520px);
    background: $txt_white;
    @include radius(8px);
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
    transform: translate(0, -50%);
    padding-right: 52px;
    @include flex_property(flex, row, flex-start, center);
    .background_area {
      background: url(assets/images/signup_bg.webp) no-repeat;
      display: flex;
      flex-basis: 279px;
      max-width: 279px;
      min-width: 279px;
      height: 100%;
      align-items: flex-start;
      flex-flow: column;
      padding-left: 30px;
      h2 {
        padding-top: 85px;
        @include font_fira_size(36px, $txt_white, normal, 800);
      }
      p {
        max-width: 165px;
        min-width: 165px;
        @include font_fira_size(18px, $signup_heading, 1.5, normal);
      }
    }
    .form_area {
      display: flex;
      flex-basis: calc(100% - 279px);
      max-width: calc(100% - 279px);
      min-width: calc(100% - 279px);
      height: 100%;
      flex-flow: column;
      padding-top: 40px;
      padding-left: 32px;
      overflow-y: auto;
      > nav {
        a {
          @include font_fira_size(16px, lighten($tertiary, 60), normal, normal);
          text-decoration: none;
          margin-right: 30px;
          padding: 0 7px 7px 7px;
          @include relative_position;
          &.active {
            color: $tertiary;
            font-weight: 700;
            &:before {
              content: "";
              @include absolute_position_value(auto, 0, 0, 0);
              height: 3px;
              border-radius: 20px;
              background-color: $primary;
            }
          }
        }
      }
      .skip_btn {
        @include absolute_position_value(21px, 21px, auto, auto);
        @include font_fira_size(12px, $greyish_text, normal, 600);
        @include pointer;
      }
      .form_sec {
        margin-top: 30px;
        h2 {
          @include font_fira_size(24px, $tertiary, normal, 700);
          margin-bottom: 50px;
        }
        .field {
          display: block;
          max-height: 50px;
          position: relative;
          margin-bottom: 20px;
        }

        label,
        input {
          transition: all 0.2s;
          touch-action: manipulation;
        }

        input {
          font-family: inherit;
          -webkit-appearance: none;
          border-radius: 0;
          padding: 0;
          cursor: text;
          border-radius: 4px;
          box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
          border: solid 1px #dde5ed;
          background-color: $txt_white;
          height: 40px;
          @include font_fira_size(14px, $tertiary, normal, 500);
          padding-left: 20px;
          width: 100%;
        }

        input:focus {
          outline: 0;
          border-color: $primary;
        }

        label {
          @include font_fira_size(12px, $greyish_text, normal, 500);
          margin: 0;
          padding: 0;
          margin-left: 16px;
          padding-left: 5px;
          padding-right: 5px;
          background: $txt_white;
          width: auto;
          @include absolute_position_value(0, auto, auto, 0);
        }

        input:placeholder-shown + label {
          cursor: text;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left bottom;
          transform: translate(0, 13px);
        }

        ::-webkit-input-placeholder {
          opacity: 0;
          transition: inherit;
        }

        input:focus::-webkit-input-placeholder {
          opacity: 1;
        }

        input:not(:placeholder-shown) + label,
        input:focus + label {
          transform: translate(0, -7px) scale(1);
          cursor: pointer;
          color: $greyish_text;
        }
        input:focus + label {
          color: $primary;
        }
      }
      a.forgot_btn {
        display: flex;
        margin-left: auto;
        max-width: 120px;
        margin-bottom: 50px;
        text-decoration: none;
        @include font_fira_size(14px, $greyish_text, normal, 600);
        justify-content: flex-end;
      }
      .signup_btn_sec {
        @include flex_property(flex, row, center, flex-start);
        .btns {
          @include font_fira_size(16px, $txt_white, 48px, 600);
          @include width_height_max_width_max_height(135px, 48px, 135px, 48px);
          background: $secondary;
          @include radius(6px);
          text-align: center;
          @include pointer;
        }
        .already {
          margin-left: auto;
          max-width: 250px;
          @include flex_property(flex, column, flex-end, flex-end);
          @include font_fira_size(14px, $greyish_text, normal, 600);
          text-align: right;
          a {
            text-decoration: none;
            color: $signup_link;
          }
        }
      }
      .tncbox {
        margin-top: 35px;
        @include font_fira_size(11px, $input_text_color, normal, normal);
        a {
          text-decoration: none;
          color: $signup_link;
        }
      }
    }
  }
}
.Login_Signup_Error {
  font-family: "Fira Sans";
  font-size: 14px;
  color: #f94330;
  font-weight: 600;
}
.pharmacyPage {
  height: calc(100vh - 72px);
  min-height: 635px;
  background: $greyis;
  .pharmacy_header {
    @include flex_property(flex, row, center, flex-start);
    height: 62px;
    background: $txt_white;
    box-shadow: 0 1px 3px 0 rgba(36, 39, 44, 0.1);
    .container {
      @include flex_property(flex, row, center, flex-start);
      h2 {
        @include pad_mar_0;
        @include font_fira_size(24px, $tertiary, normal, 500);
      }
      .right_btn_sec {
        margin-left: auto;
        display: flex;
        @include relative_position;
        .map_btn {
          display: flex;
          align-items: center;
          @include font_fira_size(16px, $greyish_text, normal, normal);
          i {
            @include width_height_max_width_max_height(20px, 18px, 20px, 18px);
            background: url(assets/images/location.svg) no-repeat;
            background-size: 100%;
            margin-top: -2px;
          }
          input[type="checkbox"] {
            display: none;
            & + label {
              display: flex;
              @include pad_mar_0;
              margin-left: 8px;
              @include pointer;
              i {
                @include width_height_max_width_max_height(18px, 18px, 18px, 18px);
                background: url(assets/images/unchecked.svg) no-repeat;
                background-size: 100% !important;
              }
            }
            &:checked {
              & + label {
                i {
                  background: url(assets/images/checked.svg) no-repeat;
                }
              }
            }
          }
        }
        .sortby {
          @include flex_property(flex, row, center, center);
          @include font_fira_size(16px, $greyish_text, normal, normal);
          margin-left: 40px;
          @include relative_position;
          @include pointer;
          i {
            @include width_height_max_width_max_height(20px, 20px, 20px, 20px);
            background: url(assets/images/ddl.svg) no-repeat;
            margin-left: 10px;
          }
        }
        .sortby_ddl {
          @include width_height_max_width_max_height(180px, 115px, 310px, 115px);
          box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.05), 0 9px 24px 0 rgba(0, 0, 0, 0.2);
          border: solid 1px rgba(80, 109, 133, 0.42);
          background-color: $txt_white;
          @include flex_property(flex, column, flex-start, flex-start);
          @include absolute_position_value(calc(100% + 15px), 0, auto, auto);
          @include radius(6px);
          padding-left: 10px;
          z-index: 9;
          &:before {
            content: "";
            @include width_height_max_width_max_height(14px, 14px, 14px, 14px);
            background: $txt_white;
            border: 1px solid rgba(80, 109, 133, 0.42);
            @include absolute_position_value(-8px, 10px, auto, auto);
            transform: rotate(45deg);
            border-bottom: none;
            border-right: none;
          }
          ul {
            li {
              list-style: none;
              @include font_fira_size(16px, $tertiary, 40px, normal);
              @include relative_position;
              @include pointer;
              i {
                @include absolute_position_value(50%, auto, auto, 0);
                background-size: 100% !important;
                transform: translate(0, -50%);
              }
              &:first-child {
                margin-top: 20px;
              }
              &.active {
                color: $primary;
              }
            }
          }
        }
      }
    }
  }
  .pharmacy_box {
    @include flex_property(flex, row, flex-start, flex-start);
    .select_filterList_parent {
      display: flex;
      flex-basis: 212px;
      max-width: 25%;
      @include flex_property(flex, column, flex-start, flex-start);
      min-width: 212px;
      .filter_box {
        display: flex;
        flex-flow: column;
        flex-basis: 100%;
        width: 100%;
        label {
          @include pad_mar_0;
          @include font_fira_size(12px, $input_text_color, normal, 500);
          text-transform: uppercase;
          padding-top: 10px;
        }
        .type_box {
          padding-bottom: 15px;
          @include font_fira_size(16px, $tertiary, normal, normal);
          border-bottom: 1px solid lighten($greyish_text, 50);
          padding-top: 5px;
          @include pointer;
          @include relative_position;
          &:before {
            content: "";
            width: 20px;
            height: 20px;
            background: url(assets/images/ddl.svg) no-repeat;
            @include absolute_position_value(3px, 0, auto, auto);
          }
          input {
            width: 100%;
            height: 30px;
            background: transparent;
            outline: none;
            border: none;
          }
          @media (min-width: 1025px) {
            input {
              width: 95% !important;
            }
          }
        }
        &:last-child {
          .type_box {
            border-bottom: none;
          }
        }
      }
      .update_btn {
        @include width_height_max_width_max_height(185px, 48px, 185px, 48px);
        background: $secondary;
        @include radius(4px);
        @include flex_property(flex, row, center, center);
        @include font_fira_size(16px, $txt_white, normal, 600);
        margin-top: 50px;
        @include pointer;
        // transition: font-size 0.1s ease;
        overflow: hidden;
        &.loader {
          font-size: 200px;
          transition: font-size 1s ease;
          pointer-events: none;
          &:before {
            content: "";
            border: 4px solid rgba(255, 255, 255, 0.25);
            border-radius: 50%;
            border-top: 4px solid $txt_white;
            width: 35px;
            height: 35px;
            -webkit-animation: spin 0.5s linear infinite; /* Safari */
            animation: spin 0.5s linear infinite;
            position: absolute;
          }
        }
        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
      .type_list {
        display: flex;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.5);
        @include relative_position;
        top: -1px;
        display: none;
        ul {
          padding-left: 15px;
          padding-top: 10px;
          padding-bottom: 10px;
          overflow-y: overlay;
          max-height: 137px;
          &:hover::-webkit-scrollbar {
            display: initial;
          }

          &::-webkit-scrollbar {
            display: none;
          }
          /* custom webkit scrollbars */
          &::-webkit-scrollbar {
            width: 10px;
          }

          &::-webkit-scrollbar-track {
            background-color: hsla(0, 0%, 100%, 0);
            border-width: 0;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 50px;
            background-color: $primary;
            border-style: solid;
            border-color: transparent;
            border-width: 3px;
            background-clip: padding-box;
          }
          /* hidden elements */
          &::-webkit-scrollbar-button,
          &::-webkit-scrollbar-track-piece,
          &::-webkit-scrollbar-corner,
          &::-webkit-resizer {
            display: none;
          }
          li {
            @include pointer;
            @include font_fira_size(14px, lighten($tertiary, 20), 30px, normal);
            border-bottom: 1px solid rgba(204, 204, 204, 0.3);
            &:last-child {
              border-bottom: none;
            }
            &.active {
              color: $primary;
            }
          }
        }
      }
    }
    .pharmacy_list {
      display: flex;
      flex-flow: column;
      margin-left: 50px;
      flex-basis: 100%;
      max-width: 100%;
      margin-top: 8px;
      padding-right: 10px;
      .card {
        display: flex;
        flex-flow: column;
        @include radius(8px);
        box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
        background-color: $txt_white;
        padding: 10px;
        padding-left: 40px;
        padding-top: 14px;
        @include relative_position;
        margin-bottom: 8px;
        @include pointer;
        max-width: 100%;
        min-height: 80px;
        &:before {
          content: "";
          width: 4px;
          @include absolute_position_value(16px, auto, 16px, 16px);
          @include radius(4px);
          background: $primary;
        }

        .name_Sec {
          display: flex;
          flex-basis: 100%;
          max-width: 100%;
          align-items: center;
          p {
            @include pad_mar_0;
            @include font_fira_size(16px, $tertiary, normal, 600);
          }
          span {
            margin-left: auto;
            @include font_fira_size(18px, $pricing-color, normal, 600);
          }
        }
        .distance {
          @include font_fira_size(16px, $input_text_color, normal, normal);
        }
      }
      max-height: calc(100vh - 144px);
      overflow-y: overlay;
      &:hover::-webkit-scrollbar {
        display: initial;
      }

      &::-webkit-scrollbar {
        display: none;
      }
      /* custom webkit scrollbars */
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: hsla(0, 0%, 100%, 0);
        border-width: 0;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: $primary;
        border-style: solid;
        border-color: transparent;
        border-width: 3px;
        background-clip: padding-box;
      }
      /* hidden elements */
      &::-webkit-scrollbar-button,
      &::-webkit-scrollbar-track-piece,
      &::-webkit-scrollbar-corner,
      &::-webkit-resizer {
        display: none;
      }
    }
    .maps_box {
      flex-basis: 100%;
      margin-left: 15px;
      margin-top: 8px;
    }
    .no_pharmacy {
      @include flex_property(flex, column, center, center);
      @include font_fira_size(14px, $input_text_color, normal, normal);
      span {
        margin-top: 15px;
      }
      > span.notxt {
        font-weight: 700;
        font-size: 20px;
        color: $tertiary;
      }
      min-height: calc(100vh - 544px);
    }
  }
  .discount_card {
    @include fixedposition;
    background: rgba(1,37,72,.97);
    z-index: 100000;
    .close_search_pharmacy_popup {
      @include width_height_max_width_max_height(36px, 36px, 36px, 36px);
      background: url(assets/images/pharmacy_close.svg);
      @include pointer;
      @include absolute_position_value(20px, 20px, auto, auto);
    }
    .discount_card_parent {
      @include width_height_max_width_max_height(100%, 667px, 460px, 667px);
      @include absolute_position_value(50%, 0, auto, 0);
      margin: 0 auto;
      background: $txt_white;
      @include radius(8px);
      transform: translate(0, -50%);
      border: 1px solid #000;
      .header {
        display: flex;
        padding: 27px 27px 15px 27px;
        > img {
          max-height: 50px;
        }
        .button_sec {
          margin-left: auto;
          display: flex;
          @include font_fira_size(14px, lighten($tertiary, 30), normal, normal);
          > div {
            margin-right: 25px;
            @include pointer;
            background-size: 20px !important;
            padding-left: 30px;
            &:last-child {
              margin-right: 0;
            }
            &.sendsms {
              background: url(assets/images/email.svg) no-repeat;
            }
            &.save {
              background: url(assets/images/save.svg) no-repeat;
            }
            &.saved {
              background: url(assets/images/saved.svg) no-repeat;
            }
            &.print {
              background: url(assets/images/print.svg) no-repeat;
            }
          }
        }
      }
      .pharmacybox {
        margin-left: 15px;
        margin-right: 15px;
        background: $txt_white;
        border: 1px solid $card_border;
        @include radius(8px);
        padding: 8px;
        display: flex;
        align-items: center;
        @include relative_position;
        .name_quantity {
          padding-left: 12px;
          min-width: 70%;
          max-width: 70%;
          p {
            @include pad_mar_0;
            @include font_fira_size(17px, $tertiary, normal, 600);
          }
          span {
            @include font_fira_size(18px, $input_text_color, normal, normal);
          }
        }
        .price {
          @include font_fira_size(30px, $pricing-color, normal, 600);
          display: flex;
          justify-content: flex-end;
          flex-basis: 30%;
          max-width: 30%;
          word-break: break-all;
        }
        &:before {
          content: "";
          @include absolute_position_value(8px, auto, 8px, 8px);
          background: $primary;
          width: 4px;
          @include radius(4px);
        }
      }
      .pharmacyDetail {
        padding: 30px;
        .name {
          @include font_fira_size(21px, $tertiary, normal, 600);
          padding-bottom: 10px;
        }
        .address {
          @include font_fira_size(18px, $input_text_color, normal, normal);
          padding-bottom: 10px;
        }
        .number {
          @include font_fira_size(24px, $contactY, normal, 600);
          background: url(assets/images/call_pharmacy.svg) no-repeat;
          background-position: 0;
          padding-left: 28px;
          margin-bottom: 10px;
        }
        .method_salt {
          display: flex;
          flex-flow: column;
          background: rgba(196, 212, 229, 0.1);
          .method {
            display: flex;
            height: 38px;
            align-items: center;
            > div {
              &:first-child {
                padding-left: 25px;
                @include font_fira_size(12px, $input_text_color, normal, 500);
                text-transform: uppercase;
                min-width: 40%;
              }
              &:last-child {
                @include font_fira_size(16px, $tertiary, normal, normal);
                text-transform: uppercase;
                min-width: 40%;
              }
            }
          }
        }
        .email_field {
          border: 1px solid rgba(139, 166, 193, 0.9);
          background: $txt_white;
          box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
          @include radius(4px);
          height: 48px;
          display: flex;
          align-items: center;
          padding-left: 20px;
          margin-top: 16px;
          @include relative_position;
          input {
            outline: none;
            border: none;
            @include font_fira_size(14px, $tertiary, normal, 500);
            transition: all 0.2s;
            touch-action: manipulation;
          }
          label {
            position: absolute;
            left: 15px;
            @include font_fira_size(12px, $greyish_text, normal, 500);
            background: $txt_white;
            transition: all 0.2s;
            touch-action: manipulation;
            padding-left: 5px;
            padding-right: 5px;
          }
          input:placeholder-shown + label {
            cursor: text;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transform-origin: left bottom;
            transform: translate(0, 0);
          }

          ::-webkit-input-placeholder {
            opacity: 0;
            transition: inherit;
          }

          input:focus::-webkit-input-placeholder {
            opacity: 1;
          }

          input:not(:placeholder-shown) + label,
          input:focus + label {
            transform: translate(0, -24px) scale(1);
            cursor: pointer;
            color: $greyish_text;
            z-index: 1;
          }
          input:focus + label {
            color: $primary;
          }
          .sendbtn {
            width: 78px;
            height: 40px;
            background: $secondary;
            @include radius(4px);
            display: flex;
            margin-left: auto;
            align-items: center;
            @include font_fira_size(16px, $txt_white, normal, 600);
            justify-content: center;
            margin-right: 4px;
            @include pointer;
            &.success {
              background: $pricing-color;
            }
          }
        }
        .helpline_btn {
          display: flex;
          align-items: center;
          justify-content: space-between;

          margin-top: 20px;
          > div {
            display: flex;
            align-items: center;
            flex-basis: 49%;
            max-width: 49%;
            justify-content: center;
            @include pointer;
            @include font_fira_size(16px, $txt_white, normal, 600);
            height: 48px;
            @include radius(4px);
            &.helpline {
              background: $secondary;
              border: 1px solid $secondary;
            }
            &.direction {
              color: $input_text_color;
              border: 1px solid $input_text_color;
              img {
                pointer-events: none;
                margin-left: 15px;
              }
            }
          }
        }
      }
    }
  }
}
.cardPage {
  .pharmacy_box {
    .pharmacy_list {
      max-width: 905px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .pharmacy_header {
    .header_container.container {
      h2 {
        display: block;
        width: 100%;
      }
    }
  }
}
agm-map {
  height: calc(100vh - 144px);
}
.search_pharmacy_popup {
  @include fixedposition;
  z-index: 9999;
  background: rgba(1,37,72,.97);
  .close_search_pharmacy_popup {
    @include width_height_max_width_max_height(25px, 25px, 25px, 25px);
    background: url(assets/images/pharmacy_close.svg) no-repeat;
    @include pointer;
    @include absolute_position_value(0, 0, auto, auto);
  }
  .search_pharmacy_select {
    @include absolute_position_value(50%, 0, auto, 0);
    margin: 0 auto;
    @include width_height_max_width_max_height(100%, 560px, 407px, 560px);
    background: $txt_white;
    border-radius: 0 100px 0 0;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
    transform: translate(0, -50%);
    padding: 32px;
    padding-top: 45px;
    overflow: visible !important;
    h2 {
      @include apercubold;
      margin: 0;
      text-align: left;
      font-size: 20px;
      color: $pharmacypop;
      line-height: normal;
    }
    .filter_box {
      margin-top: 15px;
      .lists_box {
        @include relative_position;
        margin-bottom: 25px;
        > div:first-child {
          box-shadow: none;
          border-bottom: solid 1px $formgrey;
          background-color: $txt_white;
          @include pointer;
          > label {
            @include apercuregular;
            font-size: 14px;
            letter-spacing: -0.53px;
            color: $formtypecolor;
            margin-bottom: 0;
          }
          > div {
            line-height: 25px;
            font-weight: normal;
            padding-left: 0;
            font-size: 20px;
            @include apercuregular;
            color: $formborder;
            input {
              @include default_input_style;
              font-size: 20px;
              @include apercuregular;
              color: $formborder;
              line-height: 25px;
              font-weight: normal;
              &::placeholder {
                color: $formborder;
              }
            }
          }
          > i {
            @include width_height_max_width_max_height(24px, 24px, 24px, 24px);
            background: url("assets/images/downarrow.svg") no-repeat;
            display: block;
            @include absolute_position;
            bottom: 7px;
            right: 9px;
          }
        }
        .lists {
          @include fixedposition;
          background: rgba(29, 37, 45, 0.9);
          z-index: 9;
          ul {
            background: $txt_white;
            @include absolute_position_value(50%, 0, auto, 0);
            transform: translate(0, -50%);
            @include width_height_max_width_max_height(100%, auto, 75%, 250px);
            overflow-y: auto;
            padding: 15px;
            margin: 0 auto;
            @include radius(6px);
            li {
              @include apercubold;
              font-size: 16px;
              @include pointer;
              color: $formborder;
              border-bottom: 1px solid $formgrey;
              padding-bottom: 8px;
              &.active {
                color: $secondary !important;
              }
              &:last-child {
                border: none;
              }
            }
          }
        }
      }
      .autopic_location {
        input[type="checkbox"] {
          display: none;
        }
        label {
          @include relative_position;
          padding-left: 26px;
          margin: 0;
          @include font_fira_size(12px, $greyish_text, normal, 500);
          @include pointer;
          span {
            @include absolute_position_value(50%, auto, auto, 0);
            @include width_height_max_width_max_height(18px, 26px, 18px, 26px);
            transform: translate(0, -50%);
            background: url(assets/images/form_pinlocation.svg) no-repeat;
          }
        }
        input[type="checkbox"]:checked + label {
          span {
            background: url(assets/images/form_pinlocation.svg) no-repeat;
            border: none;
            background-size: 100%;
          }
        }
      }
      .find_button {
        background: #7fc534;
        @include width_height_max_width_max_height(100%, 45px, 100%, 45px);
        @include apercubold;
        font-size: 16px;
        color: $whitetext;
        @include radius(20.5px);
        margin-top: 40px;
        text-align: center;
        @include pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
@media print {
  .pharmacyPage .discount_card .discount_card_parent .header .button_sec > div {
    display: none;
  }
  .pharmacyPage .discount_card .discount_card_parent .pharmacyDetail .email_field {
    display: none;
  }
  title {
    display: none;
  }
  .error_msg.email_field + span {
    display: none;
  }
  .pharmacyPage #print-section .direction {
    display: none;
  }

  .pharmacyPage .discount_card .discount_card_parent {
    height: 550px;
  }
  @page {
    size: auto;
    margin: 0mm;
  }
}
.change_password {
  @include fixedposition;
  background: rgba(29, 37, 45, 0.9);
  z-index: 9999;
  .close_search_pharmacy_popup {
    @include width_height_max_width_max_height(36px, 36px, 36px, 36px);
    background: url(assets/images/pharmacy_close.svg);
    @include pointer;
    @include absolute_position_value(20px, 20px, auto, auto);
  }
  .changepassword_main {
    @include absolute_position_value(50%, 0, auto, 0);
    margin: 0 auto;
    @include width_height_max_width_max_height(100%, 397px, 496px, 397px);
    background: $txt_white;
    @include radius(8px);
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
    transform: translate(0, -50%);
    padding-right: 52px;
    @include flex_property(flex, column, flex-start, flex-start);
    padding: 24px;
    overflow-y: auto;
    h2 {
      @include font_fira_size(24px, $tertiary, normal, bold);
      margin-top: 0;
      margin-bottom: 0;
    }
    .input_box {
      padding-top: 25px;
      width: 100%;
      > div {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        i {
          width: 24px;
          height: 24px;
          background: url(assets/images/eye-slash-solid.svg) no-repeat;
          // background: url(assets/images/eye.svg) no-repeat;
          @include absolute_position_value(50%, 20px, auto, auto);
          transform: translate(0, -50%);
          @include pointer;
        }
      }
      label,
      input {
        transition: all 0.2s;
        touch-action: manipulation;
      }

      input {
        font-family: inherit;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 0;
        cursor: text;
        border-radius: 4px;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #dde5ed;
        background-color: $txt_white;
        height: 50px;
        @include font_fira_size(14px, $tertiary, normal, 500);
        padding-left: 20px;
        width: 100%;
      }

      input:focus {
        outline: 0;
        border-color: $primary;
      }

      label {
        @include font_fira_size(12px, $greyish_text, normal, 500);
        margin: 0;
        padding: 0;
        margin-left: 16px;
        padding-left: 5px;
        padding-right: 5px;
        background: $txt_white;
        width: auto;
        @include absolute_position_value(0, auto, auto, 0);
      }

      input:placeholder-shown + label {
        cursor: text;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 18px);
      }

      ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
      }

      input:focus::-webkit-input-placeholder {
        opacity: 1;
      }

      input:not(:placeholder-shown) + label,
      input:focus + label {
        transform: translate(0, -7px) scale(1);
        cursor: pointer;
        color: $greyish_text;
      }
      input:focus + label {
        color: $primary;
      }
      .reset_button {
        @include width_height_max_width_max_height(135px, 48px, 135px, 48px);
        @include flex_property(flex, row, center, center);
        @include font_fira_size(16px, $txt_white, normal, 600);
        @include radius(4px);
        background: $secondary;
        @include pointer;
      }
    }
  }
  .forgot_pass {
    max-height: 300px;
    .input_box {
      display: table;
    }
  }
  .group_Number__box {
    .skip_btn {
      @include absolute_position_value(21px, 21px, auto, auto);
      @include font_fira_size(12px, $greyish_text, normal, 600);
      @include pointer;
    }
    .staticgroup {
      @include absolute_position_value(16px, auto, auto, 10px);
      @include font_fira_size(14px, $tertiary, normal, 500);
    }
    .input_box {
      input {
        padding-left: 48px;
      }
      label {
        left: 30px;
      }
      input:not(:placeholder-shown) + label,
      input:focus + label {
        left: 0;
      }
    }
    h2 {
      margin-top: 40px;
    }
  }
  .resetpass_box h2 {
    margin-top: 30px;
  }
}
.error_msg {
  input {
    border-color: $errorcolor !important;
    & + label {
      color: $errorcolor !important;
      transform: translate(0, -7px) scale(1) !important;
    }
  }
  & + span {
    color: $errorcolor;
    top: -12px;
    padding-left: 18px;
    display: block;
    position: relative;
    text-align: left;
    @include font_fira_size(10px, $errorcolor, normal, normal);
    &:before {
      content: "!";
      width: 12px;
      height: 12px;
      border: 1px solid $errorcolor;
      border-radius: 50%;
      font-size: 9px;
      border-radius: 50%;
      @include absolute_position_value(50%, auto, auto, 0);
      transform: translate(0, -50%);
      text-align: center;
    }
  }
  > div {
    border-color: $errorcolor !important;
    label {
      color: $errorcolor !important;
    }
    span {
      color: $errorcolor;
      top: 6px;
      padding-left: 18px;
      display: block;
      position: relative;
      @include font_fira_size(10px, $errorcolor, normal, normal);
      &:before {
        content: "!";
        width: 12px;
        height: 12px;
        border: 1px solid $errorcolor;
        border-radius: 50%;
        font-size: 9px;
        border-radius: 50%;
        @include absolute_position_value(50%, auto, auto, 0);
        transform: translate(0, -50%);
        text-align: center;
      }
    }
  }
  &.email_field {
    label {
      transform: translate(0, -24px) scale(1) !important;
    }
    border-color: $errorcolor !important;
    & + span {
      top: 5px;
    }
  }
}
.Noddl:before {
  display: none !important;
}
.success {
  background: $pricing-color !important;
}
.filterbtn_mobile {
  display: none;
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  header {
    .header {
      .headermenu {
        .menu_main {
          right: -10px;
          &::before {
            right: 15px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 0) and (max-width: 768px) {
  body {
    -webkit-perspective: 1500px;
    perspective: 1500px;
    -webkit-perspective-origin: 0% 50%;
    perspective-origin: 0% 50%;
  }
  header {
    .container {
      max-width: 100%;
      .row {
        flex-flow: row;
        .logoparent {
          max-width: 100px;
        }
        .headermenu {
          > div {
            margin-right: 0;
            &.sigbup_btn {
              a {
                width: auto;
                height: auto;
                background: transparent;
                color: $tertiary;
                font-weight: 600;
                margin-right: 3px;
              }
            }
            &.login_btn {
              a {
                color: $tertiary;
                font-weight: 600;
                margin-left: 3px;
                &::before {
                  content: "/";
                  position: relative;
                  left: -3px;
                }
              }
            }
          }
          .menu_main {
            width: 290px;
            max-width: 290px;
            z-index: 1;
            opacity: 1;
            -webkit-transform: translate3d(0, 0, -1690px);
            transform: translate3d(-290px, 0, -1690px);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;
            visibility: hidden;
            width: 300px;
            height: 100%;
            -webkit-transition: all 0.5s;
            transition: all 0.5s;
            display: block !important;
            max-height: 100%;
            border-radius: 0;
            border: none;
            &:before,
            &:after {
              display: none;
            }
          }

          .st-effect-10 {
            visibility: visible;
            -webkit-transition: -webkit-transform 0.5s;
            transition: transform 0.5s;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }
  .signupbox {
    z-index: 9999;
    .Signup_login {
      flex-flow: column;
      height: 100%;
      max-height: 100%;
      padding-right: 0;
      border-radius: 0;
      background: transparent;
      .background_area {
        flex-basis: auto;
        max-width: 100%;
        min-width: 100%;
        height: 157px;
        background: #00aced;
        align-items: flex-start;
        padding-left: 24px;
        min-height: 157px;
        p {
          max-width: 100%;
          min-width: 100%;
          color: $txt_white;
          br {
            display: none;
          }
        }
        h2 {
          padding-top: 24px;
        }
      }
      .form_area {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding: 26px 24px;
        border-radius: 8px 8px 0 0;
        background: $txt_white;
        min-height: calc(100% - 148px);
        top: -5px;
        position: relative;
        .skip_btn {
          top: auto;
          bottom: 25px;
          right: 24px;
        }
      }
    }
    .close_search_pharmacy_popup {
      z-index: 99;
    }
  }
  .change_password {
    .changepassword_main {
      transform: none;
      top: auto;
      bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      height: 410px;
      max-height: 100%;
      .skip_btn {
        top: auto;
        bottom: 21px;
      }
    }
  }
  #Search_Header {
    padding-top: 10px;
    padding-bottom: 10px;
    .row {
      flex-flow: column;
      .headermenu {
        flex-flow: column;
        .headersearch {
          flex-basis: auto;
          order: 2;
          width: 100%;
          margin-top: 10px;
          max-width: 100%;
          input[type="search"] {
            border-radius: 4px;
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
            border: solid 1px #dde5ed;
            background-color: #ffffff;
            appearance: none;
            -ms-appearance: none;
            -moz-appearance: none;
          }
        }
        .userArea {
          position: absolute;
          top: -35px;
          right: 0;
        }
      }
    }
  }
  .pharmacyPage {
    &.loggedin_header {
      height: calc(100vh - 121px);
    }
    > .container {
      max-width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .pharmacy_header {
      .container {
        max-width: 100%;
        h2 {
          display: none;
        }
        .right_btn_sec {
          margin-left: 0;
          justify-content: space-between;
          width: 100%;
          .map_btn {
            @include relative_position;
            padding-left: 25px;
            @include font_fira_size(12px, $greyish_text, normal, 600);
            > i {
              display: none;
            }
            input[type="checkbox"] {
              & + label {
                position: absolute;
                left: 0;
                margin-left: 0;
              }
            }
          }
          .filterbtn_mobile {
            display: flex;
            align-items: center;
            @include font_fira_size(12px, $greyish_text, normal, 600);
            i {
              background: url(assets/images/filtericon.svg) no-repeat;
              width: 17px;
              height: 17px;
              margin-right: 10px;
            }
          }
          .sortby {
            margin-left: 0;
            @include font_fira_size(12px, $greyish_text, normal, 600);
          }
        }
      }
    }
    .pharmacy_box {
      flex-flow: column;
      .select_filterList_parent {
        display: none;
        &.Show_filter_on_mobile {
          display: flex;
          @include fixedposition;
          max-width: 100%;
          z-index: 9999;
          background: #f5f8fa;
          padding-left: 25px;
          padding-right: 25px;
          padding-top: 50px;
          overflow-y: auto;
          height: -webkit-fill-available;
          > i {
            width: 36px;
            height: 36px;
            max-width: 36px;
            max-height: 36px;
            background: url(assets/images/closeicon.svg);
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
            bottom: auto;
            left: auto;
          }
          > div {
            flex-basis: auto;
          }
        }
      }
      .pharmacy_list {
        margin-left: 0;
        order: 2;
        max-height: calc(100vh - 150px);
        padding-right: 0;
        min-width: 100%;
      }
      .maps_box {
        width: 100%;
        margin-left: 0;
        order: 1;
        max-height: calc(50vh - 100px);
        agm-map {
          height: calc(50vh - 100px);
        }
      }
    }
    .discount_card {
      height: -webkit-fill-available;
      .discount_card_parent {
        height: auto;
        max-height: 100%;
        border-radius: 0;
        top: 0;
        bottom: 0;
        transform: none;
        position: fixed;
        border: none;
        .header {
          padding: 27px 15px 15px 15px;
          .button_sec {
            padding-top: 25px;
          }
        }
        .pharmacyDetail {
          padding: 15px;
          .helpline_btn {
            > div {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
      .close_search_pharmacy_popup {
        z-index: 99;
        top: 0;
        right: 0;
      }
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// top search
.top_search {
  background: #5ed4fa url(assets/images/landing-page-bg.webp) no-repeat;
  @include width(100%);
  background-size: cover;
  .container {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    .searchbox_home_parent {
      position: absolute;
      top: 50%;
      @include width(100%);
      max-width: 600px;
      transform: translate(0, -50%);
      h1 {
        @include font_fira;
        color: $txt_white;
        font-size: 36px;
      }
      .search_steps {
        display: flex;
        justify-content: space-between;
        > div {
          position: relative;
          display: flex;
          align-items: center;
          &:after {
            content: "";
            @include absolute_position_value(50%, 25px, auto, auto);
            width: 1px;
            height: 46px;
            transform: translate(0, -50%);
            background: $txt_white;
          }
          &:first-child {
            flex-basis: 35%;
            max-width: 35%;
          }
          &:nth-child(2) {
            flex-basis: 27%;
            max-width: 27%;
          }
          &:last-child {
            flex-basis: 38%;
            max-width: 38%;
            &:after {
              display: none;
            }
          }
          span {
            @include font_fira;
            font-weight: 800;
            color: $txt_white;
            font-size: 18px;
            @include normal_line_height;
            &:first-child {
              font-size: 68px;
              margin-right: 12px;
            }
          }
        }
      }
      .searchbox_home {
        margin-top: 20px;
        @include relative_position;
        .searchbox_box {
          @include relative_position;
          .cancelbtn {
            display: none;
          }
          input[type="text"] {
            box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
            border: solid 1px #71d2f1;
            background-color: #ffffff;
            @include width(100%);
            @include height(50px);
            outline: none;
            border-radius: 6px;
            line-height: 50px;
            @include font_fira;
            font-size: 16px;
            color: $input_text_color;
            font-weight: normal;
            padding-left: 60px;
          }
          i {
            @include absolute_position;
            background: url(assets/images/search.svg) no-repeat;
            display: block;
            @include width_height_max_width_max_height(22px, 22px, 100%, 100%);
            top: 50%;
            transform: translate(0, -50%);
            left: 20px;
          }
        }
        .serachlist {
          box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
          border: solid 1px $search_border;
          background-color: $txt_white;
          @include radius(6px);
          @include absolute_position_value(50px, 0, auto, 0);
          @include width_height_max_width_max_height(100%, 100%, 100%, 315px);
          overflow-y: auto;
          min-height: 315px;
          .recent_sesrch,
          .popular_sesrch {
            .title {
              background: $greyis;
              padding-left: 26px;
              padding-right: 26px;
              @include font_fira_size(12px, $greyish_text, 30px, 500);
            }
            ul {
              li {
                @include font_fira_size(16px, $tertiary, 40px, 600);
                @include pointer;
                padding-left: 26px;
                padding-right: 26px;
                &:hover {
                  background: $primary;
                  color: $txt_white;
                }
              }
            }
          }
        }
      }
      .search_btn {
        @include width(235px);
        @include height(50px);
        border-radius: 4px;
        background: $secondary;
        margin-top: 25px;
        @include pointer;
        @include font_fira;
        @include fontsize(16px);
        color: $txt_white;
        @include flex_property(flex, column, center, center);
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1260px) {
    background-position: -180px;
    .container {
      .searchbox_home_parent {
        top: 40%;
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    background-position: -200px;
    position: relative;
    .container {
      max-width: 100%;
      .searchbox_home_parent {
        top: 50px;
        transform: none;
        max-width: 55%;
        .search_steps {
          flex-flow: column;
          > div {
            max-width: 100% !important;
            flex-basis: auto !important;
            span {
              &:first-child {
                font-size: 40px;
              }
            }
            br {
              display: none;
            }
            &::after {
              display: none;
            }
          }
        }
        .searchbox_home {
          .serachlist {
          }
        }
      }
    }
  }
}

.headersearch {
  .serachlist {
    z-index: 2;
    box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px $search_border;
    background-color: $txt_white;
    @include radius(6px);
    @include absolute_position_value(50px, 0, auto, 0);
    @include width_height_max_width_max_height(100%, 100%, 100%, 315px);
    overflow-y: auto;
    min-height: 315px;
    .recent_sesrch,
    .popular_sesrch {
      .title {
        background: $greyis;
        padding-left: 26px;
        padding-right: 26px;
        @include font_fira_size(12px, $greyish_text, 30px, 500);
      }
      ul {
        li {
          @include font_fira_size(16px, $tertiary, 40px, 600);
          @include pointer;
          padding-left: 26px;
          padding-right: 26px;
          &:hover {
            background: $primary;
            color: $txt_white;
          }
        }
      }
    }
  }
}
.logoarea {
  display: flex;
  padding-bottom: 18px;
  margin-left: 15px;
  margin-right: 15px;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    width: 50%;
    &:last-child {
      flex-flow: column;
      flex-flow: column;
      align-items: flex-end;

      span {
        margin-bottom: 5px;
        @include font_fira_size(11px, $tertiary, normal, 500);
      }
      &.walmart_img {
        span {
          padding-right: 20px;
        }
      }
    }
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-ms-clear {
  -webkit-appearance: none;
}
// browser issuse fix in ie
@media all and (-ms-high-contrast: none) {
  .homepage {
    .container {
      display: -ms-flexbox;
      justify-content: center;
    }
  }
  .email_field {
    label {
      margin-top: -30px;
    }
    input {
      width: 75%;
    }
  }
  // mycard in use
  .lists ul {
    max-width: 31% !important;
  }
  .search_pharmacy_select .lists ul {
    max-width: 20% !important;
  }
  // pharmacy use
  .pharmacyPage {
    min-height: 635px;
    .pharmacy_box {
      .select_filterList_parent {
        display: block;
      }
    }
  }
  .filter_box {
    display: block;
  }
  .type_list ul {
    overflow: hidden;
    overflow-y: scroll;
  }
  .update_btn.loader {
    position: relative;
    color: $secondary !important;
  }

  .loader {
    &::before {
      top: 6px;
      left: 40%;
    }
  }
  .error_msg > div label {
    top: -7px !important;
  }

  // fixed map position
  .ie_fixed_no_result {
    margin-top: 150px !important;
    margin-left: -13% !important;
  }
  #Signin_Signup_Header {
    background: #ffffff;
  }
  .ie_fixed_header {
    position: fixed;
    z-index: 3;
    width: 100%;
  }

  .ie_f_pharmacy_header {
    position: fixed;
    width: 100%;
    top: 72px;
    z-index: 10;
  }

  .ie_f_select_filterList_parent {
    position: fixed;
    top: 134px;
    z-index: 4;
  }
  .ie_f_pharmacy_list {
    margin-left: 250px !important;
    z-index: 1;
    margin-top: 142px !important;
    max-width: 424px !important;
  }
  $height: 62px;
  .homepage {
    height: calc(100% - height);
    // position: fixed;
  }
  #getzipcode,
  #getzipcodeTop {
    height: 55px;
  }
  input[type="number"]::-ms-clear,
  input[type="text"]::-ms-clear,
  input[type="email"]::-ms-clear,
  input[type="password"]::-ms-clear,
  input[type="search"]::-ms-clear {
    display: none;
  }
  input[type="password"]::-ms-reveal {
    display: none;
  }
  // change password
  .change_password .changepassword_main .input_box label {
    top: -6px;
  }
  .pharmacyPage {
    .discount_card {
      .discount_card_parent {
        .pharmacybox {
          .price {
            word-break: break-all;
          }
        }
      }
    }
  }
  .signupbox {
    z-index: 9;
    .Signup_login {
      .form_area {
        .form_sec {
          label {
            top: -5px;
          }
        }
      }
    }
  }
  .error_msg {
    label {
      top: 0px !important;
    }
  }

  .error_msg.email_field label {
    transform: translate(0px, 0px) scale(1) !important;
    margin-top: -7px;
  }
  .signupbox .Signup_login .background_area h2 {
    width: 100%;
    word-break: break-word;
  }
  .pharmacyPage .discount_card .discount_card_parent .pharmacyDetail .email_field label {
    z-index: 1;
  }
}
//Mozilla Firefox fix
@-moz-document url-prefix() {
  body {
    background: #f7f9fa;
  }
  .PharmacyNear {
    .filtertext {
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
  .pharmacyPage .pharmacy_box .select_filterList_parent .filter_box .type_box input,
  #Quantity_Manual,
  #SearchDrugText,
  #InputGroup,
  #getzipcode {
    -moz-appearance: textfield;
  }
  .searchbox_home {
    .searchbox_box {
      input[type="text"] {
        border: 1px solid #ffffff !important;
      }
    }
  }
  .type_list ul {
    overflow: hidden;
    overflow-y: scroll;
    max-width: 211px;
    height: auto;
    max-height: 137px;
  }
  .pharmacyPage {
    .discount_card {
      .discount_card_parent {
        .pharmacyDetail {
          .email_field label {
            padding-top: 5px;
          }
        }
      }
    }
  }
  .pharmacyPage {
    .pharmacy_box {
      .pharmacy_list {
        .card {
          .name_Sec {
            max-height: 45%;
          }
        }
      }
    }
  }
  input[type="number"]::-moz-focusring,
  input[type="text"]::-moz-focusring,
  input[type="email"]::-moz-focusring,
  input[type="password"]::-moz-focusring,
  input[type="search"]::-moz-focusring {
    background-color: #fff;
  }
  input[type="password"]:focus-visible {
    background-color: pink; /* Something to get the user's attention */
  }
}
// change icon in password field
//mobile browser
@media screen and (max-width: 1024px) and (min-width: 0) {
  .pharmacyPage {
    .discount_card {
      .discount_card_parent {
        .pharmacyDetail {
          background: #fff;
        }
      }
    }
  }
  .pharmacyPage {
    .discount_card {
      .discount_card_parent {
        .pharmacybox {
          .price {
            word-break: break-all;
          }
        }
      }
    }
  }
}
.eye_show {
  background: url(assets/images/eye.svg) no-repeat !important;
}
.pharmacyPage .discount_card .discount_card_parent {
  height: auto;
  max-height: 100%;
}

.loadProcess {
  width: 100%;
  height: 45px;
  max-width: 100%;
  max-height: 100%;
  background: transparent;
  border-radius: 4px;
  display: none;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  line-height: normal;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  padding: 0 !important;
  // transition: font-size 0.1s ease;
  overflow: hidden;
  &.loader {
    font-size: 200px;
    transition: font-size 1s ease;
    pointer-events: none;
    padding-left: 40%;
    padding-top: 2%;
    display: block;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px !important;
    &:before {
      content: "";
      border: 4px solid $secondary;
      border-radius: 50%;
      border-top: 4px solid $txt_white;
      width: 30px;
      height: 30px;
      -webkit-animation: spin 0.5s linear infinite; /* Safari */
      animation: spin 0.5s linear infinite;
      position: absolute;
    }
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.pharmacyPage .discount_card .discount_card_parent {
  height: auto;
  max-height: 100%;
}
// use in drug setting popup
.singleDoseForm {
  pointer-events: none;
  opacity: 0.5;
}

// new site
.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

.footer-adjust {
  width: 100%;
  display: flex;
}

.menuFJC {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
// .pad0{
//   padding-top: 0px !important;
// }
// .pad25{
//   padding-top: 0;
// }
@media (min-width: 1200px) {
}
@media (min-width: 1024px) and (max-width: 2560px) {
}
.contMinHeight {
  min-height: 365px;
}
.aboutMinHeight {
  min-height: 390px;
}
.aboutMinHeightOur {
  min-height: 770px;
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 1024px) {
} // 767 media query end

.modal-backdrop {
  background: transparent;
}
.newContactBtn {
  background: #ed8808 !important;
}
.modal-dialog {
  pointer-events: fill;
}
.contact-section .form-control,
.form-control:focus,
.btn:focus,
.btn.focus {
  outline: none;
  box-shadow: none !important;
}

// rating css
.ratingTag {
  display: flex;
  padding-top: 10px;
  span {
    margin-top: 0px !important;
    padding: 0 7px 0 0;
    @include font_fira;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #18342f !important;
  }
  .star {
    display: flex;
    .enable {
      background-color: $secondary;
    }
    li {
      background-color: #dde5ed;
      background-image: url("assets/images/star.svg");
      @include width(15px);
      @include height(15px);
      background-repeat: no-repeat;
      margin-bottom: 0px;
      margin-top: 3px;
      margin-right: 4px;
      border-radius: 50%;
      fill: $txt_white;
      cursor: pointer;
      &:hover {
        background-color: $secondary;
      }
    }
  }
}
.contact-section {
  .form-control,
  textarea {
    &:focus {
      border-color: #dde5ed;
    }
  }
}
.prescription-form {
  .form-group {
    position: relative;
    margin-bottom: 0px;
    span {
      position: absolute;
      text-align: left;
      bottom: 0;
      left: 25px;
      border-top: 1px solid red;
      width: 100%;
    }
    button {
      .btn {
        color: $txt_white !important;
        &:focus {
          color: $txt_white;
        }
      }
    }
    #fullnameerror {
      width: 85%;
    }
  }
}
.shadow {
  box-shadow: none !important;
}
.download {
  position: relative;
  padding-left: 30px !important;
  a:before {
    content: "";
    height: 27px;
    width: 15px;
    background: url("assets/images/download_header.svg") no-repeat;
    background-size: 100%;
    margin-right: 10px;
  }
  a.active {
    &:before {
      content: "";
      height: 27px;
      width: 15px;
      background: url("assets/images/download_header_active.svg") no-repeat;
      background-size: 100%;
      margin-right: 10px;
    }
  }
}
.m-prescriptionimg-responsive {
  display: none;
}
// terms of use
.termsofuse {
  border-top: 1px solid #dde5ed;
  margin-bottom: 30px;
  .comman-width {
    h2 {
      font-size: 24px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: $tertiary;
      padding: 0;
      margin: 30px 0;
    }
    h3 {
      font-size: 22px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: $tertiary;
      padding: 0;
      margin: 40px 0 10px;
      &:first-child {
        margin-top: 0;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: $greyish_text;
      padding: 0;
      // margin-bottom: 10px;
      a {
        color: #7fc534;
      }
    }
    ul {
      margin: 20px 0px 20px 40px;
      li {
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: $greyish_text;
        background: none;
        list-style-type: disc;
        padding-left: 0px;
        margin-bottom: 10px;
      }
    }
  }
}
.signuphow {
  position: absolute;
  color: #fff;
  margin-top: 5px;
  display: block;
}
.fnewss {
  position: relative;
  color: #000;
  display: none;
}
.showsuccess {
  > button.btn {
    z-index: 9;
    top: -37px !important;
    opacity: 1 !important;
    color: #243d56 !important;
  }
  > div {
    display: flex;
    padding: 40px;
    flex-flow: column;
    box-shadow: none;
    border: none;
    background-color: transparent;
    margin-top: 0;
    position: relative;
    padding-top: 60px;
    img {
      width: 72px;
      margin: 0 auto 30px auto;
    }
    p {
      @include apercubold;
      font-size: 24px;
      font-weight: 500;
      color: $whitetext !important;
      line-height: 35px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
.btn.btn-default.close {
  color: #243d56 !important;
  font-size: 20px !important;
  opacity: 1 !important;
  z-index: 9 !important;
  top: -25px !important;
}
@import "mediaqueries.scss";
@import "crossbrowser.scss";

/* 28-02-2020 */
.navbar.green-theme li a.active {
  color: $secondary;
}
.navbar.green-theme li span {
  background: $secondary;
  bottom: -62px;
}

.banner-section.green-theme {
  background-color: #f2f2f2;
  background-image: inherit;
  min-height: 331px;
}
.banner-section.green-theme .over {
  position: relative;
  top: auto;
  left: auto;
  margin-left: 110px;
}
.banner-section.green-theme h2 {
  @include apercuregular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.5px;
  text-align: left;
  color: $secondary;
  margin-bottom: 0px;
  padding-top: 76px;
}
.banner-section.green-theme p {
  @include apercubold;
  font-size: 50px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: -1.43px;
  text-align: left;
  color: #012548;
}

.honest-section.green-theme .small-heading {
  @include apercuregular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.5px;
  text-align: left;
  color: $secondary;
}
.honest-section.green-theme h2 {
  @include apercubold;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.86px;
  text-align: left;
  color: $primary;
}
.honest-section.green-theme p {
  @include apercuregular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: -0.57px;
  text-align: left;
  color: #012548;
}
.honest-section.green-theme .image {
  float: right;
  margin-right: -38px;
  max-width: 716px;
  img {
    max-width: 100%;
  }
}
.honest-section.green-theme {
  width: 100%;
  overflow: hidden;
  padding-top: 112px;
}
.honest-section.green-theme .content {
  width: 455px;
  margin-left: 110px;
}
.honest-section.green-theme .content li {
  @include apercubold;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 31px;
  letter-spacing: -0.57px;
  text-align: left;
  color: #25534b;
  float: left;
  width: 100%;
  list-style-type: none !important;
}
.card-section.green-theme li {
  @include apercuregular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.6px;
  text-align: left;
  color: #19342f;
  float: left;
  width: 100%;
}
.card-section.green-theme li li {
  list-style-type: disc !important;
}
.card-section.green-theme li strong {
  @include apercubold;
  font-weight: normal;
}
.honest-section.green-theme .content ul,
.card-section.green-theme ul {
  border-left: none;
  padding-left: 20px;
  width: 100%;
  overflow: hidden;
  margin-top: 35px;
}
.card-section.green-theme ul {
  margin: 0px;
  padding: 0 0 0 18px;
}
.honest-section.green-theme .content li:first-child {
  margin-top: -8px;
}
.honest-section.green-theme .content li:last-child {
  margin-bottom: -9px;
}
.card-section.green-theme li:last-child {
  margin-bottom: -4px;
}
.contact-section.green-theme .content {
  margin-top: 0px;
  border: none;
  background: inherit;
  padding: 0px;
  width: 1039px;
  margin: 0 auto;
  padding-top: 82px;
}
.contact-section.green-theme h2 {
  @include apercuregular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.5px;
  text-align: left;
  color: $secondary;
  margin-bottom: 10px;
  margin-left: 22px;
}
.contact-section.green-theme .form-control {
  border: solid 2px #00407e;
  height: 67px;
  padding: 0px;
  border-radius: 40px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 32px;
  letter-spacing: -0.57px;
  text-align: left;
  color: #00407e;
  @include apercuregular;
  &::-webkit-scrollbar {
    display: none;
  }
}
.contact-section.green-theme .left-side {
  width: 49%;
  float: left;
}
.contact-section.green-theme .right-side {
  width: 49%;
  float: right;
}

.contact-section.green-theme .left-side .form-control,
.contact-section.green-theme .right-side .form-control {
  width: 100%;
}
.contact-section.green-theme .form-group {
  margin-bottom: 21px;
  width: 100%;
  overflow: hidden;
}
.contact-section.green-theme label {
  position: relative;
  top: auto;
  margin-left: 20px;
}
.btn.button.btn-hover-effect.newContactBtn {
  float: right;
  width: 182px;
  height: 45px;
  border-radius: 20.5px;
}
.contact-section.green-theme .newContactBtn {
  background: $secondary !important;
  @include apercuregular;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.14px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0px;
}
.contact-section.green-theme .checkmark {
  background-color: #fff;
  width: 22px;
  height: 22px;
  border: 2px solid #bfc0c0;
}
.contact-section.green-theme .checkbox-container:hover input ~ .checkmark {
  background-color: #fff;
}
.contact-section.green-theme .checkbox-container .checkmark:after {
  border-color: #bfc0c0;
  top: 2px;
}
.checkbox-container input:checked ~ .checkmark {
  background-color: #fff;
}
.colorRed.btnValidator {
  margin: 0 0 0 20px;
}
.contact-section.green-theme .form-group.destop {
  margin-bottom: 0px;
}
.accordian-section.green-theme {
  background: #fff;
}
.accordian-section.green-theme h2 {
  @include apercuregular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.5px;
  text-align: left;
  color: secondary;
  margin-bottom: 0px;
}
.accordian-section.green-theme p {
  @include apercubold;
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.86px;
  text-align: left;
  color: #27524b;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.accordian-section.green-theme {
  width: 1039px;
  margin: 0 auto;
  padding-top: 70px;
}
.accordian-section.green-theme .panel {
  border: none !important;
  border-radius: 0px !important;
  box-shadow: inherit !important;
  border-bottom: solid 1px #e8e3e3 !important;
  background-color: #ffffff !important;
  padding: 15px 0 !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  position: relative;
}
.accordian-section.green-theme .panel-title {
  @include apercuregular;
  font-size: 20px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.75px;
  text-align: left;
  color: #19342f;
}
.accordian-section.green-theme .panel-title span[aria-expanded="false"] span {
  border: none;
  width: 21px;
  height: 21px;
  background: url("assets/images/down-arrow.png") left center no-repeat;
  margin: 0px;
}
.accordian-section.green-theme .panel-title span[aria-expanded="true"] span {
  border: none;
  width: 21px;
  height: 21px;
  background: url("assets/images/down-arrow-active.png") left bottom no-repeat !important;
  margin: 0px;
  position: absolute;
  bottom: 12px;
  right: 0px;
}
.accordian-section.green-theme .panel-title span[aria-expanded="true"] {
  color: #19342f;
}
.accordian-section.green-theme .panel-body {
  @include apercuregular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.6px;
  text-align: left;
  color: #827c78;
}
.accordian-section.green-theme .panel.active {
  border-bottom: solid 2px #526763 !important;
}
.contact-section.green-theme {
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(245, 246, 246, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(228, 231, 233, 1) 100%
  ); /* ff3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(245, 246, 246, 1)),
    color-stop(52%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(228, 231, 233, 1))
  ); /* safari4+,chrome */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(245, 246, 246, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(228, 231, 233, 1) 100%
  ); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(245, 246, 246, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(228, 231, 233, 1) 100%
  ); /* opera 11.10+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(245, 246, 246, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(228, 231, 233, 1) 100%
  ); /* ie10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(245, 246, 246, 1) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(228, 231, 233, 1) 100%
  ); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f6f6', endColorstr='#e4e7e9',GradientType=1 ); /* ie6-9 */
  padding-bottom: 50px;
}

.welcome-section.download-edit h2 {
  @include apercubold;
  font-size: 45px;
  // font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: -1.29px;
  text-align: left;
  color: #ffffff;
}
.welcome-section.download-edit .rated,
.welcome-section.download-edit .get {
  @include apercuregular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.1px;
  text-align: left;
  color: #ffffff;
}
.welcome-section.download-edit .rated {
  margin-bottom: 19px;
}
.welcome-section.download-edit .get {
  margin: 14px 0 25px 0;
}

.welcome-section.download-edit .form-control {
  @include apercuregular;
  width: 551px;
  height: 90px;
  border-radius: 45px;
  border: solid 2px #ffffff;
  background-color: inherit;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.1px;
  text-align: left;
  color: #ffffff;
  padding-left: 30px;
}

.welcome-section.download-edit .form-control::-webkit-input-placeholder {
  color: #fff;
}

.welcome-section.download-edit .form-control:-ms-input-placeholder {
  color: #fff;
}

.welcome-section.download-edit .form-control::placeholder {
  color: #fff;
}
.welcome-section.download-edit .btn {
  @include apercubold;
  width: 194px;
  height: 45px;
  border-radius: 20.5px;
  background-color: $secondary;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.14px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 22px;
  right: 25px;
}
.welcome-section.download-edit form {
  position: relative;
}
.welcome-section.how-works,
.welcome-section.how-works::before {
  background: inherit;
}
.welcome-section.how-works .about {
  @include apercuregular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.5px;
  text-align: left;
  color: $secondary;
}
.welcome-section.how-works h2 {
  font-family: Apercu;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.86px;
  text-align: left;
  color: #00407e;
  margin: 0px 0 30px 0;
}
.welcome-section.how-works span {
  font-size: 20px;
  @include apercubold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.57px;
  text-align: left;
  color: #00407e;
}

.welcome-section.how-works p {
  margin-top: 15px;
  @include apercuregular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.46px;
  text-align: left;
  color: #00407e;
  text-transform: inherit;
}
.testimonial.green-theme {
  width: 100%;
  margin: 0px;
  padding: 0px 0px 104px 0px;
}
.welcome-section.how-works .welcome-center {
  max-width: 800px;
}
.welcome-section.how-works .search ul li {
  max-width: 230px;
}
.welcome-section.how-works {
  min-height: inherit;
  padding-top: 80px;
  float: none;
  overflow: hidden;
}
.testimonial.green-theme .comman-width .testimonial_inner h3,
.testimonial.green-theme .comman-width .testimonial_inner h2 {
  padding-left: 59px;
}
.contact-section.green-theme .btn-hover-effect .insider {
  background-color: #fff;
}
.welcome-section.download-edit .btn-hover-effect .insider {
  background-color: #fff;
}
.card-section.termsofuse.green-theme .terms {
  @include apercuregular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.5px;
  text-align: left;
  color: $secondary;
}
.card-section.termsofuse.green-theme h3 {
  @include apercubold;
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.86px;
  text-align: left;
  color: #00407e;
}
.card-section.termsofuse.green-theme h4 {
  color: #00407e;
}
.card-section.termsofuse.green-theme p {
  @include apercuregular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.6px;
  text-align: left;
  color: #00407e;
  padding-bottom: 10px;
}
.card-section.termsofuse.green-theme {
  padding: 80px 0 0 0;
  background: #f0f2f2; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #f0f2f2 0%,
    #ffffff 4%,
    #ffffff 4%,
    #ffffff 4%,
    #ffffff 4%,
    #ffffff 70%,
    #e4e6e9 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #f0f2f2 0%,
    #ffffff 4%,
    #ffffff 4%,
    #ffffff 4%,
    #ffffff 4%,
    #ffffff 70%,
    #e4e6e9 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #f0f2f2 0%,
    #ffffff 4%,
    #ffffff 4%,
    #ffffff 4%,
    #ffffff 4%,
    #ffffff 70%,
    #e4e6e9 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f2f2', endColorstr='#e4e6e9',GradientType=0 ); /* IE6-9 */
}
.card-section.termsofuse.green-theme .repeat {
  border-bottom: 1px solid #e5dfdf;
  padding: 0 0 20px 0;
  margin-bottom: 35px;
}
.card-section.termsofuse.green-theme .repeat.border-none {
  border: none;
}
.card-section.green-theme li ul {
  border: none;
  padding: 12px 0 12px 18px;
}
.card-section.green-theme ul.border-none {
  border: none;
}
.card-section.green-theme ul.border-none li ul {
  padding: 25px 0 25px 18px;
}
.card-section.green-theme ul.gap {
  margin-bottom: 25px;
}
.accordian-section.green-theme .panel:last-child {
  border-bottom: none !important;
}
.accordian-section.green-theme .panel.active:last-child {
  border-bottom: solid 2px #526763 !important;
}
.getCard.green-theme {
  width: 399px;
  height: 90px;
  border-radius: 61.5px;
  border: solid 2px #2a524b;
  background: #19342f url("assets/images/arrow.png") 97% center no-repeat;
}
.getCard.green-theme span {
  background: url(assets/images/discount_icon.svg) 27px 25px no-repeat;
  font-size: 20px;
  @include apercubold;
  font-stretch: normal;
  font-style: normal;
  line-height: 88px;
  letter-spacing: -0.57px;
  text-align: left;
  color: #fff;
  padding-left: 69px;
}
.contact-section.green-card .content {
  width: 469px;
  min-height: 478px;
  max-height: 535px;
  height: 100%;
  // overflow-y: scroll;
  border: solid 2px #2a524b;
  background-color: #19342f;
  border-radius: 0 140px 0 0;
  overflow: hidden;
}
#myModal.green-theme-modal .modal-dialog {
  right: 145px;
}
#myModal.green-theme-modal h2 {
  @include apercubold;
  font-size: 45px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: -1.29px;
  text-align: left;
  color: #ffffff;
}
#myModal.green-theme-modal .getCardHeading img {
  display: block;
  margin-bottom: 25px;
  margin-top: 30px;
}
#myModal.green-theme-modal .form-control {
  @include apercuregular;
  padding-top: 0px;
  width: 100%;
  height: 45px;
  border-radius: 20.5px;
  border: solid 1.2px #ffffff;
  background: inherit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.14px;
  text-align: left;
  color: #ffffff;
  padding: 0 13px;
  box-sizing: border-box;
}
#myModal.green-theme-modal .form-control::-webkit-input-placeholder {
  color: #fff;
}

#myModal.green-theme-modal .form-control:-ms-input-placeholder {
  color: #fff;
}

#myModal.green-theme-modal .form-control::placeholder {
  color: #fff;
}
#myModal.green-theme-modal .contact-section .form-group {
  margin-bottom: 15px;
}
#myModal.green-theme-modal .contact-section.card-modal button {
  width: 194px;
  height: 45px;
  border-radius: 20.5px;
  background-color: $secondary;
  top: auto;
  left: auto;
  right: auto;
}
#myModal.green-theme-modal .btn.btn-default.close {
  background: url(assets/images/pharmacy_close_icon.svg) left top no-repeat;
  width: 24px;
  height: 24px;
  text-indent: -9999px;
  top: 0 !important;
  right: -105px !important;
  background-size: 100%;
}

.inactive {
  pointer-events: none;

  ul li {
    text-decoration: none !important;
    cursor: default;
  }
}
.show_password {
  float: right;
  transform: translate(-20px, -30px);
  font-size: 12px;
  color: #b8afa6;
  cursor: pointer;
}

.location_icon {
  float: right;
  transform: translate(-10px, -30px);
  background-image: url(./assets/images/form_pinlocation.svg);
  background-repeat: no-repeat;
  width: 18px;
  height: 26px;
  cursor: pointer;
  display: block !important;
  input[type="checkbox"] {
    background-color: transparent !important;
  }

  input[type="checkbox"],
  input[type="checkbox"]:checked {
    // background: url(assets/images/form_pinlocation.svg) no-repeat;
    border: none;
    background-size: 100%;
    background: transparent !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
  }
}
.broker-exist .sidebar {
  height: 100%;
  width: 270px;
  position: fixed !important;
  left: 0;
  top: 80px;
  background: #fff;
  z-index: 999999;
  .enrolment {
    background: url(assets/images/enrollment.svg) left center no-repeat;
  }
  .pricing {
    background: url(assets/images/pricing-tool.svg) left center no-repeat;
  }
  .marketing {
    background: url(assets/images/marketing.svg) left center no-repeat;
  }
  .dashboard {
    background: url(assets/images/dashboard.svg) left center no-repeat;
  }
  .faq {
    background: url(assets/images/help-icon.svg) left center no-repeat;
  }
  .physical {
    background: url(assets/images/email-icon.svg) left center no-repeat;
  }
  .logout {
    background: url(assets/images/logout-icon.svg) left center no-repeat;
  }
  .enrolment.active {
    background: url(assets/images/enrollment-active.svg) left center no-repeat;
  }
  .pricing.active {
    background: url(assets/images/pricing-tool-active.svg) left center no-repeat;
  }
  .marketing.active {
    background: url(assets/images/marketing-active.svg) left center no-repeat;
  }
  .faq.active {
    background: url(assets/images/help-icon-active.svg) left center no-repeat;
  }
  ul {
    margin-left: 25px;
    margin-top: 20px;
  }
  img {
    margin-right: 14px;
  }
  li a {
    @include apercuregular;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #506d85;
    padding: 10px 0 10px 35px;
  }
  li a.active {
    color: #00564a;
  }
  li {
    width: 100%;
    overflow: hidden;
    padding-bottom: 28px;
  }
}
.header-section.brokerpageheader {
  position: fixed;
  width: 100%;
  top: 0;

  .header-content {
    display: flex;
  }
  align-items: inherit;
  min-height: 80px !important;
  max-height: 80px !important;
  z-index: 99;
  .logo {
    min-width: 100px;
    margin: 0 0 0 25px;
    img {
      min-width: 100px;
      max-width: 100px;
    }
  }
  .username_sec {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: 69px;
    .ppic {
      background-color: #2a524b;
      color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
    }
    .name {
      p {
        font-size: 16px;
        font-weight: 700;
        color: #19342f;
        margin-bottom: 0px;
      }
      p:last-child {
        font-size: 12px;
        font-weight: 400;
        color: #1d252d;
      }
    }
  }
}
.marketing-section {
  margin: 0 auto;
  width: calc(100% - 340px);
  margin-top: 80px;
  padding-top: 25px;
  margin-left: 300px;
  .sub-accordian.active {
    .panel-title {
      padding-bottom: 0px !important;
    }
  }
  .sub-accordian {
    width: 100%;
    overflow: hidden;
    .panel-heading {
      width: 100%;
      overflow: hidden;
      background: #f8fffb !important;
    }
    .panel-title {
      padding-top: 10px !important;
      padding-bottom: 15px !important;
    }
  }
  .panel-title {
    font-size: 18px !important;
    color: #1d252d !important;
    cursor: pointer;
  }
  .panel {
    margin: 0px !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0px !important;
    padding: 0px !important;
  }
  .panel.active {
    padding-bottom: 0px !important;
  }
  .panel-default.active > .panel-heading {
    background-color: #f8fffb !important;
  }
  h2 {
    padding-bottom: 15px;
  }
  .panel.active .panel-title {
    padding-bottom: 0px;
  }
  .panel-title {
    padding: 0px 0px 5px 0px;
  }
  .panel-title[aria-expanded="false"] {
    border: none;
    background: url(assets/images/resource-plus.png) right 3px no-repeat;
    margin-right: 0px;
  }
  .panel-title[aria-expanded="true"] {
    border: none;
    background: url(assets/images/resource-minus.png) right 9px no-repeat !important;
    margin-right: 0px;
  }
  .panel-title[aria-expanded="true"] {
    color: #44b672;
  }
  li li div {
    float: right;
    padding-right: 0px;
  }
  li li.sub-accordian div {
    float: none;
  }
  li li.sub-accordian li {
    margin-left: 65px;
  }
  li.sub-accordian .panel-title[aria-expanded="true"] {
    background: url(assets/images/resource-minus.png) right 22px no-repeat !important;
  }
  li li.sub-accordian li span:before {
    border-radius: 6px;
    color: #1d252d;
    background: #1d252d;
  }
  li li.sub-accordian li div {
    float: right;
    padding-right: 0px;
  }
  li li.sub-accordian .panel-collapse {
    padding-right: 0px;
  }
  li li a {
    font-size: 12px;
    color: #44b672;
  }
  li li img {
    margin-right: 9px;
  }
  li li .download-icon {
    margin-left: 38px;
  }
  li li {
    padding: 12px 0;
    border-top: solid 1px #e4f0e9;
    margin-left: 0px;
  }
  li li:last-child {
    border-bottom: solid 1px #e4f0e9;
  }
  li li span {
    padding-left: 20px;
    position: relative;
    font-size: 14px;
    color: #1d252d;
  }
  li li span a {
    font-size: 14px;
    color: #1d252d;
  }
  li li:first-child {
    border-top: none !important;
  }
  li ul {
    padding-top: 0px;
  }
  h4 {
    font-size: 16px;
    color: #1d252d;
    padding-top: 20px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    border-top: solid 1px #e4f0e9;
    margin-left: 28px;
  }
  h4.no-border {
    border: none;
  }
}
.broker-exist {
  app-pharmacy {
    .firstloadpricing {
      margin-left: 300px;
      padding-top: 23px;
      @media screen and (min-width: 0) and (max-width: 1024px) {
        margin-left: 0;
        margin-top: 60px !important;
        padding-top: 23px !important;
      }
    }
    .pharmacyPage {
      .container {
        margin-left: 300px;
        padding-left: 0 !important;
        max-width: calc(100% - 300px) !important;
        @media screen and (min-width: 0) and (max-width: 1024px) {
          margin-left: 0;
          max-width: 100% !important;
          .select_filterList_parent {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
          .map_list_box {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }
      }
    }
  }
  app-contact-us {
    .contact-section {
      display: none;
    }
    .accordian-section.green-theme {
      background: transparent;
      padding-top: 105px;
      margin-left: 300px;
      @media screen and (min-width: 0) and (max-width: 1024px) {
        padding-top: 85px;
        margin-left: 0;
      }
      .panel {
        background: transparent !important;
        > .panel-heading {
          background: transparent !important;
        }
      }
    }
    .contact_info {
      margin-left: 300px;
    }
  }
}
.broker-pages {
  background: #f2f2f2;
}
.shape {
  position: absolute;
  top: 34px;
  left: 20px;
  width: 18px;
  height: 18px;
  z-index: 999;
  display: none;
}
.shape span {
  height: 3px;
  width: 100%;
  background: #506d85;
  margin-bottom: 3px;
  display: block;
}
.hide {
  .closeSidebar {
    position: absolute;
    top: 16px;
    right: 16px;
    margin-right: 0px !important;
  }
  .username_sec {
    display: flex;
    align-items: center;
  }
  padding: 24px 0 0 24px;
  display: none;
  height: 85px;
  background-image: -webkit-linear-gradient(336deg, #2a524b 12%, #2a524b 77%);
  background-image: -o-linear-gradient(336deg, #2a524b 12%, #2a524b 77%);
  background-image: linear-gradient(114deg, #2a524b 12%, #2a524b 77%);
  .ppic {
    background-color: #fff;
    color: #2a524b;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .name {
    p {
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 0px;
    }

    p:last-child {
      font-size: 12px;
      font-weight: 400;
      color: #fff;
    }
  }
}

@media (max-width: 1024px) {
  .shape {
    display: block;
  }
  .hide {
    display: block;
  }
  .brokerpageheader .mobMenu.mMenu {
    display: none !important;
  }
  .marketing-section {
    margin-left: 35px;
    width: calc(100% - 70px);
  }
  .broker-exist .sidebar {
    width: 340px;
    left: -340px;
  }
  .header-section.brokerpageheader .logo {
    margin: 0 0 0 30px;
  }
  .broker-exist .sidebar {
    top: 80px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .marketing-section {
    margin-left: 20px;
    width: calc(100% - 40px);
    margin-top: 60px;
  }
  .is-hidden-mobile {
    display: none !important;
  }
  .marketing-section li img {
    margin-right: 10px;
  }
}

@media (min-width: 1025px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .marketing-section .panel-title span[aria-expanded="false"] span,
  .marketing-section .panel-title span[aria-expanded="true"] span {
    margin-right: 0px;
  }
  .footer-section .comman-width .footer-adjust .fourth {
    border-top: 0 !important;
    margin-top: 150px;
  }
  .btnshover:hover {
    background-color: transparent !important;
  }
  .caller-section .btn_desc .btnshover {
    background-color: #ff8b6c !important;
  }
  .caller-section .btn_desc .btnshover.success-btn {
    background-color: #44b672 !important;
  }
  .broker-exist .sidebar ul {
    height: 140px;
    overflow: auto;
  }
}
@media (max-width: 480px) {
  .header-section.brokerpageheader .username_sec {
    margin-right: 20px;
  }
  .marketing-section li li .download-icon {
    margin-left: 12px;
  }
  .marketing-section li li span {
    width: 100%;
    float: left;
    order: 1;
  }
  .marketing-section li li,
  .marketing-section li ul {
    width: calc(100% - 28px);
    flex-flow: column;
    display: flex;
  }
  .marketing-section li li div {
    padding-right: 0px;
    order: 2;
  }
  .broker-exist .sidebar ul {
    height: auto;
    overflow: visible;
  }
  .marketing-section li span {
    font-size: 14px !important;
  }
  .marketing-section li .pdf-download {
    width: 40px;
  }

  .marketing-section li:hover .pdf-download {
    text-indent: -9999px;
    background: none;
    padding: 0px;
  }

  .header-section.brokerpageheader .logo {
    margin-left: 5px;
  }

  .header-section.brokerpageheader .logo img {
    min-width: 130px !important;
    max-width: 130px !important;
    margin-left: 0px;
  }
}
.sharecardtable {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
}
.printcard * {
  font-family: "Roboto" !important;
}
#printdiv {
  max-width: 320px;
  margin: 0 auto;
}

@media print {
  #printdiv {
    display: block !important;
    margin: 0 auto !important;
  }
  .monitor-tag {
    display: none !important;
  }
  #printdiv,
  #printdiv > table {
    width: 100% !important;
    -webkit-print-color-adjust: exact !important;
    margin: 0 auto !important;
  }
  .firsttable {
    background: #fff !important;
    border-radius: 15px !important;
    box-shadow: 0 0 9px #ccc !important;
    width: 400px !important;
    -webkit-print-color-adjust: exact !important;
    tr:nth-child(4) > table {
      background: #27524b !important;
      border-radius: 0 0 15px 15px !important;
      -webkit-print-color-adjust: exact !important;
    }
  }
  .secondtable {
    margin-top: 30px !important;
    background: #fff !important;
    border-radius: 15px !important;
    box-shadow: 0 0 9px #ccc !important;
  }
}
.hideheaderfooter {
  display: none !important;
}
.marketing-section_frame {
  margin: 0 auto;
  width: 100%;
  margin-top: 0;
  margin-left: 0;
  padding: 15px;
}
.marketing-section_frame.frameresource {
  #accordion {
    .panel {
      .panel-collapse {
        ul {
          li {
            display: flex;
            flex-flow: column;
            div {
              order: 2;
              padding-left: 22px;
            }
            span {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.eccountsbox {
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 13px;
  padding-right: 13px;
  background: rgba(68, 182, 114, 0.1);
  border-radius: 10px;
  margin-bottom: 0px;
  max-width: auto;
  margin-right: 15px;
  color: #2a524b;
  font-size: 14px;
}
.loginPage app-root div.eccountsbox:nth-child(2) {
  height: 38px;
}
.eccountsbox:before {
  content: "";
  width: 26px;
  height: 16px;
  background: url(../src/assets/images/odometer.svg) no-repeat;
  background-size: 100%;
  margin-right: 11px;
  @include apercuregular;
  font-size: 14px;
  font-weight: 500;
  color: #2a524b;
}
.eccounts-section {
  display: flex;
  padding-top: 25px;
}
.top-section {
  display: flex;
}
.search_pharmacy_popup.broker_info .search_pharmacy_select .top-section h2 {
  font-size: 24px;
  line-height: 32px;
  border: none;
  color: #19342f;
  max-width: 306px;
}
.search_pharmacy_popup.broker_info .search_pharmacy_select .top-section div {
  margin-left: 0;
  display: flex;
  width: calc(100% - 324px);
  height: auto;
  align-items: center;
  justify-content: center;
  object-fit: contain;

  max-height: 132px;
}
.search_pharmacy_popup.broker_info .search_pharmacy_select .top-section div img {
  width: 200%;
}
.autoheightinfo {
  height: auto !important;
}
.search_pharmacy_popup.broker_info .search_pharmacy_select {
  max-width: 560px;
  max-height: 380px;
  height: 380px;
  border-radius: 10px 100px 10px 10px;
}
.search_pharmacy_popup.broker_info .field {
  margin-top: 50px;
}
.caller-section .broker_info .checkbox-container {
  margin-bottom: 20px;
  font-size: 14px;
  color: #19342f;
  width: auto;
  max-width: 175px;
}
.broker_info .btn-section {
  position: relative;
}
.caller-section .broker_info .changepassword_main .btnshover {
  width: 120px;
  height: 48px;
  border-radius: 10px;
  background-color: #ff8b6c;
  font-size: 16px;
  line-height: 48px;
  margin-top: 8px;
}
.get-card-buttons {
  display: flex;
  justify-content: center;
}
.get-card-buttons .card { 
  margin: 45px 10px 0 0;
}
.get-card-buttons .card.gap { 
  margin-left: 10px;
  margin-right: 0px;
}
.honest-section.green-theme .deduct {
  margin-left: 110px;
  margin-top: 25px;
}
.honest-section.green-theme .deduct p {
  font-family: "Apercu-Bold" !important;
  font-size: 16px;
  color: #012548;
  letter-spacing: -0.51px;
}
.honest-section.green-theme .deduct p a {
   color: #012548;
 }
 .honest-section.green-theme .deduct .cool-link::after {
  background: #012548;
 }
 .marketing-section .panel.active .panel-title {
  padding-bottom: 10px;
 }
 .contact-section.green-theme select {
  -webkit-appearance: none;
  appearance: none;
 }
 .contact-section.green-theme .down-arrow {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #00407e;
  position: absolute;
  top: 30px;
  right: 30px;
}
.walletDiv .google-wallet {
  margin-bottom: 7px;
}
.walletDiv img {
  cursor: pointer;
}
.sharepass .error {
  margin-top: 10px;
}
@media screen and (min-width: 320px) and (max-width: 980px) {
  .eccountsbox {
    font-size: 13px;
    margin-right: 8px;
  }
  .eccounts-section {
    // padding-top: 10px;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .signupbox.sharepass .Signup_login .form_area {
    border-radius: 0 100px 0 0 !important;
  }
  .signupbox.sharepass .Signup_login .form_area .signup_signin_parent_box .form_sec .changepassword_main > div .reset_button {
    min-width: 150px!important;
  }
  .eccountsbox {
    margin-right: 0px;
    margin-bottom: 8px;
  }
  .eccounts-section {
    display: block;
  }
  .search_pharmacy_popup.broker_info .search_pharmacy_select {
    max-width: 90%;
    max-height: 510px;
    height: 510px;
  }
  .top-section {
    display: block;
  }
  .search_pharmacy_popup.broker_info .search_pharmacy_select .top-section img {
    margin: 10px auto 0 auto;
    display: block;
  } 
}
.enrollparent {
  background: #fff;
  max-width: 850px;
  padding: 30px;
  padding-bottom: 5px;
  border-radius: 20px;
  margin-top: 30px;
  padding-top: 0;
}
.question-section li {
  list-style: disc;
  color: #fff;
  padding-bottom: 5px;
}
.question-section ul {
  padding-left: 30px;
  padding-bottom: 15px;
}
.question-section p.remove-gap {
  padding-bottom: 5px;
}
app-pdflink {
  iframe {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
  }
}
.contact-section.green-theme .form-control::placeholder {
  color: #00407e;
}
.drug-section h2 {
  color: #00407e;
}